import React, { useState } from "react";
import axios from "axios";
import { getToken } from "../../helpers/authHelper";
import LoadingDots from "../../components/LoadingDots";
import HeritageDropdown from "../HeritageDropdown";

const HeritageModal = ({
  active,
  user,
  setUser,
  reportKey,
  reportName,
  setHeritageModal,
  setRedeemModal,
}) => {
  const [heritage, setHeritage] = useState({ id: null, name: "Select..." });
  const [updating, setUpdating] = useState(false);
  const [error, setError] = useState(null);
  const [selectDropdown, setSelectDropdown] = useState(false);

  let instance = axios.create({
    baseURL: process.env.REACT_APP_ITOVI_API,
    timeout: 1000,
    headers: { Authorization: "Bearer " + getToken() },
  });

  const updateUserHeritage = async () => {
    const userResult = await instance
      .patch("/users/" + user.userId + "/clients/" + user.clientId, {
        heritage: {
          id: heritage.id,
        },
      })
      .then((response) => {
        return { response: true, heritage: response.data.data.heritage };
      })
      .catch((e) => {
        return { response: false };
      });

    return userResult;
  };

  const updateDNAHeritage = async () => {
    const dnaResult = await axios
      .post(
        process.env.REACT_APP_DNA_API + "/v1/kits",
        {
          kitId: user.kitId,
          heritage: {
            id: heritage.id,
            name: heritage.name,
          },
        },
        {
          headers: {
            authorization: "Token " + process.env.REACT_APP_DNA_API_KEY,
          },
        }
      )
      .then((response) => {
        return { response: true };
      })
      .catch(() => {
        return { response: false };
      });

    return dnaResult;
  };

  const updateHeritage = async () => {
    if (!heritage || !heritage.id) {
      return setError("You must select an option!");
    }

    setError(false);
    setUpdating(true);

    const userResult = await updateUserHeritage();
    const dnaResult = await updateDNAHeritage();

    if (userResult.response && dnaResult.response) {
      setUser({ ...user, heritage: userResult.heritage });
      setRedeemModal({
        active: true,
        reportName: reportName,
        reportKey: reportKey,
        userId: user.userId,
      });
      setHeritageModal(null);
    } else {
      setError("Was not able to update your profile!");
    }

    setUpdating(false);
  };

  return (
    <>
      {active ? (
        <div className="modal-wrapper blur-background">
          <div className="heritage-modal">
            <div className="heritage-modal-header">
              <div className="header-title">Heritage Info</div>
              <div
                className="dismiss"
                onClick={() => {
                  setHeritageModal(null);
                  setError(false);
                }}
              ></div>
            </div>
            <div className="heritage-description">
              {`Providing this information will give you more accurate data in
              your ${reportName} Report.`}
            </div>
            <div className="input-container">
              <div className="input-label">
                <div>{`What most closely describes ${user.firstName}’s Heritage?`}</div>
              </div>
              <HeritageDropdown
                selectDropdown={selectDropdown}
                setSelectDropdown={setSelectDropdown}
                heritage={heritage}
                setHeritage={setHeritage}
                error={!!error}
                setError={setError}
              />
              {error ? <div className="error-message">{error}</div> : null}
            </div>
            <div className="button-container">
              <div>
                <button
                  type="button"
                  className="button button-sm button-primary"
                  onClick={async () => {
                    if (!updating) {
                      await updateHeritage();
                    }
                  }}
                >
                  {updating ? (
                    <LoadingDots size="medium" color="white" />
                  ) : (
                    "Next"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default HeritageModal;
