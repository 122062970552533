import React from "react";

const LoadingDots = ({ size, color }) => {
  const style = "loading-dots " + size + " " + color;
  return (
    <div className={style}>
      <div className="dot-1"></div>
      <div className="dot-2"></div>
      <div className="dot-3"></div>
    </div>
  );
};

export default LoadingDots;
