import React from "react";
import congratsImage from "../../assets/congratsbanner.svg";

const CongratsModal = ({ congratsModal, setCongratsModal }) => {
  return (
      <>
      {congratsModal.active ?
          <div className="modal-wrapper blur-background congrats-modal-wrapper">
              <div className="congrats-modal">
                  <div className="congrats-header">
                      <div className="congrats-banner">
                          <img alt="" src={congratsImage} />
                      </div>
                      <div className="dismiss" onClick={() => setCongratsModal(false)}></div>
                  </div>
                  <div className="congrats-description">{congratsModal.message}</div>
                  <div className="congrats-button-container">
                      <div>
                          <button className="congrats-button" onClick={() => setCongratsModal(false)}>Okay</button>
                      </div>
                  </div>
              </div>
          </div>
      : null}
      </>
  );
};

export default CongratsModal;
