import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { useForm } from "react-hooks-helper";
import { Form, Button, Spinner } from "react-bootstrap";
import { loginRedirect } from "../../helpers/loginRedirect";
import axios from "axios";
import Cookies from "universal-cookie";

const Cookie = new Cookies();

const Login = ({ getLoginStatus }) => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let history = useHistory();

  const [formData, setForm] = useForm({
    email: null,
    password: null,
  });

  const [errors, setErrors] = useState({});

  return (
    <div className="xl-container">
      <div className="content-container login-wrapper">
        <h2 className="font-weight-bold">Account Login</h2>
        <p>Login to register your DNA Kit</p>
        <Form>
          <Form.Group>
            <Form.Control
              isInvalid={errors.email}
              type="text"
              placeholder="Email"
              name="email"
              autoComplete="username"
              size="lg"
              onChange={setForm}
              required
            />
            {errors.email ? (
              <div className="invalid-feedback d-block">
                <span>{errors.email}</span>
              </div>
            ) : null}
          </Form.Group>
          <Form.Group>
            <Form.Control
              isInvalid={errors.password}
              type="password"
              placeholder="Password"
              name="password"
              onChange={setForm}
              autoComplete="current-password"
              size="lg"
              required
            />
            {errors.email ? (
              <div className="invalid-feedback d-block">
                <span>{errors.password}</span>
              </div>
            ) : null}
          </Form.Group>
          <Form.Group>
            {loading === false ? (
              <Button
                variant="primary"
                type="submit"
                onClick={login}
                size="lg"
                block
              >
                Login
              </Button>
            ) : (
              <Button variant="primary" block size="lg" disabled>
                <Spinner
                  as="span"
                  animation="grow"
                  size="md"
                  role="status"
                  aria-hidden="true"
                  className="mr-2"
                />
                Loading...
              </Button>
            )}
          </Form.Group>
          <div className="sign-in">
            <span>Don't have an account? </span>
            <Link to="/create-account">Create Account</Link>
          </div>
        </Form>
      </div>
    </div>
  );

  /**
   * Login to account
   * @param {event} e
   */
  function login(e) {
    e.preventDefault();
    setLoading(true);
    axios
      .post(process.env.REACT_APP_ITOVI_API + "/auth/login", formData)
      .then(async (response) => {
        if(process.env.REACT_APP_FEATURE_FLAG === 'production') {
          Cookie.set("token", response.data.data.token, { path: '/', domain: '.itovi.com' });
        } else {
          Cookie.set("token", response.data.data.token);
        }
        setErrors({});
        setLoading(false);
        await getLoginStatus();
        history.push(loginRedirect());
      })
      .catch(function (error) {
        setLoading(false);
        if (error.response) {
          setErrors(error.response.data.errors);
        } else {
          setErrors({ email: "Internal Server Error" });
        }
      });
  }
};

export default Login;
