import React, { useState } from "react";
import { scrollToTop } from "../../../helpers/scrollHelper";
import { emptyMasterTrait } from "../../../helpers/masterTraitSchemas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ArrowRight from "../../../assets/i-line-rightarrow.svg";

const MasterTraitsNav = ({
  activeMasterTrait,
  masterTraits,
  setActiveMasterTrait,
  setEditScore,
  setTraitDeleteActive,
}) => {
  const [toggle, setToggle] = useState(false);
  const activeId =
    activeMasterTrait && activeMasterTrait.traitId
      ? activeMasterTrait.traitId
      : null;

  const changeTrait = (trait) => {
    setActiveMasterTrait(trait);
    setEditScore({ active: false, index: null, score: null });
    setTraitDeleteActive(false);

    if (window.innerWidth <= 959) {
      setToggle(false);
    }

    scrollToTop();
  };

  return (
    <div className={toggle ? "master-trait-nav active" : "master-trait-nav"}>
      <div className="trait-nav-header">
        <div
          onClick={() =>
            window.innerWidth <= 959 ? setToggle(!toggle) : setToggle(true)
          }
        >
          Master Traits
          <FontAwesomeIcon
            icon={toggle ? ["fas", "angle-up"] : ["fas", "angle-down"]}
          />
        </div>
        <div>
          <button
            type="button"
            className="button button-primary button-sm"
            onClick={() => changeTrait(emptyMasterTrait())}
          >
            New Trait
          </button>
        </div>
      </div>
      <div className="master-trait-list">
        {masterTraits
          ? masterTraits.map((val, index) => {
              return (
                <div
                  key={index}
                  className={
                    val.traitId === activeId ? "trait active" : "trait"
                  }
                  onClick={() => changeTrait(val)}
                >
                  <div>
                    <div className="image">
                      {val.croppedImages ? (
                        <img
                          src={val.croppedImages.circle}
                          alt="trait"
                          onError={(e) => {
                            e.target.style.display = "none";
                          }}
                        />
                      ) : val.imageUrl || val.imageUrl !== "" ? (
                        <img
                          src={val.imageUrl}
                          alt="trait"
                          onError={(e) => {
                            e.target.style.display = "none";
                          }}
                        />
                      ) : null}
                    </div>
                    <div className="name">{val.traitName}</div>
                    <div className="icon">
                      <img src={ArrowRight} alt="arrow right" />
                    </div>
                  </div>
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
};

export default MasterTraitsNav;
