import React, { useState } from 'react';
import MarkdownIt from "markdown-it";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

let md = new MarkdownIt();

const ReportSummary = ({ reportData, readMore, setReadMore, imageUrl }) => {
    return (
        <div className="report-summary">
            {readMore ? (
                <div className="back-button" onClick={() => setReadMore(false)}><FontAwesomeIcon icon={("fas", "chevron-left")}/> Report Summary</div>
            ) : null}
            <div className="content-container">
                {readMore ? (
                    <div className="report-image-container"><img className="report-image" src={imageUrl} /></div>
                ) : (
                    <div className="report-summary-title">Report Summary</div>
                )}

                <div className="report-summary-content">
                    {readMore ? (
                        <div dangerouslySetInnerHTML={{ __html: md.render(reportData.reportSummary) }}></div>
                    ) : (
                        <>
                            <div dangerouslySetInnerHTML={{ __html: md.render(reportData.reportSummary.substring(0, 800)) }}></div>
                            <div className="read-more-button" onClick={() => setReadMore(true)}>Read More</div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ReportSummary;