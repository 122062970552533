import React from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, ResponsiveEmbed, Card, Button } from "react-bootstrap";
import ItoviDnaLogo from "../assets/iTOVi_DNA_Logo_W_2x.png";
import SwabIcon from "../assets/icon_swab.svg";
import EnvelopeIcon from "../assets/icon_samle-envelope.svg";
import RepeatIcon from "../assets/icon_repeat.svg";
import SwabTutorialVideo from "../assets/DNA_step_2_Full.mp4";

const SwabInstructions = ({ userInfo, setUserInfo }) => {
  const history = useHistory();

  return (
    <div className="dna-registration-container">
      <div className="container dna-kit-registration">
        <div className="dna-logo d-flex justify-content-center">
          <img
            className="dna-form-logo"
            src={ItoviDnaLogo}
            alt="Itovi Dna Logo"
          />
        </div>
        <div className="swabInstructions">
          <div className="dna-registration-form swabContent">
            <h2>Congrats, you're one step close to learning more about you!</h2>
            <div className="swabInfo">
              <p className="m-0">
                Once you've mailed us your saliva sample, it will take 4-6 weeks
                for us to process it. But don't worry, we'll email you as soon
                as your results are ready, at which point you can view them in
                the app. Let's get this process moving!
              </p>
              <span>
                <strong>
                  Remember no food or drink for 30 minutes before taking your
                  sample.
                </strong>
              </span>
            </div>
            <div className="swabVideo">
              <ResponsiveEmbed aspectRatio="16by9">
                <video controls>
                  <source src={SwabTutorialVideo} type="video/mp4" />
                  Sorry, your browser doesn't support embedded videos.
                </video>
              </ResponsiveEmbed>
            </div>
            <div className="swabSteps">
              <Row>
                <Col md={4} className="p-0">
                  <Card>
                    <Card.Img variant="top" src={SwabIcon} />
                    <Card.Body>
                      <Card.Title>Step 1</Card.Title>
                      <Card.Text>
                        Find a cotton swab in your testing kit and rotate it
                        along the inside of your cheek for thirty seconds while
                        avoiding other surfaces.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={4} className="p-0">
                  <Card>
                    <Card.Img variant="top" src={EnvelopeIcon} />
                    <Card.Body>
                      <Card.Title>Step 2</Card.Title>
                      <Card.Text>
                        Remove swab and let air dry, then place it in the DNA
                        sample envelope.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={4} className="p-0">
                  <Card>
                    <Card.Img variant="top" src={RepeatIcon} />
                    <Card.Body>
                      <Card.Title>Step 3</Card.Title>
                      <Card.Text>
                        Repeat process for other swabs in kit.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row className="m-0">
                <Button
                  variant="success"
                  className="ml-auto"
                  onClick={() => {
                    history.push("/");
                  }}
                >
                  Done
                </Button>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SwabInstructions;
