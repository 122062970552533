import React from "react";

const BarChart = ({ chartData }) => {
  const dataCount = chartData.length - 1;

  return (
    <div className="barchart">
      {chartData
        ? chartData.map((val, index) => {
            return (
              <div
                key={index}
                style={{ width: val.macrosSummary.percentage.high + "%" }}
              >
                <div className="bar-title">{val.title}</div>
                <div
                  className={
                    index === 0
                      ? "bar left-rounded"
                      : index === dataCount
                      ? "bar right-rounded"
                      : "bar"
                  }
                ></div>
                <div className="range">
                  {val.macrosSummary.percentage.low}-
                  {val.macrosSummary.percentage.high}%
                </div>
              </div>
            );
          })
        : null}
    </div>
  );
};

export default BarChart;
