const emptyMasterTrait = () => {
  return {
    version: "1",
    traitId: null,
    traitKey: "",
    traitName: "New Trait",
    imageUrl: "",
    scores: [],
    related: {
      genesDescription: "",
      genes: [],
      links: [],
    },
    reports: [],
    sections: [],
    labKey: "",
    total_views: 0,
    updated_at: null,
    readyToPublish: false
  };
};

const emptyScore = () => {
  return {
    scoreName: "",
    value: 0,
    scoreStatement: "",
    scoreDescription: "",
    successStrategies: [],
    labKey: "",
  };
};

const emptyStrategy = () => {
  return { title: "", text: "", imageUrl: "" };
};

const emptyRelatedLink = () => {
  return {
    title: "",
    url: "",
  };
};

const toGeneratedTrait = (masterTrait, scoreIndex) => {
  let scoreDescription = "";
  let scoreStatement = "";
  let successStrategies = [];

  const scores = masterTrait.scores.length
    ? masterTrait.scores.map((val, index) => {
        if (index === scoreIndex) {
          scoreDescription = val.scoreDescription;
          scoreStatement = val.scoreStatement;
          successStrategies = val.successStrategies;
        }

        return { ...val, active: index === scoreIndex ? true : false };
      })
    : [];

  return {
    traitId: masterTrait.traitId,
    traitName: masterTrait.traitName,
    traitKey: masterTrait.traitKey,
    imageUrl: masterTrait.imageUrl,
    related: masterTrait.related,
    scoreDescription: scoreDescription,
    scoreStatement: scoreStatement,
    scores: scores,
    sections: masterTrait.sections,
    successStrategies: successStrategies,
  };
};

export {
  emptyMasterTrait,
  emptyScore,
  emptyStrategy,
  emptyRelatedLink,
  toGeneratedTrait,
};
