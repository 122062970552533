import React from "react";
import { useHistory } from "react-router-dom";
import { getColor } from "../../helpers/statusColorHelper";
import ArrowRight from "../../assets/i-line-rightarrow.svg";

const AllTraitsNav = ({ traitId, allTraits }) => {
  const active = traitId;
  const history = useHistory();

  return (
    <div className="trait-nav">
      <div className="content-container">
        {allTraits ? (
          allTraits
            .sort((a, b) => {
              if (a.traitName > b.traitName) {
                return 1;
              }
              if (a.traitName < b.traitName) {
                return -1;
              }
              return 0;
            })
            .map((val, index) => {
              return (
                <div
                  key={index}
                  className={active === val.traitId ? "trait active" : "trait"}
                  onClick={() => {
                    history.push("/all-traits/" + val.traitKey.toLowerCase());
                  }}
                >
                  <div>
                    <div className="image">
                      {val.croppedImages && val.croppedImages.circle ? (
                        <img src={val.croppedImages.circle} alt="trait" />
                      ) : val.imageUrl || val.imageUrl !== "" ? (
                        <img src={val.imageUrl} alt="trait" />
                      ) : null}
                    </div>
                    <div className="name-status">
                      <div className="name">{val.traitName}</div>
                      <div
                        className="status"
                        style={{
                          fontWeight: "bold",
                          color: getColor(val.score_value),
                        }}
                      >
                        {val.score}
                      </div>
                    </div>
                    <div className="icon">
                      <img src={ArrowRight} alt="arrow right" />
                    </div>
                  </div>
                </div>
              );
            })
        ) : (
          <div className="trait trait-disabled">
            <div>
              <div className="image"></div>
              <div className="name-status">
                <div className="name">No Traits Available</div>
                <div className="status"></div>
              </div>
              <div className="icon"></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AllTraitsNav;
