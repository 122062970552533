import React from "react";
import DnaLoadingIcon from "../assets/iTOVi_DNA_Icon.png";

const Loading = () => {
  return (
    <div className="loading-wrapper">
      <div className="loading">
        <img src={DnaLoadingIcon} alt="loading" />
      </div>
    </div>
  );
};

export default Loading;
