import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import MasterTraitsNav from "../components/masterTraits/MasterTraitsNav";
import MasterTraitForm from "../components/masterTraits/MasterTraitForm";
import LoadingDots from "../../components/LoadingDots";

const MasterTraits = ({ userInfo }) => {
  const [loading, setLoading] = useState(true);
  const [masterReports, setMasterReports] = useState([]);
  const [masterTraits, setMasterTraits] = useState([]);
  const [activeMasterTrait, setActiveMasterTrait] = useState({});
  const [traitDeleteActive, setTraitDeleteActive] = useState(false);
  const [editScore, setEditScore] = useState({
    active: false,
    index: null,
    score: null,
  });

  const getMasterReports = useCallback(async () => {
    await axios
        .get(process.env.REACT_APP_DNA_API + "/v2/masterreports", {
            headers: {
                authorization: "Token " + process.env.REACT_APP_DNA_API_KEY
            }
        })
        .then((response) => {
            if (response.data && response.data.length > 0) {
                setMasterReports(response.data);
            }
        })
        .catch((error) => {
            setLoading(false);
        });
  }, []);

  const getMasterTraits = useCallback(async () => {
    await axios
        .get(process.env.REACT_APP_DNA_API + "/v2/mastertraits")
        .then(async (response) => {
            if (response.data && response.data.length > 0) {
                setMasterTraits(response.data.sort((a, b) => a.traitName.localeCompare(b.traitName)));
                setActiveMasterTrait(response.data[0]);
                await getMasterReports();
            }
            setLoading(false);
        })
        .catch((error) => {
            setLoading(false);
        });
  }, [getMasterReports]);

  useEffect(() => {
    getMasterTraits();
  }, [getMasterTraits]);

  return (
    <div className="xl-container master-traits">
      {loading ? (
        <LoadingDots />
      ) : (
        <div className="flex-two-thirds-container">
          <div className="content-container">
            <MasterTraitsNav
              masterTraits={masterTraits}
              activeMasterTrait={activeMasterTrait}
              setActiveMasterTrait={setActiveMasterTrait}
              setEditScore={setEditScore}
              setTraitDeleteActive={setTraitDeleteActive}
            />
          </div>
          <div>
            <MasterTraitForm
              getMasterTraits={getMasterTraits}
              activeMasterTrait={activeMasterTrait}
              setActiveMasterTrait={setActiveMasterTrait}
              masterTraits={masterTraits}
              setMasterTraits={setMasterTraits}
              masterReports={masterReports}
              editScore={editScore}
              setEditScore={setEditScore}
              traitDeleteActive={traitDeleteActive}
              setTraitDeleteActive={setTraitDeleteActive}
              userId={userInfo.userId}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default MasterTraits;
