import React from "react";
import ProductCard from "../components/dnaHome/ProductCard";
import DnaBanner from "../components/dnaHome/DnaBanner";
import HowItWorks from "../components/dnaHome/HowItWorks";
import MatchWellnessProducts from "../components/dnaHome/MatchWellnessProducts";
import Privacy from "../components/dnaHome/Privacy";
import OurScience from "../components/dnaHome/OurScience";
import DnaGsr from "../components/dnaHome/DnaGsr";
import { getProductBundles } from "../helpers/productHelper";
import DnaWhatsInReport from "../components/dnaHome/DnaWhatsInReport";
import ReportOneWhatsIncluded from "../components/dnaHome/ReportOneWhatsIncluded";
import FrequentlyAskedQuestions from "../components/dnaHome/FrequentlyAskedQuestions";

const Home = ({ loggedIn }) => {
  const products = getProductBundles();
  return (
    <div className="home-page">
      <DnaBanner loggedIn={loggedIn} />
      <div className="shop-list-container" id="shop-section">
        <div>
          <div className="shop-list">
            {products && products.length
              ? products.map((val, index) => {
                  return <ProductCard product={val} key={index} />;
                })
              : null}
          </div>
        </div>
      </div>
      <MatchWellnessProducts />
      <DnaGsr />
      <HowItWorks />
      <OurScience />
      <Privacy />
      <DnaWhatsInReport />
      <ReportOneWhatsIncluded />
      <FrequentlyAskedQuestions />
    </div>
  );
};

export default Home;
