const setLoginRedirect = (url, loggedIn) => {
  const forbiddenUrls = [
    "/",
    "/login",
    "/not-found",
    "/create-account",
    "/terms-and-conditions",
  ];

  if (!forbiddenUrls.includes(url) && !loggedIn) {
    localStorage.setItem("login_redirect", url);
  }
};

const loginRedirect = () => {
  const url = localStorage.getItem("login_redirect") || "/";
  localStorage.removeItem("login_redirect");
  return url;
};

export { setLoginRedirect, loginRedirect };
