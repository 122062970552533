import React from "react";
import { Link } from "react-router-dom";

const Footer = ({ kitRegistered }) => {
  return (
    <footer>
      <div className="footer-links">
        {!kitRegistered ? (
          <div>
            <Link to="/register">Register DNA Kit</Link>
          </div>
        ) : null}
        <div>
          <a
            href="https://shop.itovi.com/products/itovi-dna-collection-kit"
            target="_blank"
            rel="noopener noreferrer"
          >
            Purchase DNA Kit
          </a>
        </div>
        <div>
          <Link to="/terms-and-conditions">Terms & Conditions</Link>
        </div>
        <div>
          <a
            href="https://www.itovi.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
        </div>
      </div>
      <div className="copyright">
        <p>
          &copy; Copyright {new Date().getFullYear()} iTOVi, L.L.C. All rights
          reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
