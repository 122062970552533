import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { setLoginRedirect } from "./helpers/loginRedirect";
import { logout, getUserInfo } from "./helpers/authHelper";
import { getAllTraits, getAllReports } from "./helpers/axiosReportHelper";
import Loading from "./components/Loading";
import TopNavbar from "./components/layout/TopNavbar";
import Footer from "./components/layout/Footer";
import OpenApp from "./components/OpenApp";
import Section from "./pages/Section";
import Trait from "./pages/Trait";
import Report from "./pages/Report";
import Login from "./pages/auth/login";
import Register from "./pages/auth/register";
import DnaDashboard from "./pages/DnaDashboard";
import NotFound from "./pages/NotFound";
import SwabInstructions from "./pages/SwabInstructions";
import DnaKitRegistration from "./pages/DnaKitRegistration";
import TermsAndConditions from "./pages/legal/TermsAndConditions";
import AllTraits from "./pages/AllTraits";
import ScrollToTop from "./components/ScrollToTop";
import RightSlideOut from "./components/layout/RightSlideOut";
import MasterTraits from "./admin/pages/MasterTraits";
import PublishMasterTraits from "./admin/pages/PublishMasterTraits";
import Home from "./pages/Home";
import AboutReport from "./pages/AboutReport";

library.add(fas, fab);

// eslint-disable-next-line no-extend-native
Array.prototype.swapItems = function (a, b) {
  this[a] = this.splice(b, 1, this[a])[0];
  return this;
};

const App = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const [rightSlideOut, setRightSlideOut] = useState({
    active: false,
    component: null,
  });

  useEffect(() => {
    getLoginStatus();
  }, []);

  async function getLoginStatus() {
    setLoading(true);
    const result = await getUserInfo();
    if (result) {
      setUserInfo(result);
      setLoggedIn(true);
      await getAllReports(result.userId);
      await getAllTraits(result.userId);
    } else {
      setUserInfo({});
      setLoggedIn(false);
    }

    setLoading(false);
  }

  setLoginRedirect(useLocation().pathname, loggedIn);

  const GuestRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        loggedIn === false ? (
          <Component
            {...props}
            setLoggedIn={setLoggedIn}
            getLoginStatus={getLoginStatus}
          />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );

  const HomeRoute = ({ ...rest }) => (
    <Route
      {...rest}
      render={() => {
        if (!loggedIn || (userInfo && userInfo.kitRegistered === false)) {
          return <Home loggedIn={loggedIn} />;
        } else {
          return (
            <DnaDashboard
              userInfo={userInfo}
              setUserInfo={setUserInfo}
              setLoggedIn={setLoggedIn}
            />
          );
        }
      }}
    />
  );
  const AuthRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        loggedIn === true ? (
          <Component {...props} userInfo={userInfo} setLoggedIn={setLoggedIn} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );

  const AdminRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        loggedIn === true && userInfo && userInfo.admin ? (
          <Component
            {...props}
            setRightSlideOut={setRightSlideOut}
            userInfo={userInfo}
          />
        ) : (
          <Redirect to="/not-found" />
        )
      }
    />
  );

  const KitRegistrationRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) => {
        if (!loggedIn) {
          return <Redirect to="/login" />;
        } else {
          if (userInfo.kitRegistered !== false) {
            return <Redirect to="/" />;
          } else {
            return (
              <Component
                {...props}
                userInfo={userInfo}
                setLoggedIn={setLoggedIn}
                setUserInfo={setUserInfo}
              />
            );
          }
        }
      }}
    />
  );

  const ReportRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) => {
        if (!loggedIn) {
          return <Redirect to="/login" />;
        } else {
          if (userInfo.kitRegistered !== "false") {
            return <Component {...props} userInfo={userInfo} getLoginStatus={getLoginStatus} />;
          } else {
            return <Redirect to="/" />;
          }
        }
      }}
    />
  );

  const navProps = {
    loggedIn: loggedIn,
    setLoggedIn: setLoggedIn,
    logout: logout,
    user: {
      firstName: userInfo.first_name,
      lastName: userInfo.last_name,
      avatar: userInfo.avatar,
      admin: userInfo.admin,
      kitRegistered: userInfo.kitRegistered,
    },
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <ScrollToTop />
          <div className="layout-container">
            <div className="layout-item-1">
              <TopNavbar
                {...navProps}
                rightSlideOut={rightSlideOut}
                setRightSlideOut={setRightSlideOut}
              />
            </div>

            <div id="scroll-to-top" className="layout-item-2">
              <main>
                <div className="main-content">
                  <Switch>
                    <HomeRoute exact path="/" />
                    <AuthRoute
                      exact
                      path="/swab-instructions"
                      component={SwabInstructions}
                    />
                    <KitRegistrationRoute
                      exact
                      path="/register"
                      component={DnaKitRegistration}
                    />

                    <ReportRoute
                      exact
                      path="/report/:slug"
                      component={Report}
                    />

                    <ReportRoute
                      exact
                      path="/report/:reportKey/:slug"
                      component={Section}
                    />
                    <ReportRoute
                      exact
                      path="/report/:reportKey/:slug/:trait"
                      component={Section}
                    />

                    <ReportRoute
                      exact
                      path="/all-traits"
                      component={AllTraits}
                    />

                    <ReportRoute
                      exact
                      path="/all-traits/:traitKey"
                      component={Trait}
                    />

                    <GuestRoute exact path="/login" component={Login} />
                    <GuestRoute
                      exact
                      path="/create-account"
                      component={Register}
                    />
                    <Route
                      exact
                      path="/terms-and-conditions"
                      component={TermsAndConditions}
                    />

                    {process.env.REACT_APP_FEATURE_FLAG === "staging" ||
                    process.env.REACT_APP_FEATURE_FLAG === "development" ? (
                      <AdminRoute
                        exact
                        path="/admin/master-traits"
                        component={MasterTraits}
                      />
                    ) : null}
                    {process.env.REACT_APP_FEATURE_FLAG === "production" ||
                    process.env.REACT_APP_FEATURE_FLAG === "development" ? (
                      <AdminRoute
                        exact
                        path="/admin/publish-master-traits"
                        component={PublishMasterTraits}
                      />
                    ) : null}
                    {process.env.REACT_APP_FEATURE_FLAG === "staging" ||
                    process.env.REACT_APP_FEATURE_FLAG === "development" ? (
                      <Route
                        exact
                        path="/about/:reportName"
                        component={AboutReport}
                      />
                    ) : null}
                    <Route path="*" component={NotFound} />
                  </Switch>
                </div>
                <OpenApp />
                <Footer kitRegistered={userInfo.kitRegistered} />
              </main>
              <RightSlideOut
                rightSlideOut={rightSlideOut}
                setRightSlideOut={setRightSlideOut}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default App;
