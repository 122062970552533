import React from "react";
import ShowMoreCard from "../ShowMoreCard";
import { getColor } from "../../helpers/statusColorHelper";

const NutrientSummary = ({ data, title }) => {
  return (
    <div className="nutrient-summary content-container">
      <div className="nutrient-summary-heading">
        {data.imageUrl || data.imageUrl !== "" ? (
          <img src={data.imageUrl} alt="header" />
        ) : (
          <div className="rectangle-image-placeholder"></div>
        )}

        <div>{title}</div>
      </div>
      <div className="nutrient-summary-content">
        {data.sources ? (
          <>
            <div className="nutrient-sub-heading">
              What nutrients do I need?
            </div>
            <div className="nutrient-summary-table">
              <div className="nutrient-table-heading">
                <div>Nutrient</div>
                <div>Tendency</div>
                <div>Good Sources</div>
              </div>
              {data.sources.map((val, index) => {
                return (
                  <div className="nutrient-table-row" key={index}>
                    <div>{val.traitName}</div>
                    <div
                      style={{
                        fontWeight: "bold",
                        color: getColor(val.score.value),
                      }}
                    >
                      {val.score.scoreName}
                    </div>
                    <div>
                      {val.foodRecommendations
                        ? val.foodRecommendations.map((item, index) => {
                            return index === val.foodRecommendations.length - 1
                              ? item
                              : item + ", ";
                          })
                        : null}
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        ) : null}

        <div>
          {data.faqs
            ? data.faqs.map((val, index) => {
                return <ShowMoreCard key={index} data={val} />;
              })
            : null}
        </div>
      </div>
    </div>
  );
};

export default NutrientSummary;
