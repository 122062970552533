import axios from "axios";

async function getMasterReports() {
  const masterReports = await axios
    .get(process.env.REACT_APP_DNA_API + "/v1/masterreports")
    .then((response) => {
      if (response.data && response.data.length) {
        return response.data;
      }
    })
    .catch((error) => {});
  return masterReports;
}

async function getAllReports(id) {
  return await axios
    .get(process.env.REACT_APP_DNA_API + "/v2/reports/byuser/" + id, {
      headers: {
        authorization: "Token " + process.env.REACT_APP_DNA_API_KEY,
      },
    })
    .then((response) => {
      if (response.data) {
        localStorage.setItem(
          "allReports",
          JSON.stringify(response.data.reports)
        );
      } else {
        localStorage.removeItem("allReports");
      }
      return response;
    })
    .catch((err) => {
      if (err) localStorage.removeItem("allReports");
    });
}

async function getAllTraits(id) {
  return await axios
    .get(process.env.REACT_APP_DNA_API + "/v3/traits/byuser/" + id, {
      headers: {
        authorization: "Token " + process.env.REACT_APP_DNA_API_KEY,
      },
    })
    .then((response) => {
      if (response.data.traits) {
        localStorage.setItem("allTraits", JSON.stringify(response.data.traits));
      } else {
        localStorage.removeItem("allTraits");
        localStorage.setItem("allTraits", JSON.stringify([]));
      }
      return response;
    })
    .catch((err) => {
      localStorage.removeItem("allTraits");
      localStorage.setItem("allTraits", JSON.stringify([]));
    });
}

export { getAllTraits, getAllReports, getMasterReports };
