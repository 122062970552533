import React from "react";
import { useHistory } from "react-router-dom";
import ArrowRight from "../../assets/i-line-rightarrow.svg";
import { getColor } from "../../helpers/statusColorHelper";

const TraitNav = ({ active, section, reportKey, summary }) => {
  const history = useHistory();
  return (
    <div className="trait-nav">
      {summary ? (
        <div className="content-container">
          <div
            className={active === "summary" ? "trait active" : "trait"}
            onClick={() => {
              history.push(
                `/report/${reportKey}/${section.sectionKey}`.toLowerCase()
              );
            }}
          >
            <div>
              <div className="image">
                {summary.imageUrl || summary.imageUrl !== "" ? (
                  <img src={summary.imageUrl} alt="section summary" />
                ) : null}
              </div>
              <div className="name-status">{summary.title}</div>
              <div className="icon">
                <img src={ArrowRight} alt="arrow right" />
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <div className="content-container">
        {section.traits ? (
          section.traits.map((val, index) => {
            const status = val.scores.filter((obj) => obj.active === true)[0];
            return (
              <div
                key={index}
                className={
                  (active === 0 && index === 0) || active === val.traitId
                    ? "trait active"
                    : "trait"
                }
                onClick={() => {
                  history.push(
                    `/report/${reportKey}/${
                      section.sectionKey
                    }/${val.traitKey}`.toLowerCase()
                  );
                }}
              >
                <div>
                  <div className="image">
                    {val.croppedImages && val.croppedImages.circle ? (
                      <img src={val.croppedImages.circle} alt="trait" />
                    ) : val.imageUrl || val.imageUrl !== "" ? (
                      <img src={val.imageUrl} alt="trait" />
                    ) : null}
                  </div>
                  <div className="name-status">
                    <div className="name">{val.traitName}</div>
                    <div
                      className="status"
                      style={{
                        fontWeight: "bold",
                        color: getColor(status.value),
                      }}
                    >
                      {status.scoreName}
                    </div>
                  </div>
                  <div className="icon">
                    <img src={ArrowRight} alt="arrow right" />
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="trait trait-disabled">
            <div>
              <div className="image"></div>
              <div className="name-status">
                <div className="name">No Traits Available</div>
                <div className="status"></div>
              </div>
              <div className="icon"></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TraitNav;
