import React from "react";
import TraitCard from "./TraitCard";
import DefaultReport from "./DefaultReport";

const NutritionReport = ({ report, props }) => {
    const keyTraitsSection = report.sections.find(s => s.reportKey === "nutrition" && s.sectionKey === "KEY") || {};
    const lifestyleTraitsSection = report.sections.find((s) => s.reportKey === "nutrition" && s.sectionKey === "LIFESTYLE") || {};
    const nutrientTraitsSection = report.sections.find((s) => s.reportKey === "nutrition" && s.sectionKey === "NUTRITION") || {};
    return (
        <>
            <DefaultReport
                title={report.reportName}
                description={report.reportDescription}
                imageUrl={report.imageUrl}
                keyTraits={<TraitCard title="Key Traits" reportKey="nutrition" sectionKey="key" traits={keyTraitsSection.traits} />}>
                <div className="content-container">
                    <TraitCard title="Lifestyle Traits" reportKey="nutrition" sectionKey="lifestyle" description={lifestyleTraitsSection.description} traits={lifestyleTraitsSection.traits} />
                </div>
                <div className="content-container">
                    <TraitCard title="Nutrition Traits" reportKey="nutrition" sectionKey="nutrition" description={nutrientTraitsSection.description} traits={nutrientTraitsSection.traits} />
                </div>
            </DefaultReport>
        </>
    );
};

export default NutritionReport;
