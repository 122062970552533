import React, {useState} from "react";
import TraitCard from "./TraitCard";
import SummaryCard from "../../components/report/SummaryCard";
import ReportSummary from "../../components/report/ReportSummary"

const FitnessReport = ({ report, props }) => {
    const [readMore, setReadMore] = useState(false);
    const mentalSection = report.sections.find((s) => s.reportKey === "fitness" && s.sectionKey === "MENTAL");
    const trainingSection = report.sections.find((s) => s.reportKey === "fitness" && s.sectionKey === "TRAINING");
    const fuelSection = report.sections.find((s) => s.reportKey === "fitness" && s.sectionKey === "FUEL");
    const recoverySection = report.sections.find((s) => s.reportKey === "fitness" && s.sectionKey === "RECOVERY") || {};
    const summaryProps = {
        imageUrl: report.imageUrl,
        reportName: report.reportName,
        reportDescription: report.reportDescription
    };
    return (
        <>
            <div className="report-container xl-container">
                <div className="flex-half-container">
                    {!readMore ? (
                        <div className="content-container">
                            <SummaryCard {...summaryProps} />
                        </div>
                    ) : null}
                    <ReportSummary reportData={report.reportData} imageUrl={report.imageUrl} readMore={readMore} setReadMore={setReadMore}/>
                </div>
                {!readMore ? (
                    <div className="flex-half-container">
                        <div>
                            <div className="content-container">
                                <TraitCard title={mentalSection.sectionTitle} reportKey="fitness" sectionKey={mentalSection.sectionKey} traits={mentalSection.traits} />
                            </div>
                            <div className="content-container">
                                <TraitCard title={trainingSection.sectionTitle} reportKey="fitness" sectionKey={trainingSection.sectionKey} traits={trainingSection.traits} />
                            </div>
                        </div>
                        <div>
                            <div className="content-container">
                                <TraitCard title={fuelSection.sectionTitle} reportKey="fitness" sectionKey={fuelSection.sectionKey} traits={fuelSection.traits} />
                            </div>
                            <div className="content-container">
                                <TraitCard title={recoverySection.sectionTitle} reportKey="fitness" sectionKey={recoverySection.sectionKey} traits={recoverySection.traits} />
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        </>
    );
};

export default FitnessReport;
