import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form, Button, Row, Col } from "react-bootstrap";
import { format } from "date-fns";
import compareAsc from "date-fns/compareAsc";

const KitActivationQuestions = ({ navigation, formData, setForm }) => {
  const [errors, setErrors] = useState({});
  const [heritageOptions, setHeritageOptions] = useState(null);
  const { previous, next } = navigation;
  const { sex, birth_date, pregnant, weight, height, heritage } = formData;

  const supportDatePicker = () => {
    let input = document.createElement("input");
    input.setAttribute("type", "date");

    let notADateValue = "not-a-date";
    input.setAttribute("value", notADateValue);

    return input.value !== notADateValue;
  };

  const getHeritageOptions = async () => {
    const heritageOptions = await axios
      .get(process.env.REACT_APP_ITOVI_API + "/heritages/options")
      .then((result) => {
        if (result) return result.data.data;
      })
      .catch((err) => {
        console.log(err);
      });

    return heritageOptions;
  };

  useEffect(() => {
    getHeritageOptions().then((response) => {
      setHeritageOptions(response);
    });
  }, []);

  let defaultBirthdate;
  if (!supportDatePicker()) {
    if (!isNaN(new Date(birth_date)))
      defaultBirthdate = format(
        new Date(birth_date.replace(/-/g, "/")),
        "MM/dd/yyyy"
      );
  } else {
    defaultBirthdate = birth_date;
  }

  return (
    <div className="kit-activation-questions">
      <Row>
        <Col>
          <div className="dna-registration-form activation-questions">
            <Form>
              <Form.Group as={Row}>
                <Form.Label column sm={6}>
                  Birthday
                </Form.Label>
                <Col sm={6}>
                  <Form.Control
                    isInvalid={
                      errors && errors.birth_date ? errors.birth_date : false
                    }
                    type="date"
                    max={new Date().toISOString().split("T")[0]}
                    placeholder="MM/DD/YYYY"
                    name="birth_date"
                    onChange={setForm}
                    defaultValue={defaultBirthdate}
                    required
                  />
                  {errors.birth_date ? (
                    <div className="invalid-feedback d-block">
                      <span>{errors.birth_date}</span>
                    </div>
                  ) : null}
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm={6}>
                  Sex
                </Form.Label>
                <Col sm={6}>
                  <Form.Control
                    isInvalid={errors && errors.sex ? errors.sex : false}
                    id="form-sex-input"
                    name="sex"
                    onChange={setForm}
                    as="select"
                    required
                    custom
                    defaultValue={sex}
                  >
                    <option>Select</option>
                    <option value={0}>Female</option>
                    <option value={1}>Male</option>
                  </Form.Control>
                  {errors && errors.sex ? (
                    <div className="invalid-feedback d-block">
                      <span>Please select a sex</span>
                    </div>
                  ) : null}
                </Col>
              </Form.Group>
              {heritageOptions && heritageOptions.length ? (
                <Form.Group as={Row}>
                  <Form.Label column sm={6}>
                    Race
                  </Form.Label>
                  <Col sm={6}>
                    <Form.Control
                      isInvalid={errors && errors.race ? errors.race : false}
                      id="form-race-input"
                      name="heritage"
                      onChange={(e) => {
                        if (e.target.value === "select") {
                          setForm({
                            target: { name: "heritage", value: null },
                          });
                        } else {
                          setForm({
                            target: {
                              name: "heritage",
                              value: heritageOptions.filter(
                                (obj, index) =>
                                  obj.id === parseInt(e.target.value)
                              )[0],
                            },
                          });
                        }
                      }}
                      as="select"
                      required
                      custom
                      defaultValue={heritage ? heritage.id : 0}
                    >
                      <option value="select">Select</option>
                      {heritageOptions.map((val, index) => (
                        <option key={index} value={val.id}>
                          {val.name}
                        </option>
                      ))}
                    </Form.Control>
                    {errors && errors.race ? (
                      <div className="invalid-feedback d-block">
                        <span>Please select a race</span>
                      </div>
                    ) : null}
                  </Col>
                </Form.Group>
              ) : null}

              <Form.Group as={Row}>
                <Form.Label column sm={6}>
                  Weight (LB)
                </Form.Label>
                <Col sm={6}>
                  <Form.Control
                    isInvalid={errors && errors.weight ? errors.weight : false}
                    type="number"
                    min="1"
                    placeholder="0"
                    name="weight"
                    onChange={setForm}
                    defaultValue={weight}
                    required
                  />
                  {errors && errors.weight ? (
                    <div className="invalid-feedback d-block">
                      <span>Please enter a valid weight</span>
                    </div>
                  ) : null}
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm={6} className="justify-content-right">
                  Height (IN)
                </Form.Label>
                <Col sm={6}>
                  <Form.Control
                    isInvalid={errors && errors.height ? errors.height : false}
                    type="number"
                    min="0.0"
                    placeholder="0"
                    name="height"
                    onChange={setForm}
                    defaultValue={height}
                    required
                  />
                  {errors && errors.height ? (
                    <div className="invalid-feedback d-block">
                      <span>Please enter a valid height</span>
                    </div>
                  ) : null}
                </Col>
              </Form.Group>
              {sex === "0" ? (
                <Form.Group as={Row}>
                  <Form.Label className="pregnant" column sm={6}>
                    Are You Pregnant?
                  </Form.Label>
                  <Col sm={6}>
                    <div className="pregnant">
                      <Row>
                        <div className="d-flex pl-3 pr-3">
                          <Form.Label>Yes</Form.Label>
                          <Form.Check>
                            <Form.Check.Input
                              name="pregnant"
                              className="checkbox"
                              value="Yes"
                              onChange={setForm}
                              type="radio"
                              aria-label="radio yes"
                              required
                              defaultChecked={pregnant === "Yes" ? true : null}
                            />
                            <Form.Check.Label />
                          </Form.Check>
                        </div>
                        <div className="d-flex pl-3 pr-3">
                          <Form.Label>No</Form.Label>
                          <Form.Check>
                            <Form.Check.Input
                              className="checkbox"
                              name="pregnant"
                              value="No"
                              onChange={setForm}
                              type="radio"
                              aria-label="radio no"
                              defaultChecked={pregnant === "No" ? true : null}
                            />
                            <Form.Check.Label />
                          </Form.Check>
                        </div>
                      </Row>
                    </div>
                  </Col>
                  {errors && errors.pregnant ? (
                    <>
                      <Col sm={6}></Col>
                      <Col sm={6}>
                        <div className="invalid-feedback d-block">
                          <span>Please select an answer</span>
                        </div>
                      </Col>
                    </>
                  ) : null}
                </Form.Group>
              ) : null}

              <Row>
                <Col sm={6} className="mb-3">
                  <Button variant="outline-success" onClick={previous} block>
                    Previous
                  </Button>
                </Col>
                <Col sm={6} className="mb-3">
                  <Button
                    variant="success"
                    onClick={validateKitQuestions}
                    type="submit"
                    block
                  >
                    Next
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </Col>
        <Col className="text-white info-container">
          <h1 className="text-bold">Tell Us About You</h1>
          <p className="pt-3">
            We use this information along with your DNA results to make product
            recommendations and provide other insights.
          </p>
        </Col>
      </Row>
    </div>
  );

  /**
   * Validate form inputs for Kit Activation Questions
   * @param {event} e
   */
  function validateKitQuestions(e) {
    e.preventDefault();
    let errors = {};
    let pregnantAnswers = ["Yes", "No"];
    let genderOptions = ["0", "1"];

    if (isNaN(Date.parse(birth_date))) {
      errors.birth_date = "Not a valid date. EX: MM/DD/YYYY";
    } else {
      if (birth_date.length !== 10) {
        errors.birth_date = "Not a valid date. EX: MM/DD/YYYY";
      }
      if (
        compareAsc(new Date(birth_date.replace(/-/g, "/")), new Date()) >= 0
      ) {
        errors.birth_date = "Birthday can't be greater than current date";
      }
    }

    if (!weight || parseInt(weight) <= 0) {
      errors.weight = true;
    }

    if (!height || parseInt(height) <= 0) {
      errors.height = true;
    }

    if (!genderOptions.includes(sex)) {
      errors.sex = true;
    }

    if (sex === "0") {
      if (!pregnantAnswers.includes(pregnant)) {
        errors.pregnant = true;
      }
    }
    if (!heritage || !heritage.id) {
      errors.race = "Please select a race";
    }

    if (Object.keys(errors).length) {
      setErrors(errors);
      return;
    }

    next();
  }
};

export default KitActivationQuestions;
