import React from "react";
const FrequentlyAskedQuestions = () => {
    return (
        <div className="frequently-asked-questions-wrapper">
            <div className="frequently-asked-questions">
                <div  className="dna-faq-heading">
                    Frequently Asked Questions
                </div>
                <div className="faq-content">
                    <div>
                        <p>
                           <strong>How does iTOVi DNA testing work?</strong> <br /> We use noninvasive swabs to collect cells from the mouth. The swab is easy to use and only takes about a minute to collect enough DNA for analysis. Once collected, the swabs are mailed to our lab for analysis.
                        </p>
                        <p>
                            <strong>What genes are analyzed with the genetic test?</strong><br /> Our lab analyzes approximately 300 unique genetic markers that have been shown through external research to be related to various ways the human body processes food, nutrients and physical exercise, as well as how the body responds to the aging process.
                        </p>
                        <p>
                            <strong>When will I get my results?</strong><br /> Typical turnaround time for the entire process (swab kit shipping, DNA extraction and analysis, quality inspection, report generation) is approximately 30-40 days. You will be notified as soon as your report is ready.
                        </p>
                        <p>
                            <strong>Will you test for any serious inherited diseases?</strong><br /> No. iTOVi focuses solely on those genes that are related to the body’s ability to process food, nutrients and physical exercise. Any DNA test performed to detect predictors for disease should be interpreted by a physician or a certified genetic counselor.
                        </p>
                    </div>

                    <div>
                        <p>
                            <strong>What will happen to my sample after it has been analyzed?</strong><br /> Your DNA sample will be destroyed by the lab within 45 days of analyzing the DNA.
                        </p>
                        <p>
                            <strong>How is my personal information protected?</strong><br /> To prevent unauthorized access to your information, our systems are run on HIPAA-compliant architecture, employing secure servers, firewalls and encrypted back-ups.
                        </p>
                        <p>
                            <strong>Will my insurance cover the cost of a genetic test?</strong><br /> Probably not. Most insurance companies do not yet pay for genetic tests related to health management programs like iTOVi’s programs.
                        </p>
                        <p>
                            <strong>Can my insurance company or employer request or independently obtain a copy of my results?</strong><br /> No. Your privacy is protected by the Genetic Information Non-discrimination Act (GINA), which was instituted in the United States in May 2008. As a result, you are also not required to disclose genetic information to an employer or insurer. In addition, iTOVi will not respond to any request for test results from an insurer, employer or any other entity.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FrequentlyAskedQuestions;
