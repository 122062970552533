import React, { useState, useEffect, useCallback } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import itovi_logo from "../../assets/itovi_logo.png";
import BlankAvatar from "../../assets/blank_avatar.svg";
import icon_login from "../../assets/icon_login.svg";
import MyAccount from "../MyAccount";
import HomePageNav from "./HomePageNav";

const DASHBOARD_LINK = process.env.REACT_APP_FEATURE_FLAG === "production" ? "https://dashboard.itovi.com" : "http://staging.dashboard.itovi.com";

const TopNavbar = ({
  loggedIn,
  setLoggedIn,
  logout,
  user,
  rightSlideOut,
  setRightSlideOut,
}) => {
  const history = useHistory();
  const [toggleAuth, setToggleAuth] = useState(false);
  const [toggleBreadcrumb, setToggleBreadcrumb] = useState(false);
  const url = useLocation().pathname;
  const breadcrumbs = url.substring(1).split("/");
  const showBaseBreadcrumb = breadcrumbs[0] !== "" ? true : false;
  
    const lastBreadCrumb = breadcrumbs.pop().replace(/_/g, " ").replace(/-/g, " ");
  const hiddenPathNames = ["report", "admin"];
  const maxBreadcrumbLength = 30;

  const handleResize = useCallback(() => {
    const width = window.innerWidth || document.documentElement.clientHeight;

    if (width <= 767 && url === "/") {
      document
        .getElementsByClassName("layout-container")[0]
        .classList.add("mobile-nav");
    } else {
      document
        .getElementsByClassName("layout-container")[0]
        .classList.remove("mobile-nav");
    }
  }, [url]);

  const setAuthState = useCallback(() => {
    setToggleAuth(false);
  }, [setToggleAuth]);

  const setBreadcrumbState = useCallback(() => {
    setToggleBreadcrumb(false);
  }, [setToggleBreadcrumb]);

  useEffect(() => {
    const element = document.getElementsByClassName("layout-item-2")[0];

    element.addEventListener("click", setAuthState);
    element.addEventListener("click", setBreadcrumbState);

    if (!loggedIn || !user.kitRegistered) {
      window.addEventListener("resize", handleResize);
      handleResize();
    }
    return () => {
      window.removeEventListener("resize", handleResize);
      element.removeEventListener("click", setAuthState);
      element.removeEventListener("click", setBreadcrumbState);
    };
  }, [handleResize, loggedIn, user, url, setAuthState, setBreadcrumbState]);

  return (
      <div className="top-bar" id="top-bar">
          <div>
              <div className="top-bar-brand">
                  <Link to="/">
                      <img src={itovi_logo} alt="itovi" />
                  </Link>
              </div>
              <div className="breadcrumbs">
                  {showBaseBreadcrumb ? (
                      <div>
                          <Link to="/">DNA</Link>
                      </div>
                  ) : null}

                  {breadcrumbs.map((value, key) => {
                      if (hiddenPathNames.includes(value) || value === "") {
                          return null;
                      } else {
                          return (
                              <div key={key}>
                                  <span>&nbsp;/&nbsp;</span>
                                  <Link
                                      to={
                                          "/" +
                                          url
                                              .substring(1)
                                              .split("/", key + 1)
                                              .join("/")
                                      }
                                  >
                                    {value.length > maxBreadcrumbLength ? value.replace(/-/g, " ").replace(/_/g, " ").slice(0, maxBreadcrumbLength) + "..." : value.replace(/_/g, " ").replace(/-/g, " ")}
                                  </Link>
                              </div>
                          );
                      }
                  })}
                  {lastBreadCrumb ? (
                      <div>
                          <span>&nbsp;/&nbsp;</span>
                          {lastBreadCrumb.length > maxBreadcrumbLength ? lastBreadCrumb.replace(/-/g, " ").replace(/_/g, " ").slice(0, maxBreadcrumbLength) + "..." : lastBreadCrumb.replace(/_/g, " ").replace(/-/g, " ")}
                      </div>
                  ) : null}
              </div>
              <div
                  className="breadcrumb-dropdown"
                  onClick={() => {
                      setToggleBreadcrumb(!toggleBreadcrumb);
                      setToggleAuth(false);
                  }}
              >
                  {showBaseBreadcrumb ? (
                      <>
                          <div>
                              {lastBreadCrumb.length > maxBreadcrumbLength ? lastBreadCrumb.replace(/_/g, " ").replace(/-/g, " ") + "..." : lastBreadCrumb.replace(/_/g, " ").replace(/-/g, " ")}
                              <FontAwesomeIcon icon={["fas", "angle-down"]}  />
                          </div>
                          <div className={toggleBreadcrumb ? "dropdown active" : "dropdown"}>
                              <ul>
                                  {breadcrumbs.map((value, key) => {
                                      if (hiddenPathNames.includes(value) || value === "") {
                                          return null;
                                      } else {
                                          return (
                                              <li key={key}>
                                                  <Link
                                                      to={
                                                          "/" +
                                                          url
                                                              .substring(1)
                                                              .split("/", key + 1)
                                                              .join("/")
                                                      }
                                                  >
                                                    {value.length > maxBreadcrumbLength ? value.replace(/_/g, " ").replace(/-/g, " ").slice(0, maxBreadcrumbLength) + "..." : value.replace(/_/g, " ").replace(/-/g, " ")}
                                                  </Link>
                                              </li>
                                          );
                                      }
                                  })}
                                  <li>
                                      <Link to="/">DNA</Link>
                                  </li>
                              </ul>
                          </div>
                      </>
                  ) : null}
              </div>
              {(!loggedIn && url === "/") || (!user.kitRegistered && url === "/") ? (
                  <div className="dna-home-nav">
                      <HomePageNav user={user} />
                  </div>
              ) : null}
              {loggedIn === true ? (
                  <div
                      className="auth"
                      onClick={() => {
                          setToggleBreadcrumb(false);
                          setToggleAuth(!toggleAuth);
                      }}
                  >
                      <div className="avatar">{loggedIn === false || user.avatar === undefined || user.avatar.length === 0 ? <img src={BlankAvatar} alt="avatar" /> : <img src={user.avatar[0].url} alt="avatar" />}</div>
                      <div className="name">
                          <span>{loggedIn === true ? user.firstName + " " + user.lastName : "Account"}</span>
                      </div>
                      <div>
                          <FontAwesomeIcon icon={["fas", "chevron-down"]} color="#B3B3B3" style={{fontSize:"14px"}} />
                      </div>
                      <div className={toggleAuth ? "dropdown active" : "dropdown"}>
                          <ul>
                              {loggedIn === false ? (
                                  <>
                                      <li>
                                          <Link to="/login">Login</Link>
                                      </li>
                                      <li>
                                          <Link to="/create-account">Create Account</Link>
                                      </li>
                                  </>
                              ) : (
                                  <>
                                      {process.env.REACT_APP_FEATURE_FLAG === "staging" || process.env.REACT_APP_FEATURE_FLAG === "development" ? (
                                          <li>
                                              <Link
                                                  to=""
                                                  onClick={() => {
                                                      setRightSlideOut({
                                                          active: true,
                                                          component: MyAccount,
                                                          props: user
                                                      });
                                                  }}
                                              >
                                                  My Account
                                              </Link>
                                          </li>
                                      ) : null}
                                      <li>
                                          <a href={DASHBOARD_LINK} target="_self" >
                                              Dashboard
                                          </a>
                                      </li>
                                      {(process.env.REACT_APP_FEATURE_FLAG === "staging" && user && user.admin === true) || (process.env.REACT_APP_FEATURE_FLAG === "development" && user && user.admin === true) ? (
                                          <li>
                                              <Link to="/admin/master-traits">Master Traits</Link>
                                          </li>
                                      ) : null}
                                      {(process.env.REACT_APP_FEATURE_FLAG === "production" && user && user.admin === true) || (process.env.REACT_APP_FEATURE_FLAG === "development" && user && user.admin === true) ? (
                                          <li>
                                              <Link to="/admin/publish-master-traits">Publish Master Traits</Link>
                                          </li>
                                      ) : null}
                                      <li>
                                          <Link to="" onClick={() => logout(setLoggedIn)}>
                                              Logout
                                          </Link>
                                      </li>
                                  </>
                              )}
                          </ul>
                      </div>
                  </div>
              ) : (
                  <div className="sign-in">
                      <button type="button" className="button button-primary button-sm" onClick={() => history.push("/login")}>
                          Login
                      </button>
                  </div>
              )}
          </div>
          {(!loggedIn && url === "/") || (!user.kitRegistered && url === "/") ? (
              <div className="mobile-home-nav">
                  <HomePageNav user={user} />
              </div>
          ) : null}
      </div>
  );
};

export default TopNavbar;
