import React, { useEffect, useState } from "react";
import SingleKitTracker from "../components/kitTracker/SingleKitTracker";
import ReportCard from "../components/dnaHome/ReportCard";
import Privacy from "../components/dnaHome/Privacy";
import OurScience from "../components/dnaHome/OurScience";
import { getMasterReports } from "../helpers/axiosReportHelper";
import AllTraitsCard from "../components/dnaHome/AllTraitsCard";
import RedeemModal from "../components/dnaHome/RedeemModal";
import HeritageModal from "../components/dnaHome/HeritageModal";
import CustomerSupportModal from "../components/CustomerSupportModal";
import CongratsModal from "../components/dnaHome/CongratsModal";

const DnaDashboard = ({ userInfo, setUserInfo }) => {
  const [masterReports, setMasterReports] = useState([]);
  const [redeemModal, setRedeemModal] = useState(null);
  const [heritageModal, setHeritageModal] = useState(null);
  const [customerSupport, setCustomerSupport] = useState(false);
  const [congratsModal, setCongratsModal] = useState({
    active: false,
    message: null,
  });

  const trackerProps = {
    userId: userInfo.userId,
    username: userInfo.first_name,
    avatar: userInfo.avatar,
    kitRegistered: userInfo.kitRegistered,
  };

  useEffect(() => {
    let isSubscribed = true;

    getMasterReports().then((response) => {
      if (isSubscribed && response) setMasterReports(response);
    });

    return () => (isSubscribed = false);
  }, [setMasterReports]);

  return (
    <>
      <div className="dna-dashboard xl-container">
        {trackerProps && trackerProps.kitRegistered === "completed" ? null : (
          <div className="content-container">
            <SingleKitTracker {...trackerProps} />
          </div>
        )}
        <div className="dna-report-section">
          {trackerProps && trackerProps.kitRegistered === "completed" ? (
            <div className="dna-report-title">My Reports</div>
          ) : null}
          <div className="dna-report-list">
            {masterReports
              ? masterReports.map((val, index) => {
                  return (
                    <ReportCard
                      key={index}
                      report={val}
                      userInfo={userInfo}
                      setRedeemModal={setRedeemModal}
                      setHeritageModal={setHeritageModal}
                    />
                  );
                })
              : null}
            {userInfo && userInfo.kitRegistered === "completed" ? (
              <AllTraitsCard />
            ) : null}
          </div>
        </div>
      </div>

      {customerSupport ? (
        <CustomerSupportModal setCustomerSupport={setCustomerSupport} />
      ) : null}

      <RedeemModal
        {...redeemModal}
        setRedeemModal={setRedeemModal}
        setCustomerSupport={setCustomerSupport}
        setCongratsModal={setCongratsModal}
      />
      <HeritageModal
        {...heritageModal}
        setHeritageModal={setHeritageModal}
        setUserInfo={setUserInfo}
        setCustomerSupport={setCustomerSupport}
      />
      <CongratsModal
        congratsModal={congratsModal}
        setCongratsModal={setCongratsModal}
      />
      <OurScience />
      <Privacy />
    </>
  );
};

export default DnaDashboard;
