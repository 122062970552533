import React from "react";

const AboutReport = () => {
  const report = {
    reportId: 2,
    reportName: "Balance",
    imageUrl:
      "https://dm8j30w0c9dtk.cloudfront.net/reports/SMALL-Balance-REPORT.jpg",
    reportDescription:
      "You are about to receive insights about your body that, up until now, have never been available. The science of the human body only recently evolved enough to allow scientists to identify and analyze a plethora of stuff.",
    traits: [
      "calcium",
      "carb",
      "fat",
      "protein",
      "magnesium",
      "stress_tolerance",
      "iron",
      "phosphorus",
      "vitamin_a",
      "vitamin_c",
      "vitamin_b12",
      "vitamin_b9",
      "vitamin_b6",
      "vitamin_d",
      "vitamin_e",
      "copper",
      "selenium",
      "zinc",
      "systemic_inflammation",
    ],
  };
  return (
    <>
      {report ? (
        <div className="about-report">
          <div className="report-header">
            <img src={report.imageUrl} alt="report" />
            <div>
              <div>{`${report.reportName} Report`}</div>
            </div>
          </div>
          <div className="description">
            <div>Description</div>
            <div>{report.reportDescription}</div>
          </div>
          {report.traits.length ? (
            <div className="included-traits">
              <div>Included Traits</div>
              <ul>
                {report.traits.map((val, index) => {
                  return <li key={index}>{val.split("_").join(" ")}</li>;
                })}
              </ul>
            </div>
          ) : null}
        </div>
      ) : (
        <div className="md-container content-container">
          <h4 className="p-4">Report Not Found</h4>
        </div>
      )}
    </>
  );
};

export default AboutReport;
