import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { getActiveHomeNav } from "../../helpers/scrollHelper";

const HomePageNav = ({ user }) => {
  let currentPath = useLocation().pathname;
  const [activeSection, setActiveSection] = useState("");

  const scrollToSection = (id) => {
    const scrollToTop = document.getElementById("scroll-to-top");
    scrollToTop.setAttribute("style", "overflow-y: hidden;");

    setTimeout(() => {
      document.getElementById("scroll-to-top").removeAttribute("style");
      document.getElementById(id).scrollIntoView({ behavior: "smooth" });
    }, 10);
  };

  const setActive = useCallback(() => {
    setActiveSection(getActiveHomeNav());
  }, [setActiveSection]);

  const setScrollListener = useCallback(
    (element) => {
      if (
        (currentPath === "/" && !user) ||
        (currentPath === "/" && !user.kitRegistered)
      ) {
        element.addEventListener("scroll", setActive);
      } else {
        element.removeEventListener("scroll", setActive);
      }
    },
    [setActive, currentPath, user]
  );

  useEffect(() => {
    const element = document.getElementsByClassName("layout-item-2")[0];
    setScrollListener(element);

    return () => {
      element.removeEventListener("scroll", setActive);
    };
  }, [currentPath, setActive, user, setScrollListener]);

  return (
    <>
      <div
        className={activeSection === "how-it-works" ? "active" : null}
        onClick={() => scrollToSection("how-it-works")}
      >
        How It Works
      </div>
      <div
        className={activeSection === "science" ? "active" : null}
        onClick={() => scrollToSection("science")}
      >
        Science
      </div>
      <div
        className={activeSection === "privacy" ? "active" : null}
        onClick={() => scrollToSection("privacy")}
      >
        Privacy
      </div>
    </>
  );
};

export default HomePageNav;
