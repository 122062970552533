import React from "react";
import EmailUs from "../assets/email_icon.svg";
import CallUs from "../assets/phone_icon.svg";

const CustomerSupportModal = ({ setCustomerSupport }) => {
  return (
      <div className="modal-wrapper blur-background support-modal-wrapper">
          <div className="support-modal">
              <div className="support-header">
                  <div className="header-title">Customer Support</div>
                  <div className="dismiss" onClick={() => setCustomerSupport(false)}></div>
              </div>
              <div className="support-description">Contact us during business hours: 8am - 5pm</div>
              <div className="contact-list">
                  <div className="contact-item">
                      <div className="contact-icon">
                          <img src={CallUs} alt="Call Us" />
                      </div>
                      <div className="contact-content">
                          <div className="contact-title">Call Us</div>
                          <div className="contact-source">
                              <a href="tel:+1-877-483-3748">877-483-3748</a>
                          </div>
                      </div>
                  </div>
                  <div className="contact-item">
                      <div className="contact-icon">
                          <img src={EmailUs} alt="Email Us" />
                      </div>
                      <div className="contact-content">
                          <div className="contact-title">Email Us</div>
                          <div className="contact-source">
                              <a href="mailto:support@itovi.com" target="_blank" rel="noopener noreferrer">
                                  support@itovi.com
                              </a>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  );
};

export default CustomerSupportModal;
