export const scrollToTop = () => {
  document.getElementById("scroll-to-top").scrollTop = 0;
};

export const getActiveHomeNav = () => {
  if (!document.getElementById("how-it-works")) return;

  let activeSection = null;
  const howItWorks = isInView(
    document.getElementById("how-it-works").getBoundingClientRect()
  );
  const ourScience = isInView(
    document.getElementById("science").getBoundingClientRect()
  );
  const privacy = isInView(
    document.getElementById("privacy").getBoundingClientRect()
  );

  if (howItWorks) {
    activeSection = "how-it-works";
  }
  if (ourScience) {
    activeSection = "science";
  }
  if (privacy) {
    activeSection = "privacy";
  }

  return activeSection;
};

const isInView = (elem) => {
  return (
    elem.top >= 0 &&
    elem.left >= 0 &&
    elem.right <= (window.innerWidth || document.documentElement.clientWidth) &&
    elem.bottom <= (window.innerHeight || document.documentElement.clientHeight)
  );
};
