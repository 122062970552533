import React, { useState } from "react";
import BarChart from "../BarChart";
import CollapsableCard from "./CollapsableCard";
import MarkdownIt from "markdown-it";
import UpdateUserModal from "../UpdateUserModal";

let md = new MarkdownIt();

const FoodSummary = ({ data, title, user, kitId, getLoginStatus }) => {
  const [weightNav, setWeightNav] = useState(0);
  const summaryData = weightNav === 0 ? data.maintaining : data.weightLoss;
  const [userModal, setUserModal] = useState(false);

  return (
    <div className="food-summary content-container">
      <div className="heading">
        {data.imageUrl || data.imageUrl !== "" ? (
          <img src={data.imageUrl} alt="header" />
        ) : (
          <div className="rectangle-image-placeholder"></div>
        )}
        <div>{title}</div>
      </div>
      <div className="subheading">
        <div className="title">{data.title}</div>
        <div className="description">{data.description}</div>
      </div>
      <div className="weight-management">
        <div className="weight-navigation">
          <div
            className={weightNav === 0 ? "active" : ""}
            onClick={() => setWeightNav(0)}
          >
            Maintaining my weight
          </div>
          <div
            className={weightNav === 1 ? "active" : ""}
            onClick={() => setWeightNav(1)}
          >
            Info for Weight Loss
          </div>
        </div>
        <div className="macros">
          <div>
            <div className="title">
              <span>Macros</span> <span>(By Type)</span>
            </div>
            <BarChart chartData={summaryData.macros} />
          </div>
        </div>
        <div className="weight-loss-heading">
          <div className="calories">{summaryData.calories}</div>
          <div className="title">Recommended Daily total calories</div>
          <div className="edit-info">
            <p>
              Confirm your personal info is correct for the most accurate
              daily calorie recommendation
            </p>
            <button
              className="button button-outline-primary"
              onClick={() => setUserModal(true)}
            >
              Edit Info
            </button>
          </div>
          <div
            className="weight-content"
            dangerouslySetInnerHTML={{
              __html: md.render(summaryData.description),
            }}
          ></div>
        </div>
      </div>
      {
        <div>
          {summaryData.macros.length
            ? summaryData.macros.map((val, index) => {
                return <CollapsableCard data={val} key={index} />;
              })
            : null}
        </div>
      }
      <UpdateUserModal
        active={userModal}
        setActive={setUserModal}
        user={user}
        kitId={kitId}
        getLoginStatus={getLoginStatus}
      />
    </div>
  );
};

export default FoodSummary;
