import React from "react";

const RightSlideOut = ({ rightSlideOut, setRightSlideOut }) => {
  const Component = () => {
    if (!rightSlideOut.component) return null;
    return React.createElement(rightSlideOut.component, {
      ...rightSlideOut.props,
    });
  };
  return (
    <div
      className={
        rightSlideOut.active
          ? "right-slide-out-wrapper active"
          : "right-slide-out-wrapper deactive"
      }
    >
      <div className="right-slide-out">
        <button
          onClick={() => {
            setRightSlideOut({ active: false, component: null });
          }}
        >
          click me
        </button>
        {Component()}
      </div>
    </div>
  );
};

export default RightSlideOut;
