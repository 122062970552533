import React, {useState} from "react";
import TraitCard from "./TraitCard";
import SummaryCard from "./SummaryCard";
import ReportSummary from "./ReportSummary";

const BeautyReport = ({ report, props }) => {
    const [readMore, setReadMore] = useState(false);
    const reportKey = "beauty";
    const lookSection = report.sections.find((s) => s.reportKey === reportKey && s.sectionKey === "LOOK");
    const feelSection = report.sections.find((s) => s.reportKey === reportKey && s.sectionKey === "FEEL");
    const functionSection = report.sections.find((s) => s.reportKey === reportKey && s.sectionKey === "FUNCTION");
    const summaryProps = {
        imageUrl: report.imageUrl,
        reportName: report.reportName,
        reportDescription: report.reportDescription
    };
    return (
        <>
            <div className="report-container xl-container">
                <div className="flex-half-container">
                    {!readMore ? (
                        <div className="content-container">
                            <SummaryCard {...summaryProps} />
                        </div>
                    ) : null}
                    <ReportSummary reportData={report.reportData} imageUrl={report.imageUrl} readMore={readMore} setReadMore={setReadMore}/>
                </div>
                {!readMore ? (
                    <div className="flex-half-container">
                        <div>
                            <div className="content-container">
                                <TraitCard title={lookSection.sectionTitle} reportKey="beauty" sectionKey={lookSection.sectionKey} traits={lookSection.traits} />
                            </div>
                            <div className="content-container">
                                <TraitCard title={feelSection.sectionTitle} reportKey="beauty" sectionKey={feelSection.sectionKey} traits={feelSection.traits} />
                            </div>
                        </div>
                        <div>
                            <div className="content-container">
                                <TraitCard title={functionSection.sectionTitle} reportKey="beauty" sectionKey={functionSection.sectionKey} traits={functionSection.traits} />
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        </>
    );
};

export default BeautyReport;
