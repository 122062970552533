import React, { useState } from "react";
import axios from "axios";
import ErrorIcon from "../../assets/warning.svg";
import LoadingDots from "../../components/LoadingDots";

const RedeemCodeHelp = ({
  setDontHaveCode,
  closeModal,
  setEmailSent,
  setCustomerSupport,
}) => {
  const [recoverWith, setRecoverWith] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const sendCodes = () => {
    if (!recoverWith.length) {
      setError("Entry cannot be empty");
      return;
    }
    setLoading(true);
    axios
      .post(
        process.env.REACT_APP_DNA_API + "/v1/redemptioncodes/recover",
        { recoverWith: recoverWith },
        {
          headers: {
            authorization: "Token " + process.env.REACT_APP_DNA_API_KEY,
          },
        }
      )
      .then((response) => {
        setEmailSent({ active: true, email: response.data.email });
        setLoading(false);
        closeDontHaveCode();
      })
      .catch((error) => {
        setLoading(false);
        setError(error.response.data.error);
      });
  };

  const closeDontHaveCode = () => {
    setError(null);
    setRecoverWith("");
    setDontHaveCode(false);
  };

  return (
    <>
      <div className="modal-card redeem-code-helper">
        <div className="modal-card-header">
          <div className="header-title">Find Redemption Code</div>
          <div className="dismiss" onClick={() => closeModal()}></div>
        </div>
        <div className="redeem-input-container">
          <div className="input-label">
            <div>Enter Email or Order Number</div>
            {error ? (
              <div className="redeem-response error">
                <img src={ErrorIcon} alt="" />
                Error
              </div>
            ) : null}
          </div>
          <input
            name="redeemBy"
            className={error ? "error" : ""}
            type="text"
            value={recoverWith}
            required
            onChange={(e) => setRecoverWith(e.target.value)}
            onFocus={() => setError(null)}
          />
          {error ? <div className="error-message">{error}</div> : null}
        </div>
        <div className="redeem-description">
          Redemption codes are located in the email receipt from the purchase of
          the Nutrition Report.{" "}
          <span
            className="redeem-text-link"
            onClick={() => setCustomerSupport(true)}
          >
            Contact Customer Support
          </span>
        </div>
        <div className="redeem-description">
          Interested in purchasing a DNA Report?{" "}
          <span
            className="redeem-text-link"
            onClick={() => window.open("https://shop.itovi.com/collections/itovi-dna", "_blank")}
          >
            Buy Now
          </span>
        </div>
        <div className="redeem-actions">
          <div className="redeem-buttons">
            <button
              type="button"
              className="button button-sm button-outline-secondary"
              onClick={() => closeDontHaveCode()}
            >
              Back
            </button>
            <button
              type="button"
              className="button button-sm button-primary"
              onClick={() => loading ? null : sendCodes()}
            >
              {loading ?
                  <LoadingDots size="medium" color="white"/>
                  : "Next"
              }
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default RedeemCodeHelp;
