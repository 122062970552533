import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { emptyScore, emptyStrategy } from "../../../helpers/masterTraitSchemas";
import { getColor } from "../../../helpers/statusColorHelper";

const MasterTraitScores = ({ editScore, setTrait, setEditScore, trait }) => {
  const [score, setScore] = useState(
    editScore.score ? editScore.score : emptyScore()
  );
  const [activeStrategies, setActiveStrategies] = useState([]);
  const scoreValues = [-2, -1, 0, 1, 2];
  const saveScore = () => {
    let newScores = null;
    if (editScore.index !== null) {
      newScores = trait.scores;
      newScores[editScore.index] = score;

      setTrait({ ...trait, scores: newScores });
    } else {
      setTrait({ ...trait, scores: [...trait.scores, score] });
    }
    setEditScore({ active: false, index: null, score: null });
  };

  const updateSuccessStrategy = (e, index) => {
    const name = e.target.name;
    const value = e.target.value;

    let strategies = score.successStrategies;
    strategies[index][name] = value;

    setScore({ ...score, successStrategies: strategies });
  };

  const updateSingleValue = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setScore({
      ...score,
      [name]: name === "value" ? parseInt(value) : value,
    });
  };

  const onDragStart = (e, key) => {
    e.dataTransfer.setData("key", key);
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const onDrop = (e, key) => {
    const prevPosition = e.dataTransfer.getData("key");
    let strategies = score.successStrategies;
    strategies.swapItems(prevPosition, key);
    setScore({ ...score, successStrategies: strategies });
  };

  return (
    <div className="master-trait-scores">
      {score ? (
        <div className="flex-half-container">
          <div>
            <div className="score-heading">
              <div className="score-heading-title">Score Details</div>
            </div>
            <div className="form-input-container">
              <div className="form-input-label">Score Name</div>
              <div className="form-input-field">
                <input
                  type="text"
                  name="scoreName"
                  value={score.scoreName}
                  onChange={updateSingleValue}
                />
              </div>
            </div>
            <div className="form-input-container">
              <div className="form-input-label">Lab Key</div>
              <div className="form-input-field">
                <input
                  type="text"
                  name="labKey"
                  value={score.labKey}
                  onChange={updateSingleValue}
                />
              </div>
            </div>
            <div className="form-input-container">
              <div className="form-input-label">Score Value</div>
              <div className="form-inline">
                <div className="form-input-field">
                  <select
                    name="value"
                    value={score.value}
                    onChange={updateSingleValue}
                  >
                    {scoreValues.map((val, index) => {
                      return (
                        <option
                          value={val}
                          style={{ backgroundColor: "#000" }}
                          key={index}
                        >
                          {val}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div>
                  <div
                    style={{
                      width: "38px",
                      height: "38px",
                      borderRadius: "8px",
                      backgroundColor: getColor(score.value),
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <div className="form-input-container">
              <div className="form-input-label">Score Statement</div>
              <div className="form-input-field">
                <textarea
                  name="scoreStatement"
                  value={score.scoreStatement}
                  onChange={updateSingleValue}
                />
              </div>
            </div>
            <div className="form-input-container">
              <div className="form-input-label">Score Description</div>
              <div className="form-input-field">
                <textarea
                  type="text"
                  name="scoreDescription"
                  value={score.scoreDescription}
                  onChange={updateSingleValue}
                />
              </div>
            </div>
          </div>
          <div>
            <div className="score-heading">
              <div className="score-heading-title">Success Strategies</div>
              <div>
                <button
                  type="button"
                  className="button button-outline-primary button-sm"
                  onClick={() => {
                    setScore({
                      ...score,
                      successStrategies: [
                        ...score.successStrategies,
                        emptyStrategy(),
                      ],
                    });
                  }}
                >
                  Add New
                </button>
              </div>
            </div>
            <div
              className="droppable"
              onDragOver={(e) => {
                onDragOver(e);
              }}
            >
              {score.successStrategies
                ? score.successStrategies.map((strategy, key) => {
                    return (
                      <div
                        onDragStart={(e) => onDragStart(e, key)}
                        onDrop={(e) => onDrop(e, key)}
                        draggable={!activeStrategies.includes(key)}
                        className={
                          activeStrategies.includes(key)
                            ? "success-strategy active"
                            : "success-strategy"
                        }
                        key={key}
                      >
                        <div
                          className="strategy-title"
                          onClick={() =>
                            activeStrategies.includes(key)
                              ? setActiveStrategies(
                                  activeStrategies.filter((obj) => obj !== key)
                                )
                              : setActiveStrategies([...activeStrategies, key])
                          }
                        >
                          <div>
                            {strategy.title !== ""
                              ? strategy.title
                              : "( No Title )"}
                          </div>

                          <div>
                            {activeStrategies.includes(key) ? null : (
                              <FontAwesomeIcon icon={("fas", "arrows-alt")} />
                            )}

                            <FontAwesomeIcon
                              icon={
                                activeStrategies.includes(key)
                                  ? ["fas", "angle-up"]
                                  : ["fas", "angle-down"]
                              }
                            />
                          </div>
                        </div>
                        <div>
                          <div>
                            <div className="form-input-container">
                              <div className="form-input-label">Title</div>
                              <div className="form-input-field">
                                <input
                                  type="text"
                                  name="title"
                                  value={strategy.title ? strategy.title : ""}
                                  onChange={(e) =>
                                    updateSuccessStrategy(e, key)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form-input-container">
                            <div className="form-input-label">Description</div>
                            <div className="form-input-field">
                              <textarea
                                name="text"
                                value={strategy.text ? strategy.text : ""}
                                onChange={(e) => updateSuccessStrategy(e, key)}
                              />
                            </div>
                          </div>
                          <div className="form-input-container">
                            <div className="form-input-label">Image Url</div>
                            <div className="form-input-field">
                              <input
                                type="text"
                                name="imageUrl"
                                value={
                                  strategy.imageUrl ? strategy.imageUrl : ""
                                }
                                onChange={(e) => updateSuccessStrategy(e, key)}
                              />
                            </div>
                            <div className="remove">
                              <button
                                type="button"
                                className="button button-danger button-sm"
                                onClick={() => {
                                  setScore({
                                    ...score,
                                    successStrategies: score.successStrategies.filter(
                                      (obj, index) => key !== index
                                    ),
                                  });
                                  setActiveStrategies([]);
                                }}
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        </div>
      ) : null}
      <div className="score-actions">
        <div>
          <button
            type="button"
            className="button button-primary button-sm"
            onClick={saveScore}
          >
            Done
          </button>
        </div>
        <div>
          <button
            type="button"
            className="button button-danger button-sm"
            onClick={() => {
              setEditScore({ active: false, index: null, score: {} });
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default MasterTraitScores;
