import React from "react";
import ItoviDnaLogo from "../assets/iTOVi_DNA_Logo_W_2x.png";
import { useStep, useForm } from "react-hooks-helper";
import KitPreScan from "../components/kitRegistration/KitPreScan";
import KitActivationQuestions from "../components/kitRegistration/KitActivationQuestions";
import ReviewKitActivation from "../components/kitRegistration/ReviewKitActivation";
import TermsOfService from "../components/kitRegistration/TermsOfService";

const steps = [
  { id: "KitPreScan" },
  { id: "KitActivationQuestions" },
  { id: "TermsOfService" },
  { id: "ReviewKitActivation" },
];

const DnaKitRegistrationForm = (props) => {
  const { userInfo, setUserInfo, setLoggedIn } = props;
  const [formData, setForm] = useForm({
    ...userInfo,
  });

  const { step, navigation } = useStep({ initialStep: 0, steps });
  const formProps = { navigation, formData, setForm, setLoggedIn };

  const renderForm = () => {
    if (step.id === "KitPreScan") return <KitPreScan {...formProps} />;
    if (step.id === "KitActivationQuestions")
      return <KitActivationQuestions {...formProps} />;
    if (step.id === "TermsOfService") return <TermsOfService {...formProps} />;
    if (step.id === "ReviewKitActivation")
      return (
        <ReviewKitActivation
          {...formProps}
          setLoggedIn={setLoggedIn}
          userInfo={userInfo}
          setUserInfo={setUserInfo}
        />
      );
  };
  return (
    <div className="dna-registration-container">
      <div className="dna-kit-registration">
        <div className="dna-logo d-flex justify-content-center">
          <img
            className="dna-form-logo"
            src={ItoviDnaLogo}
            alt="Itovi Dna Logo"
          />
        </div>
        {renderForm()}
      </div>
    </div>
  );
};

export default DnaKitRegistrationForm;
