import React from "react";

const ProductCard = ({ product }) => {
  return (
    <>
      {product ? (
        <div className="product-card">
          <div className="product-name">{product.name}</div>
          <div className="product-image">
            <img src={product.imageUrl} alt="" />
          </div>
          <div>
            <div className="buy-now">
              <div className="product-price">
                <sup style={{ fontSize: "26px" }}>$</sup>
                {product.price}
                {product.monthlyPrice ? (
                  <span className="monthly-price">
                    &nbsp;(+<sup>$</sup>
                    {product.monthlyPrice}
                    <sup>.99</sup>/mo.)
                  </span>
                ) : null}
              </div>
              <button
                className="buy-now-btn"
                onClick={() => window.open(product.link, "_blank")}
              >
                {product.buttonText}
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ProductCard;
