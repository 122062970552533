import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MarkdownIt from "markdown-it";

let md = new MarkdownIt();
const ShowMoreCard = ({ data }) => {
  const [showMore, setShowMore] = useState(false);
  const showMoreButton = data && data.text ? data.text.search("\n\n") : -1;

  return (
    <>
      {data ? (
        <div className="show-more-card">
          {data.title ? (
            <div className="show-more-heading">{data.title}</div>
          ) : null}

          <div className="show-more-container">
            {data.imageUrl && data.imageUrl !== "" ? (
              <div className="image-container">
                <img src={data.imageUrl} alt="block" />
              </div>
            ) : null}
            {data.text ? (
              <div
                className={
                  showMore ? "show-more-content active" : "show-more-content"
                }
                dangerouslySetInnerHTML={{ __html: md.render(data.text) }}
              ></div>
            ) : null}

            <div style={{ clear: "left" }}></div>
          </div>
          {showMoreButton >= 0 ? (
            <div className="show-more-button">
              <button
                onClick={() => {
                  setShowMore(!showMore);
                }}
              >
                {showMore ? "Show Less" : "Show More"}
                <FontAwesomeIcon
                  icon={showMore ? ["fas", "angle-up"] : ["fas", "angle-down"]}
                />
              </button>
            </div>
          ) : null}
        </div>
      ) : null}
    </>
  );
};

export default ShowMoreCard;
