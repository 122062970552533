import React, { useState } from "react";
import { getColor } from "../../helpers/statusColorHelper";
import MarkdownIt from "markdown-it";
import TraitStatusBar from "./TraitStatusBar";
import ShowMoreCard from "../ShowMoreCard";
import RecommendedProductCard from "../RecommendedProductCard";
import BlankImage from "../../assets/blank_1542x464.png";

let md = new MarkdownIt();

const TraitSummary = ({ data, userId }) => {
  const [traitNav, setTraitNav] = useState(0);
  const scoreStatement = data && data.scoreStatement ? data.scoreStatement.split("**") : null;

  const getActiveScore = () => {
    const activeScore = data.scores.filter((obj) => obj.active === true)[0];
    return activeScore && activeScore.scoreName ? activeScore.scoreName : "";
  };

  return (
      <>
          {data ? (
              <div className="trait-summary-container content-container">
                  <div className="trait-summary-header">
                      <img src={data.imageUrl || data.imageUrl !== "" ? data.imageUrl : BlankImage} alt="header" />

                      <div>{data.traitName}</div>
                  </div>
                  <div className="trait-summary-nav">
                      <div className={traitNav === 0 ? "active" : ""} onClick={() => setTraitNav(0)}>
                          What Your Genes Say
                      </div>
                      <div className={traitNav === 1 ? "active" : ""} onClick={() => setTraitNav(1)}>
                          Genes SNP'S And Studies
                      </div>
                  </div>
                  <div className="trait-nav-content">
                      {traitNav === 0 ? (
                          <>
                              <div className="trait-nav-header">
                                  {scoreStatement
                                      ? scoreStatement.map((val, index) => {
                                            return (
                                                <span
                                                    key={index}
                                                    style={
                                                        index === 1
                                                            ? {
                                                                  color: getColor(data.scores.filter((obj) => obj.active === true)[0].value)
                                                              }
                                                            : null
                                                    }
                                                >
                                                    {val}
                                                </span>
                                            );
                                        })
                                      : null}
                              </div>
                              <div className="bar-chart-container">
                                  <TraitStatusBar chartData={data.scores} />
                              </div>
                              <div className="trait-score-description">
                                  <ShowMoreCard data={{ text: data.scoreDescription }} />
                              </div>
                              <RecommendedProductCard traitId={data.traitId} traitName={data.traitName} scoreName={getActiveScore()} userId={userId} />
                              <div className="success-stratagies">
                                  {data.successStrategies.length
                                      ? data.successStrategies.map((val, index) => {
                                            return <ShowMoreCard data={val} key={index} />;
                                        })
                                      : null}
                              </div>
                          </>
                      ) : (
                          <div className="related-container">
                              <div className="trait-nav-header">Related Genes/SNP’s {data.related.genes.join(", ")}</div>
                              <div
                                  className="genes-description"
                                  dangerouslySetInnerHTML={{
                                      __html: md.render(data.related.genesDescription)
                                  }}
                              ></div>
                              <div className="links-container">
                                  <div className="link-title">Reference Articles</div>
                                  <ul>
                                      {data.related.links
                                          ? data.related.links.map((val, index) => {
                                                return (
                                                    <li key={index}>
                                                        <a href={val.url} target="_blank" rel="noopener noreferrer">
                                                            {val.title}
                                                        </a>
                                                    </li>
                                                );
                                            })
                                          : null}
                                  </ul>
                              </div>
                          </div>
                      )}
                  </div>
              </div>
          ) : (
              <div>
                  <div className="content-container p-5">
                      <h4>No Trait Found!</h4>
                  </div>
              </div>
          )}
      </>
  );
};

export default TraitSummary;
