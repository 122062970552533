import React from "react";
import { useHistory } from "react-router-dom";
import { getTraits } from "../helpers/reportMapHelper";
import { getColor } from "../helpers/statusColorHelper";
import ArrowRight from "../assets/i-line-rightarrow.svg";
import GrayImage from "../assets/gray_backdrop_1542x464.png";

const AllTraits = () => {
  const history = useHistory();
  const traits = getTraits();

  return (
    <div className="xl-container">
      <div className="all-traits-container">
        {traits
          ? traits
              .sort((a, b) => {
                if (a.traitName > b.traitName) {
                  return 1;
                }
                if (a.traitName < b.traitName) {
                  return -1;
                }
                return 0;
              })
              .map((val, index) => {
                return (
                  <div
                    className="trait-container"
                    key={index}
                    onClick={() =>
                      history.push(`/all-traits/${val.traitKey}`.toLowerCase())
                    }
                  >
                    <div className="trait-heading">
                      <img
                        src={
                          val.imageUrl || val.imageUrl !== ""
                            ? val.imageUrl
                            : GrayImage
                        }
                        alt="trait"
                      />
                      <div>
                        <div className="name">{val.traitName}</div>
                        <div className="icon">
                          <img src={ArrowRight} alt="arrow right" />
                        </div>
                      </div>
                    </div>
                    <div
                      className="trait-status"
                      style={{ background: getColor(val.score_value) }}
                    >
                      {val.score}
                    </div>
                  </div>
                );
              })
          : null}
      </div>
      {!traits ? (
        <div className="content-container p-5">
          <h4>No Traits Found!</h4>
        </div>
      ) : null}
    </div>
  );
};

export default AllTraits;
