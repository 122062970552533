import React from "react";
import dnaHand from "../../assets/dna_hand.png";

const OurScience = () => {
  return (
    <div className="dna-sciecne-wrapper ">
      <div className="dna-science">
        <div id="science" className="dna-science-header">
          Science
        </div>
        <div className="dna-science-content">
          <div>
            <p>
              Your genes set the foundations for how wellness works in your
              body. As a result, anything that affects your body and your
              wellness, for good or ill, can only operate under the overarching
              guidelines set by your personal set of genes.
            </p>
            <p>
              Many genes have little to no effect on your wellness, but decades
              of genomics research identified specific genes that relate to
              specific wellness advantages and disadvantages, such as metabolic
              tendencies, skin sensitivity, and more.
            </p>
            <p>
              iTOVi DNA takes a sample of your DNA and uses a SNP (Single
              Nucleotide Polymorphism) array to determine exactly which genes
              you have. Once your genetic profile is complete, a report can be
              assembled that outlines which wellness advantages and
              disadvantages are likely to manifest in your body due to your
              genes.
            </p>
            <p>
              Your iTOVi DNA report offers you wellness advice tailored to your
              DNA results, including product recommendations and lifestyle
              habits that match your genotype.
            </p>
          </div>
          <div>
            <img src={dnaHand} alt="our science" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurScience;
