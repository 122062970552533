import React, { useEffect, useState } from "react";
import axios from "axios";
import { scrollToTop } from "../../../helpers/scrollHelper";
import MasterTraitScores from "./MasterTraitScores";
import NotificationBanner from "../../../components/NotificationBanner";
import TraitSummary from "../../../components/trait/TraitSummary";
import LoadingDots from "../../../components/LoadingDots";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  emptyRelatedLink,
  toGeneratedTrait,
} from "../../../helpers/masterTraitSchemas";

const MasterTraitForm = ({
  getMasterTraits,
  activeMasterTrait,
  setActiveMasterTrait,
  masterTraits,
  setMasterTraits,
  masterReports,
  editScore,
  setEditScore,
  traitDeleteActive,
  setTraitDeleteActive,
  userId,
}) => {
  const [saving, setSaving] = useState(false);
  const [trait, setTrait] = useState(activeMasterTrait);
  const [flashMessage, setFlashMessage] = useState({
    duration: "0",
    message: "",
    status: "success",
  });

  const [preview, setPreview] = useState({ active: false, index: 0 });

  useEffect(() => {
    setTrait(activeMasterTrait);
    setPreview({ active: preview.active, index: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMasterTrait]);

  const updateSingleValue = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setTrait({
      ...trait,
      [name]: value,
    });
  };

  const updateCroppedImages = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setTrait({
      ...trait,
      croppedImages: {...trait.croppedImages,  [name]: value },
    });
  }

  const updateReadyToPublish = (isReadyToPublish) => {
    setTrait({
      ...trait,
      readyToPublish: isReadyToPublish,
    });
  };

  const addRelatedLink = () => {
    setTrait({
      ...trait,
      related: {
        ...trait.related,
        links: [...trait.related.links, emptyRelatedLink()],
      },
    });
  };

  const updateReports = (reportId) => {
    if (trait.reports && trait.reports.includes(reportId)) {
      setTrait({
        ...trait,
        reports: trait.reports.filter((val) => val !== reportId),
      });
    } else {
      setTrait({ ...trait, reports: [...trait.reports, reportId] });
    }
  };

  const updateSections = (sectionName) => {
    if (trait.sections && trait.sections.includes(sectionName)) {
      setTrait({
        ...trait,
        sections: trait.sections.filter((val) => val !== sectionName),
      });
    } else {
      setTrait({ ...trait, sections: [...trait.sections, sectionName] });
    }
  };

  const updateRelated = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setTrait({
      ...trait,
      related: {
        ...trait.related,
        [name]: value,
      },
    });
  };
  const updateRelatedLinks = (e, index) => {
    const name = e.target.name;
    const value = e.target.value;

    let links = trait.related.links;
    links[index][name] = value;

    setTrait({ ...trait, related: { ...trait.related, links: links } });
  };

  const updateGenes = (e) => {
    const index = e.target.name;
    const value = e.target.value;

    let genes = trait.related.genes;
    genes[index] = value;

    setTrait({
      ...trait,
      related: {
        ...trait.related,
        genes: genes,
      },
    });
  };

  const deleteRelatedObj = (name, index) => {
    const relatedObj = trait.related[name].filter((val, key) => key !== index);
    setTrait({
      ...trait,
      related: {
        ...trait.related,
        [name]: relatedObj,
      },
    });
  };

  const upsertReports = async (remove) => {
    const result = await axios
      .post(
        process.env.REACT_APP_DNA_API + "/v1/masterreports/trait",
        { traitKey: trait.traitKey, reportIds: remove ? [] : trait.reports },
        {
          headers: {
            authorization: "Token " + process.env.REACT_APP_DNA_API_KEY,
          },
        }
      )
      .then((response) => {
        return response.status === 200 ? true : false;
      })
      .catch((err) => {
        return false;
      });

    return result;
  };

  const submit = async () => {
    setSaving(true);

    const traitResult = await saveTrait();
    if (!traitResult) {
      setSaving(false);
      setFlashMessage({
        duration: "5000",
        message: trait.traitName + " was not saved successfully!",
        status: "danger",
      });
      return;
    }

    const reportResult = await upsertReports(false);

    if (!reportResult) {
      setSaving(false);
      setFlashMessage({
        duration: "5000",
        message: "Included Reports were not saved successfully",
        status: "danger",
      });
      return;
    }

    setSaving(false);
    setFlashMessage({
      duration: "5000",
      message: trait.traitName + " was saved successfully!",
      status: "success",
    });
    return;
  };

  const saveTrait = async () => {
    if (trait) {
      delete trait._id;
      const result = await axios
        .post(
          process.env.REACT_APP_DNA_API + "/v1/mastertraits/update",
          trait,
          {
            headers: {
              authorization: "Token " + process.env.REACT_APP_DNA_API_KEY,
            },
          }
        )
        .then((response) => {
          if (response.data.type === "ok" && response.data.result) {
            let traits = masterTraits;
            const traitIndex = traits.findIndex(
              (x) => x.traitId === response.data.result.traitId
            );

            if (traitIndex >= 0) {
              traits[traitIndex] = response.data.result;
            } else {
              traits.push(response.data.result);
              traits.sort((a, b) => a.traitName.localeCompare(b.traitName));
            }
            setActiveMasterTrait(response.data.result);
            setMasterTraits(traits);
            return true;
          }
        })
        .catch((error) => {
          return false;
        });

      return result;
    } else {
      return false;
    }
  };

  const onDelete = async () => {
    setTraitDeleteActive(2);
    const traitResult = await deleteTrait();

    if (!traitResult.status) {
      setTraitDeleteActive(false);
      scrollToTop();
      setFlashMessage({
        duration: "5000",
        message: traitResult.message,
        status: "danger",
      });
      return;
    }

    const reportResults = await upsertReports(true);

    if (!reportResults) {
      getMasterTraits();
      setTraitDeleteActive(false);
      scrollToTop();
      setFlashMessage({
        duration: "5000",
        message: "Unable to remove trait from included reports.",
        status: "danger",
      });
      return;
    }

    getMasterTraits();
    setTraitDeleteActive(false);
    scrollToTop();
    setFlashMessage({
      duration: "5000",
      message: "Successfully removed Trait",
      status: "success",
    });
  };

  const deleteTrait = async () => {
    if (trait && trait.traitId) {
      const result = await axios
        .delete(process.env.REACT_APP_DNA_API + "/v1/mastertraits/delete", {
          data: { traitId: trait.traitId },
          headers: {
            authorization: "Token " + process.env.REACT_APP_DNA_API_KEY,
          },
        })
        .then((response) => {
          if (response.status === 200 && response.data.message) {
            return { status: true, message: response.data.message };
          }
          return { status: false, message: "Something went wrong." };
        })
        .catch((error) => {
          if (error && error.message) {
            return { status: false, message: error.message };
          }
        });
      return result;
    } else {
      return { status: false, message: "Trait missing" };
    }
  };

  const deleteScore = (index) => {
    const scores = trait.scores.filter((val, key) => key !== index);
    setTrait({
      ...trait,
      scores: scores,
    });
  };

  return (
    <div className="master-trait-form">
      {trait ? (
        <>
          <div className="content-container trait-form-toolbar">
            <div className="form-status">
              {trait.traitId !== null ? "Edit Trait" : "New Trait"}
            </div>
            <div>
              {saving ? (
                <button
                  type="button"
                  className="button button-primary button-sm"
                >
                  <LoadingDots size="medium" color="white" />
                </button>
              ) : (
                <>
                  <button
                    type="button"
                    className="button button-outline-primary button-sm"
                    style={{ marginRight: "8px" }}
                    onClick={() =>
                      setPreview({
                        active: !preview.active,
                        index: 0,
                      })
                    }
                  >
                    {preview.active ? "Edit" : "Preview"}
                  </button>
                  <button
                    type="button"
                    className="button button-primary button-sm"
                    onClick={submit}
                  >
                    Save
                  </button>
                </>
              )}
            </div>
          </div>
          {preview.active ? (
            <>
              <div className="active-score-dropdown">
                <div>Active Score: </div>
                <div>
                  <select
                    value={preview.index}
                    onChange={(e) => {
                      setPreview({
                        active: true,
                        index: parseInt(e.target.value, 10),
                      });
                    }}
                  >
                    {trait.scores.length
                      ? trait.scores.map((val, index) => {
                          return (
                            <option key={index} value={index}>
                              {val.scoreName}
                            </option>
                          );
                        })
                      : null}
                  </select>
                </div>
              </div>
              <TraitSummary
                data={toGeneratedTrait(trait, preview.index)}
                userId={userId}
              />
            </>
          ) : (
            <>
              <NotificationBanner
                status={flashMessage.status}
                duration={flashMessage.duration}
                message={flashMessage.message}
                setFlashMessage={setFlashMessage}
              />
              <div className="content-container trait-form-section">
                <div className="form-section-header">
                  <div>General</div>
                </div>
                <div className="form-input-container">
                  <div className="form-input-label">Trait Name</div>
                  <div className="form-input-field">
                    <input
                      type="text"
                      name="traitName"
                      value={trait.traitName}
                      onChange={updateSingleValue}
                    />
                  </div>
                </div>
                <div className="form-input-container">
                  <div className="form-input-label">Trait Key</div>
                  <div className="form-input-field">
                    <input
                      type="text"
                      name="traitKey"
                      value={trait.traitKey}
                      onChange={updateSingleValue}
                    />
                  </div>
                </div>
                <div className="form-input-container">
                  <div className="form-input-label">Lab Key</div>
                  <div className="form-input-field">
                    <input
                      type="text"
                      name="labKey"
                      value={trait.labKey}
                      onChange={updateSingleValue}
                    />
                  </div>
                </div>
                <div className="form-input-container">
                  <div className="form-input-label">Image Url</div>
                  <div className="form-input-field">
                    <input
                      type="text"
                      name="imageUrl"
                      value={trait.imageUrl}
                      onChange={updateSingleValue}
                    />
                  </div>
                </div>
                <div className="form-input-container">
                  <div className="form-input-label">Circle Image Url</div>
                  <div className="form-input-field">
                    <input
                      type="text"
                      name="circle"
                      value={trait.croppedImages && trait.croppedImages.circle ? trait.croppedImages.circle : ""}
                      onChange={updateCroppedImages}
                    />
                  </div>
                </div>
                <div className="form-input-container">
                  <div className="form-input-label">Rectangle Image Url</div>
                  <div className="form-input-field">
                    <input
                      type="text"
                      name="rectangle"
                      value={trait.croppedImages && trait.croppedImages.rectangle ? trait.croppedImages.rectangle : ""}
                      onChange={updateCroppedImages}
                    />
                  </div>
                </div>
                <div className="form-input-container">
                  <div className="checkbox-container">
                    <div
                      className={
                        trait.readyToPublish === true
                          ? "checkbox active"
                          : "checkbox"
                      }
                    >
                      <input
                        type="checkbox"
                        name="readyToPublish"
                        checked={trait.readyToPublish === true}
                        onChange={() =>
                          updateReadyToPublish(!trait.readyToPublish)
                        }
                      />
                    </div>
                    <div className="checkbox-title">Ready to Publish</div>
                  </div>
                </div>
              </div>
              <div className="flex-half-container">
                <div className="content-container trait-form-section">
                  <div className="form-section-header">
                    <div>Included Reports</div>
                  </div>
                  <div className="form-input-container">
                    {masterReports
                      ? masterReports.map((val, index) => {
                          return (
                            <div className="checkbox-container" key={index}>
                              <div
                                className={
                                  trait.reports.length &&
                                  trait.reports.includes(val.reportId)
                                    ? "checkbox active"
                                    : "checkbox"
                                }
                                onClick={() => updateReports(val.reportId)}
                              >
                                <div></div>
                              </div>
                              <div className="checkbox-title">
                                {val.reportName}
                              </div>
                            </div>
                          );
                        })
                      : null}
                  </div>
                </div>
                <div className="content-container trait-form-section">
                  <div className="form-section-header">
                    <div>Included Sections</div>
                  </div>
                  {masterReports.map((report) => {
                    return (
                      <div
                        className="form-input-container"
                        key={`section-${report.reportKey}`}
                      >
                        <div class="report-section">{report.reportName}</div>
                        {report.sections.map((section) => {
                          const reportSectionKey = `${report.reportKey}.${section.sectionKey}`.toUpperCase();
                          return (
                            <div
                              className="checkbox-container"
                              key={reportSectionKey}
                            >
                              <div
                                className={
                                  trait.sections.length > 0 &&
                                  trait.sections.includes(reportSectionKey)
                                    ? "checkbox active"
                                    : "checkbox"
                                }
                                onClick={() => {
                                  updateSections(reportSectionKey);
                                }}
                              >
                                <div></div>
                              </div>
                              <div className="checkbox-title">
                                {section.sectionKey}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="content-container trait-form-section">
                <div className="form-section-header">
                  <div>Scores</div>
                  <div>
                    {editScore.active === false ? (
                      <div>
                        <button
                          type="button"
                          className="button button-primary button-sm"
                          onClick={() => {
                            setEditScore({
                              active: true,
                              index: null,
                              score: null,
                            });
                          }}
                        >
                          New Score
                        </button>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="form-input-container">
                  {editScore.active ? (
                    <MasterTraitScores
                      editScore={editScore}
                      trait={trait}
                      setTrait={setTrait}
                      setEditScore={setEditScore}
                    />
                  ) : trait.scores ? (
                    trait.scores.map((val, index) => {
                      return (
                        <div className="score-item" key={index}>
                          <div>{val.scoreName}</div>
                          <div>
                            <button
                              type="button"
                              className="button button-primary button-sm"
                              onClick={() =>
                                setEditScore({
                                  active: true,
                                  index: index,
                                  score: val,
                                })
                              }
                            >
                              Edit
                            </button>
                            <button
                              type="button"
                              className="button button-outline-danger button-md"
                              onClick={() => {
                                deleteScore(index);
                              }}
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <h4>No Scores Available</h4>
                  )}
                </div>
              </div>
              <div className="content-container trait-form-section">
                <div className="form-section-header">
                  <div>Genes, SNPs, and Studies</div>
                </div>
                <div className="form-input-container">
                  <div className="flex-half-container">
                    <div>
                      <div className="related-heading">
                        <div className="related-heading-title">
                          Related Details
                        </div>
                      </div>
                      <div className="form-input-container">
                        <div className="form-input-label">
                          Genes Description
                        </div>
                        <div className="form-input-field">
                          <textarea
                            name="genesDescription"
                            value={trait.related.genesDescription}
                            onChange={updateRelated}
                          />
                        </div>
                      </div>

                      <div className="form-input-container">
                        <div className="form-input-label">New Gene</div>
                        <div className="form-inline">
                          <div className="form-input-field">
                            <input type="text" name="newGene" id="newGene" />
                          </div>
                          <div>
                            <button
                              type="button"
                              className="button button-outline-primary button-sm"
                              onClick={() => {
                                const newGene = document.getElementById(
                                  "newGene"
                                ).value;
                                setTrait({
                                  ...trait,
                                  related: {
                                    ...trait.related,
                                    genes: [...trait.related.genes, newGene],
                                  },
                                });
                                document.getElementById("newGene").value = "";
                              }}
                            >
                              Add Gene
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="form-input-container">
                        <div className="form-input-label">Genes</div>

                        {trait.related && trait.related.genes.length
                          ? trait.related.genes.map((val, index) => {
                              return (
                                <div className="form-inline gene" key={index}>
                                  <div className="form-input-field">
                                    <input
                                      type="text"
                                      value={val}
                                      onChange={updateGenes}
                                    />
                                  </div>
                                  <div>
                                    <button
                                      type="button"
                                      className="button button-xs button-outline-danger"
                                      onClick={() =>
                                        deleteRelatedObj("genes", index)
                                      }
                                    >
                                      <FontAwesomeIcon
                                        icon={("fas", "times")}
                                      />
                                    </button>
                                  </div>
                                </div>
                              );
                            })
                          : null}
                      </div>
                    </div>
                    <div>
                      <div className="related-heading">
                        <div className="related-heading-title">
                          Related Links
                        </div>
                        <div>
                          <button
                            type="button"
                            className="button button-outline-primary button-sm"
                            onClick={addRelatedLink}
                          >
                            Add New
                          </button>
                        </div>
                      </div>
                      {trait.related && trait.related.links.length
                        ? trait.related.links.map((val, index) => {
                            return (
                              <div className="related-link" key={index}>
                                <div className="form-input-container">
                                  <div className="form-input-label">Title</div>
                                  <div className="form-input-field">
                                    <textarea
                                      name="title"
                                      value={val.title}
                                      onChange={(e) =>
                                        updateRelatedLinks(e, index)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="form-input-container">
                                  <div className="form-input-label">Url</div>
                                  <div className="form-input-field">
                                    <input
                                      type="text"
                                      name="url"
                                      value={val.url}
                                      onChange={(e) =>
                                        updateRelatedLinks(e, index)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="related-remove">
                                  <button
                                    type="button"
                                    className="button button-danger button-sm"
                                    onClick={() =>
                                      deleteRelatedObj("links", index)
                                    }
                                  >
                                    Remove
                                  </button>
                                </div>
                              </div>
                            );
                          })
                        : null}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <div className="content-container p-4">Master Trait Not Found!</div>
      )}
      {preview.active ? null : (
        <div className="delete-trait">
          <>
            {traitDeleteActive > 0 ? (
              <>
                <div className="delete-message">
                  Are you sure you want to delete ( {trait.traitName} )?
                </div>
                <button
                  type="button"
                  className="button button-danger confirm-delete"
                  onClick={onDelete}
                  disabled={traitDeleteActive === 2}
                >
                  {traitDeleteActive === 2 ? (
                    <LoadingDots size="medium" color="white" />
                  ) : (
                    "Yes"
                  )}
                </button>
                <button
                  type="button"
                  className="button button-primary"
                  onClick={() => setTraitDeleteActive(false)}
                >
                  No
                </button>
              </>
            ) : trait.traitId ? (
              <button
                type="button"
                className="button button-danger"
                onClick={() => setTraitDeleteActive(1)}
              >
                Delete Trait
              </button>
            ) : null}
          </>
        </div>
      )}
    </div>
  );
};

export default MasterTraitForm;
