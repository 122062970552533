import React, { useEffect, useCallback, useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const HeritageDropdown = ({
  selectDropdown,
  setSelectDropdown,
  heritage,
  setHeritage,
  error,
  setError,
}) => {
  const [heritageValues, setHeritageValues] = useState([]);
  const [maxOptionLength, setMaxOptionLength] = useState(45);

  const getHeritageOptions = useCallback(() => {
    axios
      .get(process.env.REACT_APP_ITOVI_API + "/heritages/options")
      .then((response) => {
        setHeritageValues(response.data.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const handleResize = useCallback(() => {
    let innerWidth = window.innerWidth;
    if (innerWidth < 355) {
      setMaxOptionLength(25);
    }
    else if (innerWidth < 371) {
      setMaxOptionLength(30);
    }
    else {
      setMaxOptionLength(45);
    }
  }, [setMaxOptionLength]);

  useEffect(() => {
    handleResize();
    getHeritageOptions();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("heritageMobileListener", handleResize);
  }, [getHeritageOptions, handleResize]);

  return (
    <div
      className={selectDropdown ? "heritage-dropdown active" : (error ? "heritage-dropdown error" : "heritage-dropdown")}
    >
      <div
        className="selected"
        onClick={() => setSelectDropdown(!selectDropdown)}
      >
        <div>
          {heritage.name.length > maxOptionLength
            ? heritage.name.slice(0, maxOptionLength) + "..."
            : heritage.name}
        </div>
        <div>
          <FontAwesomeIcon
            icon={
              selectDropdown ? ("fas", "chevron-up") : ("fas", "chevron-down")
            }
          />
        </div>
      </div>
      <div className="dropdown" onClick={() => setSelectDropdown(false)}>
        {heritageValues.length
          ? heritageValues.map((heritage, index) => {
              return (
                <div
                  key={index}
                  className="option"
                  onClick={() => {
                    setHeritage(heritage);
                    setError(false);
                  }}
                >
                  {heritage.name}
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
};

export default HeritageDropdown;
