import React, { useState, useEffect } from "react";
import axios from "axios";
import { getToken } from "../helpers/authHelper";

const RecommendedProductCard = ({ traitId, traitName, scoreName, userId }) => {
  const [product, setProduct] = useState();

  useEffect(() => {
    const getProduct = () => {
      axios
        .get(
          process.env.REACT_APP_ITOVI_API +
            "/users/" +
            userId +
            "/products/dna/traits/" +
            traitId +
            "/scores/" +
            scoreName,
          {
            headers: {
              Authorization: "Bearer " + getToken(),
            },
          }
        )
        .then((response) => {
          setProduct(response.data.data[0]);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    getProduct();
  }, [traitId, scoreName, userId]);

  return (
    <>
      {product ? (
        <div className="recommended-product-container">
          <div className="recommended-product-header">Recommended Products</div>
          <div className="recommended-product-description">
            This product recommendation was based on your {traitName} results in
            your genetic profile.
          </div>
          <div className="recommended-product-card">
            {product.images && product.images.length ? (
              <div className="product-image">
                <img src={product.images[0].url} alt="product" />
              </div>
            ) : null}
            <div className="product-content">
              <div>
                <div className="product-title">{product.name}</div>
                <div className="product-description">{product.description}</div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default RecommendedProductCard;
