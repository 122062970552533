import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { hasReport } from "../../helpers/reportMapHelper";
import {getAllReports, getAllTraits} from "../../helpers/axiosReportHelper";
import BlankImage from "../../assets/blank_1200x303.png";
import {
  isGenerating,
  removeGeneratingStatus,
} from "../../helpers/generatingReportHelper";
import LoadingDots from "../LoadingDots";

const ReportCard = ({ report, setRedeemModal, setHeritageModal, userInfo }) => {
  const history = useHistory();
  const [user, setUser] = useState({
    userId: userInfo.userId,
    clientId: userInfo.clientId,
    firstName: userInfo.first_name,
    heritage: userInfo.heritage,
    kitId: userInfo.kitId,
  });
  const [activelyGenerating, setActivelyGenerating] = useState(
    isGenerating(report.reportKey)
  );
  const hasAccess = hasReport(report.reportKey);

  const redirectPage = () => {
    const reportUrl = () => {
      switch (report.reportKey) {
        case "balance":
          return "https://shop.itovi.com/collections/accessories/products/itovi-dna-collection-kit";
        case "nutrition":
          return "https://shop.itovi.com/products/nutrition-report";
        default:
          return "https://shop.itovi.com/collections/accessories/products/itovi-dna-collection-kit";
      }
    };

    if (hasAccess) {
      history.push(`/report/${report.reportKey}`);
    } else {
      window.open(reportUrl(), "_blank");
    }
  };

  const removeActivelyGenerating = useCallback(() => {
    if (hasReport(report.reportKey)) {
      removeGeneratingStatus(report.reportKey);
      setActivelyGenerating(false);
    }
  }, [setActivelyGenerating, report.reportKey]);

  const refreshGenerating = useCallback(async () => {
    await getAllReports(userInfo.userId);
    await getAllTraits(userInfo.userId);
    removeActivelyGenerating();
  }, [userInfo.userId, removeActivelyGenerating]);

  const flipReportImage = (reportKey) => {
    const reportIds = ['fitness', 'beauty'];
    return reportIds.includes(reportKey)
  }

  useEffect(() => {
    let refreshInterval;
    removeActivelyGenerating();

    if (activelyGenerating) {
      refreshInterval = setInterval(refreshGenerating, 10000);
    }

    return () => {
      clearInterval(refreshInterval);
    };
  }, [
    removeActivelyGenerating,
    setActivelyGenerating,
    activelyGenerating,
    refreshGenerating,
  ]);

  return (
    <>
      {report ? (
        <div className="report-card">
          <div className="header">
            <img src={report.imageUrl ? report.imageUrl : BlankImage} className={flipReportImage(report.reportKey) ? 'flip-image' : null } alt="" />
            <div>
              <div>{report.reportName}</div>
            </div>
          </div>
          <div className="body">
            <div>{report.reportDescription}</div>
            <div>
              {activelyGenerating ? (
                <div className="generating-container">
                  <div>Generating Report </div>
                  <LoadingDots size="xs" color="dove_gray" />
                </div>
              ) : (
                <>
                  <button
                    type="button"
                    onClick={() => redirectPage()}
                    className="button button-primary"
                  >
                    {hasAccess ? "View Report" : "Learn More"}
                  </button>
                  {!hasAccess && report.reportKey !== "balance" ? (
                    <button
                      className="button button-outline-primary"
                      onClick={() => {
                        user && user.heritage && user.heritage.id !== 1
                          ? setRedeemModal({
                              active: true,
                              reportName: report.reportName,
                              reportKey: report.reportKey,
                              userId: user.userId,
                              setActivelyGenerating: setActivelyGenerating,
                            })
                          : setHeritageModal({
                              active: true,
                              reportName: report.reportName,
                              reportKey: report.reportKey,
                              setRedeemModal: setRedeemModal,
                              setUser: setUser,
                              user: user,
                            });
                      }}
                    >
                      Redeem
                    </button>
                  ) : null}
                </>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ReportCard;
