import React from "react";
import { getReport } from "../helpers/reportMapHelper";
import NutritionReport from "../components/report/NutritionReport";
import BalanceReport from "../components/report/BalanceReport";
import FitnessReport from "../components/report/FitnessReport";
import BeautyReport from "../components/report/BeautyReport";

const Report = (props) => {
  const report = getReport(props.match.params.slug);
  const reportKey = props.match.params.slug;

  if (report) {
    if (reportKey === "balance") {
      return <BalanceReport report={report} {...props} />
    }
    else if (reportKey === "nutrition") {
      return <NutritionReport report={report} {...props} />
    }
    else if (reportKey === "fitness") {
      return <FitnessReport report={report} {...props} />
    }
    else if (reportKey === "beauty") {
      return <BeautyReport report={report} {...props} />
    }
  }
  
  return (
    <div className="content-container lg-container p-5">
      <h4>No report found!</h4>
    </div>
  );
};

export default Report;
