import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button, Row, Col, Alert, Spinner, Modal } from "react-bootstrap";
import "../../scss/components/kitRegistration/reviewKitActivation.scss";
import axios from "axios";
import { getToken, logout } from "../../helpers/authHelper";
import { format } from "date-fns";

const ReviewKitActivation = ({
  navigation,
  formData,
  setForm,
  setLoggedIn,
  userInfo,
  setUserInfo,
}) => {
  const {
    userId,
    clientId,
    kitId,
    first_name,
    last_name,
    birth_date,
    sex,
    pregnant,
    weight,
    height,
    phoneNumber,
    heritage,
  } = formData;
  const { go } = navigation;
  const [redirectToEdit, setRedirect] = useState(false);
  const handleEdit = () => setRedirect(true);
  const [error, setError] = useState(null);
  const [sessionModal, setSessionModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const birth_date_short = format(
    new Date(birth_date.replace(/-/g, "/")),
    "yyyy-MM-dd"
  );

  useEffect(() => {
    if (redirectToEdit) {
      go("KitPreScan");
    }
  });

  return (
    <div className="dna-registration-form kit-activation">
      <Modal show={sessionModal}>
        <Modal.Header>
          <Modal.Title>Hey, {first_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Please log back into your account to continue registering your DNA
          Kit.
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="text-white"
            variant="success"
            onClick={() => logout(setLoggedIn)}
            size="lg"
            block
          >
            Login
          </Button>
        </Modal.Footer>
      </Modal>
      <h2>Please review before submitting</h2>
      <div className="form-data">
        {error ? (
          <Alert variant="danger" onClose={() => setError(null)} dismissible>
            <Alert.Heading>Error!</Alert.Heading>
            <p className="text-danger">{error}</p>
          </Alert>
        ) : null}
        <ul>
          <li>
            <label>First Name:</label> {first_name}
          </li>
          <li>
            <label>Last Name:</label> {last_name}
          </li>
          <li>
            <label>Birthdate:</label> {birth_date_short}
          </li>
          <li>
            <label>Sex:</label> {sex === "0" ? "Female" : "Male"}
          </li>
          {sex === "0" ? (
            <li>
              <label>Pregnant:</label> {pregnant}
            </li>
          ) : null}
          <li>
            <label>Race:</label>{" "}
            {heritage && heritage.id !== null ? heritage.name : "Not Selected"}
          </li>
          <li>
            <label>Weight (LB):</label> {weight}
          </li>
          <li>
            <label>Height (IN):</label> {height}
          </li>
          <li>
            <label>Phone Number:</label> {phoneNumber}
          </li>
          <li>
            <label>Kit Id:</label> {kitId}
          </li>
        </ul>
      </div>
      <Row>
        <Col sm={6} className="mb-3">
          <Button variant="outline-success" onClick={handleEdit} block>
            Edit
          </Button>
        </Col>
        <Col sm={6}>
          {loading === false ? (
            <Button variant="success" onClick={register} type="submit" block>
              Submit
            </Button>
          ) : (
            <Button variant="success" block disabled>
              <Spinner
                as="span"
                animation="grow"
                size="md"
                role="status"
                aria-hidden="true"
                className="mr-2"
              />
              Loading...
            </Button>
          )}
        </Col>
      </Row>
    </div>
  );

  function register() {
    setLoading(true);

    let instance = axios.create({
      baseURL: process.env.REACT_APP_ITOVI_API,
      timeout: 1000,
      headers: { Authorization: "Bearer " + getToken() },
    });

    let clientData = {
      birth_date: format(
        new Date(birth_date.replace(/-/g, "/")),
        "yyyy-MM-dd hh:mm:ss"
      ),
      weight: parseFloat(0.45359237 * weight),
      height: parseFloat(2.54 * height),
      phone: phoneNumber,
      gender: sex,
      pregnant: pregnant === "Yes" ? 1 : 0,
      heritage: heritage,
    };

    instance
      .patch("/users/" + userId + "/clients/" + clientId, clientData)
      .then((response) => {
        instance
          .patch("/users/" + userId, {
            phone: phoneNumber ? phoneNumber : "",
          })
          .then(() => {
            axios
                .post(process.env.REACT_APP_DNA_API + "/v1/kits/register", {
                    dateOfBirth: birth_date_short,
                    gender: sex === "0" ? "female" : "male",
                    heightCm: parseFloat(2.54 * height),
                    weightKg: parseFloat(0.45359237 * weight),
                    kitId: kitId,
                    userId: userId,
                    pregnant: pregnant === "Yes" ? 1 : 0,
                    heritage: {id: clientData.heritage.id, name: clientData.heritage.name}
                })
                .then((response) => {
                    setLoading(false);
                    setForm({
                        target: {
                            name: "kitId",
                            value: ""
                        }
                    });
                    setUserInfo({
                        ...userInfo,
                        kitRegistered: "registered"
                    });
                    history.push("/swab-instructions");
                })
                .catch((error) => {
                    setLoading(false);
                    setError("Unable to register your DNA Kit. Please try again or contact us.");
                });
          })
          .catch(() => {
            setLoading(false);
            if (error.response.data.type === "authentication_error") {
              setSessionModal(true);
            } else {
              setError("Something went wrong. Please try again or contact us.");
            }
          });
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.data.type === "authentication_error") {
          setSessionModal(true);
        } else {
          setError("Something went wrong. Please try again or contact us.");
        }
      });
  }
};

export default ReviewKitActivation;
