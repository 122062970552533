import React from "react";

const MyAccount = (props) => {
  let user = props;

  return (
    <div>
      <div>{user.firstName}</div>
    </div>
  );
};

export default MyAccount;
