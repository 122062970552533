import kitReportsBundle from "../assets/dna_kit_n_reports.png";
import nutritionReport from "../assets/dna_nutrition-report.png";
import beautyReport from "../assets/dna_beauty-report.png";
import fitnessReport from "../assets/dna_fitness-report.png";
import kitBundle from "../assets/itovi_dna_bundle.png";
import dnaKit from "../assets/dna_itovi_450x450.png";

export const getProductBundles = () => {
  return [
    {
      name: "iTOVi DNA Kit",
      imageUrl: dnaKit,
      price: "179",
      description: [],
      link: "https://store.itovi.com/products/view/63dc508e36a40d82328b0581",
      buttonText: "Buy Now",
    },
    {
      name: "iTOVi DNA Beauty Report",
      imageUrl: beautyReport,
      price: "29.99",
      description: [],
      link: "https://store.itovi.com/products/view/63dd4496a2e81125153af9fa",
      buttonText: "Buy Now",
    },
    {
      name: "iTOVi DNA Fitness Report",
      imageUrl: fitnessReport,
      price: "39.99",
      description: [],
      link: "https://store.itovi.com/products/view/63dd4cc3a2e81125153afa0c",
      buttonText: "Buy Now",
    },
    {
      name: "iTOVi DNA Nutrition Report",
      imageUrl: nutritionReport,
      price: "29.99",
      description: [],
      link: "https://store.itovi.com/products/view/63dd4cf1a2e81125153afa12",
      buttonText: "Buy Now",
    },
    {
      name: "iTOVi DNA Kit + Reports Bundle",
      imageUrl: kitReportsBundle,
      price: "275",
      description: [],
      link:
        "https://store.itovi.com/products/view/63dd4e7fa2e81125153afa28",
      buttonText: "Buy Now",
    },
  ];
};
