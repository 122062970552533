import React, { useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import "../../scss/components/kitRegistration/termsOfService.scss";

const TermsAndConditions = ({ navigation, formData, setForm }) => {
  const { next, previous } = navigation;
  const { termsOfService } = formData;
  const [errors, setErrors] = useState({});

  return (
    <div className="dna-registration-form kit-terms-of-service">
      <h2>Terms of Services</h2>
      <ul>
        <li>You own your data.</li>
        <li>
          We will not sell, lease, or rent your personally identifiable
          information.
        </li>
        <li>We responsibly dispose of your DNA sample after 45 days.</li>
        <li>
          You may request the deletion of your data and account at any time.
        </li>
      </ul>
      <div className="agreeance">
        <p>
          BY AGREEING TO OUR TERMS OF SERVICE, YOU AUTHORIZE ITOVI TO PERFORM
          GENETIC TESTING ON A BIOLOGICAL SAMPLE THAT YOU HAVE SUBMITTED TO
          ITOVI. THIS AUTHORIZATION EXTENDS TO USING AND SHARING YOUR ANONYMIZED
          DATA FOR HEALTH REPORTING PRODUCT DEVELOPMENT AND RESEARCH.
        </p>
      </div>
      <div className="d-flex">
        <Form.Check id="form-check" className="checkbox">
          <Form.Check.Input
            className="checkbox"
            defaultChecked={termsOfService ? true : false}
            value="1"
            name="termsOfService"
            onChange={setForm}
            required
          />
          <Form.Check.Label />
        </Form.Check>
        <p className="pt-3 pl-3 pr-3">
          I have read and agree to the&nbsp;
          <a href="/terms-and-conditions" target="_blank">
            Terms of Service
          </a>
          &nbsp;and&nbsp;
          <a href="/terms-and-conditions#privacy" target="_blank">
            Privacy Statement
          </a>
          .
        </p>
      </div>
      <div>
        {errors && errors.termsOfService ? (
          <div className="invalid-feedback d-block">
            <span>You must accept the Terms of Service</span>
          </div>
        ) : null}
      </div>
      <Row className="mt-3">
        <Col sm={6} className="mb-3">
          <Button variant="outline-success" onClick={previous} block>
            Previous
          </Button>
        </Col>
        <Col sm={6}>
          <Button variant="success" onClick={validation} type="submit" block>
            Next
          </Button>
        </Col>
      </Row>
    </div>
  );

  function validation() {
    let errors = {};

    if (!termsOfService) {
      errors.termsOfService = true;
    }

    if (Object.keys(errors).length) {
      setErrors(errors);
      return;
    }

    next();
  }
};

export default TermsAndConditions;
