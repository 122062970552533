import BalanceImg from "../assets/SMALL-Balance-REPORT.jpg";

function getReportsFromLocal() {
  const reports = localStorage.getItem("allReports");
  if (reports === "undefined") return null;
  return reports ? JSON.parse(reports) : null;
}

function getTraitsFromLocal() {
  const traits = localStorage.getItem("allTraits");
  if (traits === "undefined") return null;
  return traits ? JSON.parse(traits) : null;
}

function getReport(reportKey) {
  const reports = getReportsFromLocal();
  if (!reports) return null;
  const report = reports.filter(
    (obj) => obj.reportKey.toLowerCase() === reportKey.toLowerCase()
  )[0];

  if (!report) return null;

  let traits = getTraitsFromLocal();
  if (traits) {
    traits = traits.filter((obj) => report.traits.includes(obj.traitKey));
  } else {
    return null;
  }

  const sections = report.sections.map((section) => {
    if (section.sectionKey) {
      return {
        version: 2,
        reportKey: report.reportKey,
        sectionKey: section.sectionKey,
        sectionTitle: section.title,
        description: section.description ? section.description : null,
        traits: traits.filter((obj) =>
          obj.sections.includes(
            `${report.reportKey}.${section.sectionKey}`.toUpperCase()
          )
        ),
        imageUrl: section.imageUrl,
      };
    } else {
      let imageUrl = null;
      if (report.reportKey.toLowerCase() === "balance") {
        if (section === "FOOD") {
          imageUrl = report.foodSummary.imageUrl
            ? report.foodSummary.imageUrl
            : null;
        }
        if (section === "NUTRIENT") {
          imageUrl = report.nutrientSummary.imageUrl
            ? report.nutrientSummary.imageUrl
            : null;
        }
      }
      return {
        version: 1,
        sectionKey: section,
        traits: traits.filter((obj) => obj.sections.includes(section)),
        imageUrl: imageUrl,
      };
    }
  });

  return {
    ...report,
    sections: sections,
  };
}

function getReports(status) {
  const reports = getReportsFromLocal();

  if (reports && reports.length && status === "completed") {
    return reports.map((val, index) => {
      return {
        reportName: val.reportName,
        reportKey: val.reportKey,
        imageUrl: val.imageUrl,
        link: {
          internal: true,
          url: `/report/${val.reportName.toLowerCase()}`,
        },
        color: "#fff",
        show: true,
        reportDescription: val.reportDescription,
      };
    });
  } else {
    return [
      {
        reportName: "Balance",
        reportKey: "balance",
        imageUrl: BalanceImg,
        link: {
          internal: false,
          url: "https://itovi.com/dna",
        },
        color: "#0D5183",
        show: true,
        reportDescription: "Learn More",
      },
    ];
  }
}

function getTrait(traitKey) {
  const traits = getTraitsFromLocal();

  if (traits && traitKey) {
    return traits.filter(
      (obj) => obj.traitKey.toLowerCase() === traitKey.toLowerCase()
    )[0];
  }

  return null;
}

function getTraits() {
  const allTraits = getTraitsFromLocal();

  if (!allTraits) return null;

  return allTraits.map((val, index) => {
    return {
      traitId: val.traitId,
      traitName: val.traitName,
      traitKey: val.traitKey,
      imageUrl: val.imageUrl,
      croppedImages: val.croppedImages ? val.croppedImages : null,
      score: val.scores.filter((obj) => {
        return obj.active === true;
      })[0].scoreName,
      score_value: val.scores.filter((obj) => {
        return obj.active === true;
      })[0].value,
    };
  });
}

function hasReport(reportKey) {
  const reports = getReportsFromLocal();
  const report =
    reports && reports.length
      ? reports.find(
          (val) => val.reportKey.toLowerCase() === reportKey.toLowerCase()
        )
      : null;
  return report ? true : false;
}

export { getTrait, getTraits, getReport, getReports, hasReport };
