import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CollapsableCard = ({ data }) => {
  const [collapsed, setCollapsed] = useState(true);
  const [foodDropDowns, setFoodDropDowns] = useState([]);

  const percentage = data.macrosSummary["percentage"];
  const grams = data.macrosSummary["grams"];
  const calories = data.macrosSummary["calories"];

  return (
    <>
      {data ? (
        <div className="collapsable-card">
          <div
            className={
              collapsed
                ? "collapsable-card-heading active"
                : "collapsable-card-heading"
            }
            onClick={() => setCollapsed(!collapsed)}
          >
            {data.imageUrl && data.imageUrl !== "" ? (
              <img src={data.imageUrl} alt="card background" />
            ) : (
              <div className="image-filler"></div>
            )}

            <div className="header-content">
              <FontAwesomeIcon
                icon={collapsed ? ["fas", "angle-up"] : ["fas", "angle-down"]}
              />
              <span>{data.title}</span>
            </div>
          </div>
          <div
            className={
              collapsed
                ? "collapsable-card-content active"
                : "collapsable-card-content"
            }
          >
            <div className="desc">{data.description}</div>
            <div className="table-chart">
              <div>
                <div className="table-heading">Percent</div>
                <div className="table-content">
                  <span>{percentage.low}%</span>
                  <span>to</span>
                  <span>{percentage.high}%</span>
                </div>
              </div>
              <div>
                <div className="table-heading">Grams</div>
                <div className="table-content">
                  <span>{grams.low}g</span>
                  <span>to</span>
                  <span>{grams.high}g</span>
                </div>
              </div>
              <div>
                <div className="table-heading">Calories</div>
                <div className="table-content">
                  <span>{calories.low}</span>
                  <span>to</span>
                  <span>{calories.high}</span>
                </div>
              </div>
            </div>
            {data.foodRecommendations
              ? data.foodRecommendations.map((val, index) => {
                  return (
                    <div className="food-recommendations" key={index}>
                      <div
                        className="food-recommendations-header"
                        onClick={() => {
                          if (foodDropDowns.includes(index)) {
                            setFoodDropDowns(
                              foodDropDowns.filter((obj) => obj !== index)
                            );
                          } else {
                            setFoodDropDowns([...foodDropDowns, index]);
                          }
                        }}
                      >
                        <div>{val.title}</div>
                        <div>{val.description}</div>
                        <div>
                          <FontAwesomeIcon
                            icon={
                              foodDropDowns.includes(index)
                                ? ["fas", "angle-up"]
                                : ["fas", "angle-down"]
                            }
                          />
                        </div>
                      </div>
                      <div
                        className={
                          foodDropDowns.includes(index)
                            ? "food-recommendations-table active"
                            : "food-recommendations-table"
                        }
                      >
                        {val.preferredFoods
                          ? val.preferredFoods.map((val, index) => {
                              return (
                                <div key={index}>
                                  <div>
                                    <span>{val.food}</span>
                                    <span>{val.quantity}</span>
                                  </div>
                                  <div>{val.servingSize}</div>
                                </div>
                              );
                            })
                          : null}
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default CollapsableCard;
