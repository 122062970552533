import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import ReactGA from "react-ga";
import "./scss/App.scss";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

const history = createBrowserHistory();

ReactGA.initialize("UA-73289702-4", {
  debug: process.env.REACT_APP_FEATURE_FLAG === "production" ? false : true,
  testMode: process.env.REACT_APP_FEATURE_FLAG === "production" ? false : true,
  gaOptions: {
    siteSpeedSampleRate: 100,
  },
});

// Initialize google analytics page view tracking
history.listen((location) => {
  if (!location.pathname.match(/admin/g)) {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
  }
});

ReactDOM.render(
  <Router history={history}>
    <App />
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
