import React from "react";
import ItoviBundle from "../../assets/itovi_bundle@2x.png";

const DnaGsr = () => {
  return (
    <div className="dna-gsr-wrapper">
      <div className="dna-gsr">
        <div className="dna-section-heading">
          Why combine iTOVi DNA and the iTOVi GSR Scanner?
        </div>
        <div className="inline-container">
          <div className="dna-gsr-content">
            <div>
              <div className="title">iTOVi DNA</div>
              <div className="description">
                Your DNA is carefully replicated so it is always the same. By
                relying on your DNA, the iTOVi DNA report can give you a great
                basis for a wellness plan that will benefit you in the
                long-term.
              </div>
            </div>
            <div>
              <div className="title">The iTOVi Scanner</div>
              <div className="description">
                The GSR Scanner is affected by more variable things: your
                temperature, emotional state, hormones, and other important, but
                often temporary factors. So, your iTOVi scan report is more
                likely to help you find the best products for your day-to-day
                wellness concerns.
              </div>
            </div>
            <div>
              <div className="title">iTOVi DNA + the iTOVi Scanner</div>
              <div className="description">
                Using these two products combined will change the way you
                approach wellness! You’ll be able to create a wellness plan
                based on your genotype, the underlying foundation of all your
                body’s functions. And you’ll be able to make the small pivots
                and adjustments that are necessary for supporting your body
                through the day-to-day, short-term concerns.<br /><br />
                When you combine the long-term perspective and the short-term perspective with
                the intuition, experience, and resources you already have—you
                will be capable of creating a wellness plan more individualized
                and powerful than anything you’ve ever had before!
              </div>
            </div>
          </div>
          <div className="dna-gsr-image">
            <img src={ItoviBundle} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DnaGsr;
