import Cookies from "universal-cookie";
import axios from "axios";

const Cookie = new Cookies();

function extractId(token) {
  try {
    let payloadString = token.split(".")[1];
    let base64 = payloadString.replace(/-/g, "+").replace(/_/g, "/");
    let map = JSON.parse(window.atob(base64));
    let id = map["sub"];
    return id;
  } catch (e) {
    return 0;
  }
}

function getToken() {
  const token = Cookie.get("token");
  return token ? token : false;
}

async function getUserInfo() {
  const token = getToken();
  if (!token) return;
  const id = extractId(token);
  const userInfo = await axios
    .get(process.env.REACT_APP_ITOVI_API + "/users/" + id, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then(async (response) => {
      //get kit registration info for user
      return await loadUserInfo(response);
    })
    .catch((error) => {
      clearLocalStorage();
      return false;
    });

  return userInfo;

  async function loadUserInfo(response) {
    let client = { ...response.data.data.client };
    let userData = {
      userId: response.data.data.id,
      address: {
        billing: {
          city: response.data.data.address.billing.city,
          state: response.data.data.address.billing.state,
          country: response.data.data.address.billing.country,
          postal_code: response.data.data.address.billing.postal_code,
          primary: response.data.data.address.billing.primary,
          secondary: response.data.data.address.billing.secondary,
        },
      },
      kitRegistered: false,
      clientId: client.id,
      first_name: client.first_name,
      last_name: client.last_name,
      birth_date: !client.birth_date
        ? null
        : client.birth_date.date.split(" ")[0],
      pregnant: client.pregnant ? "Yes" : "No",
      sex: client.gender.toString(),
      weight: client.weight ? (client.weight / 0.45359237).toFixed(0) : "",
      height: client.height ? (client.height / 2.54).toFixed(0) : "",
      phoneNumber: response.data.data.phone,
      avatar: response.data.data.avatar,
      heritage: null,
      admin: response.data.data.email.match(/^[\w.+-]+@itovi\.com$/)
        ? true
        : false,
    };
    await axios
      .get(process.env.REACT_APP_DNA_API + "/v2/kits/byuser/" + id, {
        headers: {
          authorization: "Token " + process.env.REACT_APP_DNA_API_KEY,
        },
      })
      .then((response) => {
        if (process.env.REACT_APP_FEATURE_FLAG === "development") {
          userData.kitRegistered = process.env.REACT_APP_DEBUG_KIT_STATUS || false;
        } else {
          userData.kitRegistered = response.data.status;
        }
        userData.heritage = response.data.heritage
          ? response.data.heritage
          : null;
        userData.kitId = response.data.kitId ? response.data.kitId : null;
      })
      .catch((error) => {
        if (process.env.REACT_APP_FEATURE_FLAG === "development") {
          userData.kitRegistered = process.env.REACT_APP_DEBUG_KIT_STATUS || false;
        } else {
          userData.kitRegistered = false;
        }
        clearLocalStorage();
      });
    return userData;
  }
}

function clearLocalStorage() {
  localStorage.removeItem("allReports");
  localStorage.removeItem("allTraits");
}

function isLoggedIn() {
  const loggedIn = getUserInfo();
  return loggedIn ? true : false;
}

function logout(setLoggedIn) {
  if (Cookie.get("token")) {
    Cookie.remove('token', { path: '/', domain: '.itovi.com' })
    Cookie.remove("token");
    clearLocalStorage();
    setLoggedIn(false);
  }
}

export { isLoggedIn, logout, getUserInfo, getToken, extractId };
