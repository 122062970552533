const isGenerating = (reportKey) => {
    let reportGeneratingStatuses = localStorage.getItem("reportGeneratingStatuses");
    if (!reportGeneratingStatuses || reportGeneratingStatuses === "undefined") return false;
    reportGeneratingStatuses = JSON.parse(reportGeneratingStatuses);
    return reportGeneratingStatuses.includes(reportKey);
}

const upsertGeneratingStatus = (reportKey) => {
    let reportGeneratingStatuses = localStorage.getItem("reportGeneratingStatuses");
    if (!reportGeneratingStatuses || reportGeneratingStatuses === "undefined") reportGeneratingStatuses = [];
    else reportGeneratingStatuses = JSON.parse(reportGeneratingStatuses);
    if (reportGeneratingStatuses.includes(reportKey)) return;
    reportGeneratingStatuses.push(reportKey);
    localStorage.setItem("reportGeneratingStatuses", JSON.stringify(reportGeneratingStatuses));
}

const removeGeneratingStatus = (reportKey) => {
    let reportGeneratingStatuses = localStorage.getItem("reportGeneratingStatuses");
    if (!reportGeneratingStatuses || reportGeneratingStatuses === "undefined") return;
    reportGeneratingStatuses = JSON.parse(reportGeneratingStatuses);
    reportGeneratingStatuses = reportGeneratingStatuses.filter(key => key !== reportKey);
    if (reportGeneratingStatuses.length === 0) {
        localStorage.removeItem("reportGeneratingStatuses");
        return;
    }
    localStorage.setItem("reportGeneratingStatuses", JSON.stringify(reportGeneratingStatuses));
}

export { isGenerating, upsertGeneratingStatus, removeGeneratingStatus };
