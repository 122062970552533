import React, { useState } from "react";
import { useForm } from "react-hooks-helper";
import SelectDropdown from "./forms/SelectDropdown";
import LoadingDots from "./LoadingDots";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import axios from "axios";
import { getToken } from "../helpers/authHelper";

const UpdateUserModal = ({ active, setActive, user, kitId, getLoginStatus }) => {
  const [errors, setErrors] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [formData, setForm] = useForm(user);

  const closeModal = () => {
    if (updating) return;
    setActive(false);
  };

  const validate = () => {
    const { birth_date, pregnant, gender, weight, height } = formData;
    let validateErrors = {};
    let pregnantAnswers = ["Yes", "No"];
    let genderOptions = ["0", "1"];

    if (!birth_date) validateErrors.birth_date = "This field is required!";
    if (isNaN(Date.parse(birth_date))) {
      validateErrors.birth_date = "Not a valid date. EX: MM/DD/YYYY";
    }

    if (!weight || parseInt(weight) <= 0)
      validateErrors.weight = "This field is required!";
    if (!height || parseInt(height) <= 0)
      validateErrors.height = "This field is required!";

    if (!pregnant) validateErrors.pregnant = "This field is required!";
    if (!gender || !genderOptions.includes(gender))
      validateErrors.gender = "This field is required!";
    if (gender === "0") {
      if (!pregnantAnswers.includes(pregnant)) {
        validateErrors.pregnant = true;
      }
    }

    setErrors(validateErrors);

    return !Object.keys(validateErrors).length;
  };

  const updateKit = async () => {
    return await axios
      .post(process.env.REACT_APP_DNA_API + "/v2/kits", {
        kit: {
          dateOfBirth: formData.birth_date.substring(0, 10),
          gender: formData.gender === "0" ? "female" : "male",
          heightCm: parseFloat(2.54 * formData.height),
          weightKg: parseFloat(0.45359237 * formData.weight),
          kitId: kitId,
          pregnant: formData.pregnant === "Yes" ? 1 : 0
        }
      }, { headers: { Authorization: "Token " + process.env.REACT_APP_DNA_API_KEY } })
      .then((response) => {
        return response.status === 200 ? true : false;
      })
      .catch((error) => {
        return false;
      });
  };

  const updateClient = async () => {
    let instance = axios.create({
      baseURL: process.env.REACT_APP_ITOVI_API,
      timeout: 2000,
      headers: { Authorization: "Bearer " + getToken() },
    });

    let clientData = {
      birth_date: formData.birth_date,
      weight: parseFloat(0.45359237 * formData.weight),
      height: parseFloat(2.54 * formData.height),
      gender: formData.gender,
      pregnant: formData.pregnant === "Yes" ? 1 : 0
    };

    return await instance
      .patch("/users/" + user.user_id + "/clients/" + user.client_id, clientData)
      .then((response) => {
        return response.status === 200 ? true : false;
      })
      .catch((error) => {
        return false
      });
  };

  const submit = async () => {
    if (formData.gender === "1") {
      formData.pregnant = "No";
    }

    const validateResult = validate();
    if (!validateResult) return setUpdating(false);

    if (typeof formData.birth_date === "string" && formData.birth_date.length < 11) {
      formData.birth_date = format(new Date(formData.birth_date + " 00:00:00"), "yyyy-MM-dd hh:mm:ss");
    } 
    else {
      formData.birth_date = format(new Date(formData.birth_date), "yyyy-MM-dd hh:mm:ss");
    }

    setUpdating(true);

    const kitResult = await updateKit();
    const userResult = await updateClient();
    
    if (!kitResult || !userResult) {
      setUpdating(false);
      setErrors({ server: "Was not able to update profile!" });
      return;
    }
    
    setUpdating(false);
    await getLoginStatus();
  };

  let year, month, day;
  if (formData.birth_date) {
    if (typeof formData.birth_date === "string" && formData.birth_date.length > 1) {
      year = formData.birth_date.substring(0, 4);
      month = Number(formData.birth_date.substring(5, 7)) - 1;
      day = formData.birth_date.substring(8, 10);
    } else if (typeof formData.birth_date !== "string") {
      year = formData.birth_date.getFullYear();
      month = formData.birth_date.getMonth();
      day = formData.birth_date.getDate();
    }
  }

  return (
    <>
      {active ? (
        <div className="modal-wrapper">
          <div className="modal-card update-user-modal">
            <div className="modal-card-header">
              <div className="header-title">Edit Info</div>
              <div className="dismiss" onClick={() => closeModal()}></div>
            </div>
            <div>
              <div className="form-input-group">
                <div className={errors && errors.birth_date ? 'input-container error' : 'input-container'}>
                  <label>Birthday</label>
                  <DatePicker
                    selected={year ? new Date(year, month, day) : ""}
                    onChange={(e) => {
                      setForm({ target: { name: "birth_date", value: e } });
                    }}
                    maxDate={new Date()}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                  {errors && errors.birth_date ? (
                    <div className="invalid-error">
                      <span>Please enter a valid birthday</span>
                    </div>
                  ) : null}
                </div>
                <div className="input-container">
                  <label>Gender</label>
                  <SelectDropdown
                    name="gender"
                    setForm={setForm}
                    options={[
                      { name: "Female", value: "0" },
                      { name: "Male", value: "1" },
                    ]}
                    error={errors && errors.gender ? errors.gender : false}
                    defaultValue={formData.gender === "1" ? "Male" : "Female"}
                  />
                </div>
                {formData.gender && formData.gender === "0" ? (
                  <div className="input-container">
                    <label>Pregnant</label>
                    <SelectDropdown
                      name="pregnant"
                      setForm={setForm}
                      options={[
                        { name: "Yes", value: "Yes" },
                        { name: "No", value: "No" },
                      ]}
                      error={
                        errors && errors.pregnant ? errors.pregnant : false
                      }
                      defaultValue={formData.pregnant || "No"}
                    />
                  </div>
                ) : null}
                <div className={errors && errors.weight ? 'input-container error' : 'input-container'}>
                  <label>Weight (lb)</label>
                  <div>
                    <input
                      type="number"
                      min="1"
                      placeholder="0"
                      name="weight"
                      onChange={setForm}
                      defaultValue={user.weight}
                      required
                    />
                  </div>
                  {errors && errors.weight ? (
                    <div className="invalid-error">
                      <span>Please enter a valid weight</span>
                    </div>
                  ) : null}
                </div>
                <div className={errors && errors.height ? 'input-container error' : 'input-container'}>
                  <label>Height (in)</label>
                  <div>
                    <input
                      type="number"
                      min="0.0"
                      placeholder="0"
                      name="height"
                      onChange={setForm}
                      defaultValue={user.height}
                      required
                    />
                  </div>
                  {errors && errors.height ? (
                    <div className="invalid-error">
                      <span>Please enter a valid height</span>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="button-container">
                <div>
                  <button
                      className="button button-primary"
                      onClick={() => updating ? null : submit()}
                    >
                      {updating ? <LoadingDots size="small" color="white" /> : "Submit"}
                    </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default UpdateUserModal;
