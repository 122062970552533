import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MailIcon from "../../assets/mail.svg";
import DnaReport from "../../assets/DNA-Report.svg";
import Time from "../../assets/time.svg";
import Delivered from "../../assets/Delivered.svg";
import Mailed from "../../assets/mailed.svg";
import Loading from "../Loading";
import IconError from "../../assets/warning.svg";
import axios from "axios";

const SingleKitTracker = ({ userId, kitRegistered, avatar }) => {
  const history = useHistory();
  const [error, setError] = useState("");
  const [kitStatus, setKitStatus] = useState(kitRegistered);
  const [isLoading, setIsLoading] = useState(false);

  const updateMailedStatus = useCallback(() => {
    setIsLoading(true);
    axios
        .put(process.env.REACT_APP_DNA_API + "/v1/kits/byuser/" + userId + "/mailed")
        .then((response) => {
            setKitStatus("in_mail");
            setIsLoading(false);
        })
        .catch((error) => {
            setIsLoading(false);
            if (error.response.status === 400) {
                setError(error.response.data);
            } else {
                setError(error.response.data.message);
            }
        });
  }, [userId]);

  const updateTrackerData = useCallback(() => {
    if (kitStatus === false) {
      return {
        status: null,
        status_class: null,
        button: {
          text: "Activate DNA Kit",
          function: () => {
            history.push("/register");
          },
          icon: null,
          disabled: false,
          style: "button button-primary",
        },
      };
    }

    if (kitStatus === "registered") {
      return {
        status: null,
        status_class: null,
        button: {
          text: "Mail In",
          function: updateMailedStatus,
          icon: MailIcon,
          disabled: false,
          style: "button button-outline-primary button-sm",
        },
      };
    }

    if (kitStatus === "in_mail") {
      return {
        icon: Mailed,
        status: "Mailed",
        status_class: "in-mail",
        button: null,
      };
    }

    if (kitStatus === "received") {
      return {
        icon: Delivered,
        status: "Received",
        status_class: "received",
        button: null,
      };
    }

    if (kitStatus === "processing") {
      return {
        icon: Time,
        status: "Processing",
        status_class: "processing",
        button: null,
      };
    }

    if (kitStatus === "completed") {
      return {
        icon: null,
        status: null,
        status_class: null,
        button: {
          text: "Ready",
          function: () => {
            history.push("/report/balance");
          },
          icon: DnaReport,
          disabled: false,
          style: "button button-outline-success",
        },
      };
    }

    if (kitStatus === "sample_failed") {
      return {
        icon: null,
        status: "",
        status_class: null,
        button: {
          text: "Sample Failed",
          function: () => {
            window.open("https://www.itovi.com/contact/", "_blank");
          },
          icon: IconError,
          disabled: false,
          style: "button button-outline-danger warning-icon",
        },
      };
    }

    return {
      heading: null,
      message: "",
      icon: "",
      button: null,
    };
  }, [history, kitStatus, updateMailedStatus]);

  const [trackerData, setTrackerData] = useState(updateTrackerData());

  useEffect(() => {
    setTrackerData(updateTrackerData());
  }, [kitStatus, updateTrackerData]);

  return (
    <>
      {isLoading ? <Loading /> : null}
      {error !== "" ? (
        <div className="kit-tracker-alert-box">
          <div>{error}</div>
          <div
            onClick={() => {
              setError("");
            }}
          >
            <FontAwesomeIcon icon={["fas", "exlamation-triangle"]} />
          </div>
        </div>
      ) : null}
      <div className="single-kit-tracker">
        <div className="avatar">
          {avatar === undefined || avatar.length === 0 ? (
            <img
              src="https://helpdeskgeek.com/wp-content/pictures/2019/05/01.png"
              alt="avatar"
            />
          ) : (
            <img src={avatar[0].url} alt="avatar" />
          )}
        </div>

        <div className="heading">
          Explore the science of DNA like never before, discover what it can
          tell you about your personal health!
        </div>

        <div className="status-container">
          {trackerData.button !== null ? (
            <button
              type="button"
              className={trackerData.button.style}
              onClick={trackerData.button.function}
              disabled={trackerData.button.disabled}
            >
              <div>
                {trackerData.button.icon !== null && !error.length ? (
                  <img src={trackerData.button.icon} alt="tracker icon" />
                ) : null}
                {trackerData.button.text}
              </div>
            </button>
          ) : (
            <div>
              <div>Kit Status</div>
              <div className={trackerData.status_class}>
                {trackerData.icon ? (
                  <img src={trackerData.icon} alt="tracker icon" />
                ) : null}
                {trackerData.status ? trackerData.status : "unknown"}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SingleKitTracker;
