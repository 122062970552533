import React from "react";
import { useHistory } from "react-router-dom";
import KitBox from "../../assets/dna_kit.png";
import DnaLogo from "../../assets/iTOVi_DNA_Logo_W_2x.png";

const DnaBanner = ({ loggedIn }) => {
  const history = useHistory();
  return (
      <div className="dna-banner">
          <div>
              <div className="banner-description">
                  <div><img src={DnaLogo} alt="iTOVi DNA" /></div>
                  <div>Discover the secrets in your DNA.</div>
                  <div>Find out which essential oils and supplements your body responds to best through a DNA analysis.</div>
                  <div>
                      <button className="button button-lg button-primary" type="button" onClick={() => document.getElementById("shop-section").scrollIntoView({ behavior: "smooth" })}>
                          Order Kit Now
                      </button>
                      <button
                          className="button button-lg button-outline-primary"
                          type="button"
                          onClick={() => {
                              history.push("/register");
                          }}
                      >
                          Register Kit
                      </button>
                  </div>
              </div>
              <div className="banner-image">
                  <img src={KitBox} alt="kit box" />
              </div>
          </div>
      </div>
  );
};

export default DnaBanner;
