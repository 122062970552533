import React, { useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ErrorIcon from "../../assets/warning.svg";
import RedeemCodeHelp from "./RedeemCodeHelp";
import LoadingDots from "../../components/LoadingDots";
import { upsertGeneratingStatus } from "../../helpers/generatingReportHelper";

const RedeemModal = ({
  active,
  reportName,
  reportKey,
  setRedeemModal,
  userId,
  setCustomerSupport,
  setCongratsModal,
  setActivelyGenerating,
}) => {
  const [redemptionCode, setRedemptionCode] = useState("");
  const [inputResponse, setInputResponse] = useState(null);
  const [dontHaveCode, setDontHaveCode] = useState(false);
  const [emailSent, setEmailSent] = useState({ active: false, email: null });
  const [loading, setLoading] = useState(false);

  const redemptionValidate = async () => {
    if (!redemptionCode || redemptionCode === "") {
      setInputResponse({
        error: true,
        message: "Invalid Entry",
        description: "Code entered cannot be empty",
      });
      return;
    }
    setLoading(true);
    await axios
      .post(
        process.env.REACT_APP_DNA_API + "/v1/redemptioncodes/redeem",
        { userId: userId, code: redemptionCode },
        {
          headers: {
            authorization: "Token " + process.env.REACT_APP_DNA_API_KEY,
          },
        }
      )
      .then((result) => {
        if (result.data.success) {
          setCongratsModal({
            active: true,
            message: `Your ${reportName} Report is currently being generated, it will be ready to view momentarily!`,
          });
          upsertGeneratingStatus(reportKey);
          setActivelyGenerating(true);
          closeModal();
        } else {
          setInputResponse({
            error: true,
            message: "Error",
            description: "Something went wrong",
          });
        }
      })
      .catch((error) => {
        setInputResponse({
          error: true,
          message: "Error",
          description: error.response.data.error,
        });
      });
    setLoading(false);
    return;
  };

  const closeModal = () => {
    setInputResponse(null);
    setRedemptionCode("");
    setDontHaveCode(false);
    setEmailSent({ active: false, email: null });
    setRedeemModal(null);
  };

  return (
    <>
      {active ? (
        <div className="modal-wrapper">
          {!dontHaveCode ? (
            <div className="modal-card redeem-modal">
              <div className="modal-card-header">
                <div className="header-title">
                  {emailSent.active
                    ? "Email Sent!"
                    : `Redeem ${reportName} Report`}
                </div>
                <div className="dismiss" onClick={() => closeModal()}></div>
              </div>
              <div className="redeem-description">
                {emailSent.active ? (
                  <span>
                    Your email was verified! We’ve emailed{" "}
                    <strong>{emailSent.email}</strong> the redemption code(s)
                    associated with your account
                  </span>
                ) : (
                  `Use the redemption code received from the ${reportName} Report
                purchase. Codes are located in the email receipt from your
                purchase.`
                )}
              </div>
              {emailSent.active ? (
                <div className="redeem-description">
                  Wrong email, or didn’t receive it?{" "}
                  <span
                    className="redeem-text-link"
                    onClick={() => setCustomerSupport(true)}
                  >
                    Contact Customer Support
                  </span>
                </div>
              ) : null}
              <div className="redeem-input">
                <div className="input-label">
                  <div>Enter Redemption Code</div>
                  {inputResponse ? (
                    <div
                      className={
                        inputResponse.error
                          ? "redeem-response error"
                          : "redeem-response success"
                      }
                    >
                      {inputResponse.error ? (
                        <img src={ErrorIcon} alt="" />
                      ) : (
                        <FontAwesomeIcon icon={("fas", "check-circle")} />
                      )}

                      {inputResponse.message}
                    </div>
                  ) : null}
                </div>
                <input
                  name="redemption_code"
                  className={
                    inputResponse
                      ? inputResponse.error
                        ? "redemption-code error"
                        : "redemption-code success"
                      : "redemption-code"
                  }
                  type="text"
                  value={redemptionCode}
                  required
                  onChange={(e) =>
                    e.target.value
                      ? setRedemptionCode(
                          e.target.value
                            .replace(/-|\s/g, "")
                            .match(/.{1,4}/g)
                            .join("-")
                        )
                      : setRedemptionCode("")
                  }
                  onFocus={() => setInputResponse(null)}
                />
                <div
                  className="dont-have-code"
                  onClick={() => {
                    setDontHaveCode(true);
                    setInputResponse(null);
                  }}
                >
                  Don't have code?
                </div>
                {inputResponse && inputResponse.description ? (
                  <div
                    className={
                      inputResponse.error
                        ? "error-description"
                        : "success-description"
                    }
                  >
                    {inputResponse.description}
                  </div>
                ) : null}
              </div>
              <div className="redeem-actions">
                <div></div>
                <div className="redeem-buttons">
                  <button
                    type="button"
                    className="button button-sm button-outline-secondary"
                    onClick={() => closeModal()}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="button button-sm button-primary"
                    onClick={async () => {
                      if (!loading) {
                        await redemptionValidate();
                        setEmailSent({ active: false, email: null });
                      }
                    }}
                  >
                    {loading ? (
                      <LoadingDots size="medium" color="white" />
                    ) : (
                      "Redeem"
                    )}
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <RedeemCodeHelp
              setDontHaveCode={setDontHaveCode}
              closeModal={closeModal}
              setEmailSent={setEmailSent}
              setCustomerSupport={setCustomerSupport}
            />
          )}
        </div>
      ) : null}
    </>
  );
};

export default RedeemModal;
