import React from "react";
import TraitCard from "../../components/report/TraitCard";
import DefaultReport from "./DefaultReport";

const BalanceReport = ({ report, props }) => {
  let keyTraitsSection = {};
  let foodTraitsSection = {};
  let nutrientTraitsSection = {};

  const firstSection = report.sections[0] || [];
  if (Object.prototype.hasOwnProperty.call(firstSection, "sectionKey")) {
    keyTraitsSection = report.sections.find((s) => s.reportKey === "balance" && s.sectionKey === "KEY");
    foodTraitsSection = report.sections.find((s) => s.reportKey === "balance" && s.sectionKey === "FOOD");
    nutrientTraitsSection = report.sections.find((s) => s.reportKey === "balance" && s.sectionKey === "NUTRIENT");
  }
  else {
    keyTraitsSection = report.sections.find((s) => s.name === "KEY");
    foodTraitsSection = report.sections.find((s) => s.name === "FOOD");
    nutrientTraitsSection = report.sections.find((s) => s.name === "NUTRIENT");
  }

  const foodSummary = {
      title: "Food Summary",
      imageUrl: foodTraitsSection.imageUrl || "https://dm8j30w0c9dtk.cloudfront.net/reports/foodsummary.png"
  };
  const nutrientSummary = {
      title: "Nutrient Summary",
      imageUrl: nutrientTraitsSection.imageUrl || "https://dm8j30w0c9dtk.cloudfront.net/reports/nutrientsummary.png"
  };

  return (
      <>
      <DefaultReport
        title={report.reportName}
        description={report.reportDescription}
        imageUrl={report.imageUrl}
        keyTraits={<TraitCard title="Key Traits" reportKey="balance" sectionKey="key" traits={keyTraitsSection.traits} />}>
              <div className="content-container">
                  <TraitCard title="Food Traits" reportKey="balance" sectionKey="food" traits={foodTraitsSection.traits} summary={foodSummary} />
              </div>
              <div className="content-container">
                  <TraitCard title="Nutrient Traits" reportKey="balance" sectionKey="nutrient" traits={nutrientTraitsSection.traits} summary={nutrientSummary} />
              </div>
          </DefaultReport>
      </>
  );
};

export default BalanceReport;
