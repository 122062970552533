import React from "react";

const MatchWellnessProducts = () => {
  return (
    <div className="match-wellness-product">
      <div>
        <div className="dna-section-heading">
          <div className="match-wellness-heading">
            iTOVi DNA will match wellness products to YOU based on your unique
            DNA.
          </div>
        </div>
        <div className="description">
          Your DNA is the blueprint for your body. It is the foundation of your
          body’s wellness functions. And it largely remains the same throughout
          your entire lifetime. Your DNA is a big part of what makes you unique.
          And that means the ideal wellness plan would be unique in a way that
          matches your genome. iTOVi DNA takes a bold step of delving into your
          DNA, using your genetic markers to isolate the products that best
          match YOUR DNA, so you can build a wellness plan based on who you are.
        </div>
        <div className="sections">
          <div>
            <div className="image-container">
              <img
                src="https://www.itovi.com/wp-content/uploads/2020/03/iTOVi_DNA_Icon.png"
                alt="icon"
              />
            </div>
            <div className="title">Secrets From 20,000+ Genes!</div>
            <div className="section-description">
              Your DNA is like a book, with millions of base pairs instead of
              words, that tells a story about how wellness works for your body.
              And iTOVi DNA knows how to read it.
            </div>
          </div>
          <div>
            <div className="image-container">
              <img
                src="https://www.itovi.com/wp-content/uploads/2020/03/iTOVi_Gnome_Icon.png"
                alt="icon"
              />
            </div>
            <div className="title">Optimize Your Wellness Approach!</div>
            <div className="section-description">
              According to your DNA, which macronutrients, vitamins, and
              minerals is your body good at utilizing? Are there any that your
              body is bad at utilizing? This DNA information will help you
              manage your stress, boost your energy levels, and support your
              immune system!
            </div>
          </div>
          <div>
            <div className="image-container">
              <img
                src="https://www.itovi.com/wp-content/uploads/2020/03/wellness_Icon.png"
                alt="icon"
              />
            </div>
            <div className="title">Additional Products For Your Wellness!</div>
            <div className="section-description">
              Besides telling you what your DNA calls for, your iTOV DNA report
              will help you find the best products to support those issues!
              Knowing which oils, supplements, and other wellness resources
              match best with your DNA will transform your wellness results!
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MatchWellnessProducts;
