import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const emptyFlashMessage = {
  status: null,
  duration: 0,
  message: "",
};

const NotificationBanner = ({ status, duration, message, setFlashMessage }) => {
  const [toggle, setToggle] = useState(false);
  const [timerId, setTimerId] = useState(null);
  const style = "notification-banner " + status;
  duration = duration ? duration : 0;

  useEffect(() => {
    const timer = () =>
      setTimeout(function () {
        setToggle(false);
        setFlashMessage(emptyFlashMessage);
      }, duration);

    const timeout = () => {
      setToggle(true);
      if (duration !== "none") {
        setTimerId(timer());
      }
    };

    timeout();
  }, [status, duration, message, setFlashMessage]);

  return (
    <>
      {toggle ? (
        <div className={style}>
          <div className="message">{message}</div>
          <div className="toggle-banner">
            <FontAwesomeIcon
              icon={["fas", "times"]}
              onClick={() => {
                setToggle(false);
                clearTimeout(timerId);
                setFlashMessage(emptyFlashMessage);
              }}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default NotificationBanner;
