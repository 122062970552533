import React from "react";
import { getColor } from "../../helpers/statusColorHelper";

const TraitStatusBar = ({ chartData }) => {
  const dataCount = chartData.length - 1;
  chartData = chartData
    ? chartData.sort((a, b) => (a.value > b.value ? 1 : -1))
    : null;

  return (
    <div className="trait-status-bar">
      {chartData
        ? chartData.map((val, index) => {
            const color = getColor(val.value);
            const font_weight = val.active ? "bold" : "normal";
            return (
              <div key={index}>
                <div className="bar-title" style={{ fontWeight: font_weight }}>
                  {val.scoreName}
                </div>
                <div
                  className="up-arrow"
                  style={val.active ? { borderBottomColor: color } : null}
                ></div>
                <div
                  className={
                    index === 0
                      ? "bar left-rounded"
                      : index === dataCount
                      ? "bar right-rounded"
                      : "bar"
                  }
                  style={{ backgroundColor: color }}
                >
                  {index > 0 ? <div className="bar-splitter"></div> : null}
                </div>
              </div>
            );
          })
        : null}
    </div>
  );
};

export default TraitStatusBar;
