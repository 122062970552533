import React from "react";

const TermsAndConditions = () => {
    return (
        <div className="lg-container">
            <div className="content-container termsAndConditions">
                <div>
                    <h2>iTOVi DNA Terms of Service</h2>
                    <strong>Updated: 21 February 2021</strong>
                    <p>This document describes your rights and obligations. You should read this because any use of the Service signifies your acceptance of these Terms of Service.</p>
                    <p>
                        In order to use iTOVi DNA products and services (the “Services”), you must agree to these Terms of Service. This is a legal contract between you and iTOVi, LLC. with its principal place of business based at 355 South 520 West Suite 250 Lindon, UT 84042 (“iTOVi”). Your use of the Products includes, but is not limited to a) submitting a saliva sample (“Sample”) for DNA extraction and processing, b) uploading a digital version of your Genetic Information, and c) interacting
                        with such information, on the iTOVi website or the iTOVi app. These terms of service are referred to hereafter as the “Terms”. By using the Services, you agree to be bound by the Terms.
                    </p>
                    <ol>
                        <li>Important Things for You to Understand</li>
                        <ul>
                            <li>You always maintain ownership of your data, but you grant iTOVi the ability to use your data for the purposes set out in our Privacy Policy and these Terms.</li>
                            <li>You may discover unanticipated facts about yourself or your family when using our Services that you may not have the ability to change (e.g. you may discover surprising facts about your genetic predisposition to longevity, impulse control or hearing loss).</li>
                            <li>As we are constantly striving to improve the Services we provide you, your anonymized data may be used to enhance our existing user experience or to develop new products and services. Unless expressly stated otherwise in writing, each new feature or additional product that we add to the Services will also be subject to these Terms.</li>
                            <li>You may at any time request that we delete your data and account, as described in our Privacy Policy. </li>
                        </ul>
                        <li>Description of the Products</li>
                        <p>
                            The Services, as defined by these Terms, include access to the iTOVi website, app and personal genotyping products, including the collection and analysis of your Sample. You acknowledge and agree that the Services are provided “AS IS” and are based on the current state of the art of genetic research and technology in use by iTOVi at the time of the purchase, viewing, or use. iTOVi will provide you with a health survey that will need to be completed in order to provide
                            the Services (the “Self-Reported Information”). iTOVi will supply you with a sample collection kit with an address label for shipment of the kit to the lab or will allow you to upload genetic information generated by a third-party. The lab will conduct a DNA analysis of the Sample, which includes having the DNA isolated and a portion used for genotyping (the “Test”). iTOVi will instruct the lab to have the Sample destroyed within 45 calendar days of delivery of the
                            Test results. iTOVi will generate a report based upon the Test results and the health survey (“Report”). The Report utilizes a number of chosen genes combined with proprietary interpretation algorithms in order to develop personalized recommendations. These recommendations will be available online through the iTOVi website and/or app through a customized, personal report detailing your DNA test results, interpretation of those results, and tailored recommendations.
                            Some of these recommendations may involve vitamins and/or nutritional products. Such recommendations are provided for informational purposes only and have not been evaluated or approved by the FDA. Vitamins and nutritional supplements are not intended to diagnose, treat, cure, or prevent any disease or illness. Use individual vitamins and other health products only as directed. Consult a physician prior to beginning or altering any diet or nutritional supplement
                            program.
                        </p>
                        <p>If you have received a bone marrow or stem cell transplant, your Test may provide unexpected results because your saliva may contain cells with your donor’s DNA. DNA for the Test is extracted from the cells, and the presence of a donor’s DNA can yield flawed results or results based on your donor’s DNA.</p>
                        <li>Risks and Considerations Regarding Company Products</li>
                        <p>
                            Although based on scientific research, the Services and results provided by iTOVi in any format, (including genetic findings and probabilities) should not be relied upon by you or any other person to diagnose, treat, prevent or cure any disease or health condition. You should consult with a physician or other appropriate health care professional regarding the diagnosis, treatment and prevention of any disease or health condition and prior to undertaking any health or
                            dietary treatment or program. No patient/physician relationship between you and iTOVi is created through your use of the Services.
                        </p>
                        <p>
                            The laboratory may not be able to process your Sample, and the laboratory process may result in errors. The laboratory may not be able to process your Sample if your saliva does not contain a sufficient volume of DNA, you do not provide enough saliva, or the results from processing do not meet our standards for accuracy. If the initial processing fails due to our error, iTOVi will reprocess the same Sample at no charge to you. If the lab determines there wasn't enough
                            DNA provided there may be a charge to gather and process a second sample.
                        </p>
                        <p>
                            <strong>iTOVi DNA Services are for informational, research and educational use only. They do not and are not intended to provide medical advice.</strong> Recommendations based on evaluation of submitted DNA are not intended to provide any medical advice. Consult your physician if you have any medical concerns.
                        </p>
                        <li>User Representations and Prerequisites</li>
                        <ul>
                            By accessing iTOVi DNA, you represent the following:
                            <li>You give permission to iTOVi, its affiliates, contractors, successors and assignees to perform the Services including genotyping DNA extracted from your Sample, and you specifically request iTOVi to disclose the results of analyses performed on your DNA to you.</li>
                            <li>You are eighteen (18) years of age or older.</li>
                            <li>Any Sample you provide contains only your saliva or that of a minor of whom you are a parent or legal guardian and who has agreed to the testing.</li>
                            <li>You, or the minor whose sample you are submitting, do not have an active infectious disease.</li>
                            <li>You are not an insurance company or an employer attempting to obtain information about an insured person or an employee.</li>
                            <li>You understand that all your personal information related to or generated by the Services will be stored in iTOVi databases and will be processed in accordance with the iTOVi Privacy Policy.</li>
                            <li>
                                You understand that by engaging in the Services you acquire no rights in any intellectual property, research or commercial products that may be developed by iTOVi or its collaborating partners or contractors, including but not limited to any and all information generated from providing a Sample, the Test, the Report, accessing your Genetic Information, or providing Self-Reported Information. You specifically understand and acknowledge that you will not receive
                                compensation for any research or commercial products that include or result from the Services including, but not limited to your Sample, the Test, your Report, or Self-Reported Information.
                            </li>
                            <li>
                                You have the authority, under the laws of the state or jurisdiction in which you reside, to provide these representations. In case of a breach of any one or more of these representations, iTOVi has the right to suspend or terminate your account and refuse any and all current or future use of the Services (or any portion thereof) to you and you will defend and indemnify iTOVi and its affiliates against any liability, costs, or damages arising directly out of your
                                breach of these representations.
                            </li>
                        </ul>
                        <li>iTOVi‘s Proprietary Rights</li>
                        <p>
                            You acknowledge and agree that iTOVi (or iTOVi’s licensors, as applicable) own all legal right, title, and interest in and to the Services, intellectual property rights (including but not limited to patents) which exist in the Services (whether those rights happen to be registered or not, and wherever in the world those rights may exist). You further acknowledge that the Services may contain information which is designated confidential by iTOVi and that you shall not
                            disclose such information without iTOVi’s prior written consent.
                        </p>
                        <p>You grant to iTOVi (or iTOVi’s licensors, as applicable) an irrevocable, world-wide, fully paid-up, non-exclusive, non-royalty bearing license to any and all information provided in connection with the Services, subject to iTOVi’s Privacy Policy.</p>
                        <p>iTOVi logos, product and service names are trademarks of iTOVi and these marks together with any other Company trade names, service marks, logos, domain names, and other distinctive brand features are the “Company Brand“. You agree to display, or use the Company Brand as defined at itovi.com/marketing.</p>
                        <li>Disclaimer of Warranties</li>
                        <p>
                            YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT: (1) YOUR USE OF THE SERVICES ARE AT YOUR RISK. THE SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. iTOVi EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. (2) iTOVi MAKES NO WARRANTY THAT (a) THE SERVICES WILL MEET YOUR REQUIREMENTS; (b) THE SERVICES WILL BE
                            UNINTERRUPTED, TIMELY, UNFAILINGLY SECURE, OR ERROR-FREE; (c) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICES WILL BE ACCURATE OR RELIABLE; (d) THE QUALITY OF ANY PRODUCTS OR SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THE SERVICES WILL MEET YOUR EXPECTATIONS OR (e) ANY ERRORS IN THE SOFTWARE WILL BE CORRECTED. (3) YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM ANY
                            MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICES UNLESS iTOVi IS GROSSLY NEGLIGENT IN ITS MAINTENANCE OF THE MATERIAL AND SHOULD HAVE OTHERWISE REASONABLY KNOWN THE MATERIAL WAS CORRUPT OR CONTAINED A VIRUS. (4) NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM iTOVi OR THROUGH OR FROM THE SERVICES SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THESE TERMS. (5) iTOVi DOES NOT CONTROL OR ENDORSE ANY ACTIONS RESULTING
                            FROM YOUR PARTICIPATION IN THE SERVICES AND, THEREFORE, iTOVi SPECIFICALLY DISCLAIMS ANY LIABILITY WITH REGARD TO ANY ACTIONS RESULTING FROM YOUR USE, TRANSMISSION TO THIRD PARTIES OR PARTICIPATION IN THE SERVICES UNLESS OTHERWISE SPECIFIED IN THIS DOCUMENT.{" "}
                        </p>
                        <li>Limitation of Liability</li>
                        <p>
                            WITHIN THE LIMITS ALLOWED BY APPLICABLE LAWS, YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT iTOVi SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR PERSONAL INJURY, LOSS OF PROFITS, GOODWILL, USE, OR DATA OR OTHER INTANGIBLE LOSSES (EVEN IF iTOVi HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING FROM: (a) THE USE OR THE INABILITY TO USE THE SERVICES; (b) ANY ACTION
                            YOU TAKE BASED ON THE INFORMATION YOU RECEIVE IN, THROUGH OR FROM THE SERVICES, (c) YOUR FAILURE TO KEEP YOUR PASSWORD OR ACCOUNT DETAILS SECURE AND CONFIDENTIAL, (d) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND PRODUCTS RESULTING FROM ANY GOODS, DATA, INFORMATION, OR PRODUCTS PURCHASED OR OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH OR FROM THE SERVICES; (f) THE IMPROPER AUTHORIZATION FOR THE SERVICES BY SOMEONE CLAIMING SUCH AUTHORITY; or
                            (g) STATEMENTS OR CONDUCT OF ANY THIRD-PARTY ABOUT THE SERVICES.
                        </p>
                        <p>You agree that, to the extent permissible by law, any and all damages you may have related to your use of the Services will be limited to the purchase price of the Services.</p>
                        <li id="privacy">Privacy Policy</li>
                        <p>
                            This Privacy Policy, as stated in this section and otherwise referred to in these Terms, is designed to help you better understand how we collect, use, store, process, and transfer your personal information when providing the Services, including but not limited to, operating our computer systems, website, mobile apps, software and other products. iTOVi is not a covered entity under the Health Insurance Portability and Accountability Act (“HIPAA”), and as a result no
                            information provided by you is subject to or protected by HIPAA. The following are iTOVi’s privacy principles:
                        </p>
                        <ul>
                            <li>We collect and handle information, including personal information, (i) to provide, analyze and improve our Services, (ii) as we reasonably believe is permitted by laws and regulations, such as for marketing and advertising purposes, and (iii) as reasonably necessary to comply with laws and regulations, and to protect the security and safety of our company, employees, customers and others.</li>
                            <li>We will not sell, lease, or rent your personally identifiable information (such as information identifying a single individual with his/her individual genotype) to any third-party or to a third-party for research purposes without your consent.</li>
                            <li>
                                We understand and respect the sensitive nature of the information you may provide to us, including your contact information, information about your genetic characteristics, health survey, etc. To that end, we strive to be transparent in our collection, use and disclosure of this information. By using the Services you consent to share genetic information with any of our laboratories. Your contact information will not be shared with our laboratories. Please see
                                below to learn more about our sharing and consent practices.
                                We may use analytics software that record: Screens visited, Interaction patterns (such as screen actions, gestures: taps, scrolls) and Device details (Type, Version, Model, Operating System). We are using the information collected to improve our app. We do not collect personally identifiable information with these tools and do not track your browsing habits across apps.
                            </li>
                        </ul>
                        <h5>What information we collect:</h5>
                        <ul>
                            <li>Information you provide directly to us</li>
                            <ul>
                                <li>
                                    <strong>Registration Information.</strong> When you register an account with us or purchase our Services, we collect personal information, such as your name, date of birth and contact information such as your email and phone number.
                                </li>
                                <li>
                                    <strong>Self-Reported Information.</strong> We ask you to provide us with additional information about yourself through our health survey. Optional information, such as testimonials, may be provided to us at your discretion through our website or the app.
                                </li>
                                <li>
                                    <strong>Genetic information.</strong> We provide you the option to submit Genetic Information that has been extracted by a third-party for analysis and report generation.{" "}
                                </li>
                                <li>
                                    <strong>Customer service.</strong> When you contact our Customer Service or correspond with us about our Service, we collect information to improve our Services including by tracking and responding to your inquiry; investigating any breach of our Privacy Policy or applicable laws or regulations; and otherwise analyzing and improving our Services.
                                </li>
                            </ul>
                            <li>Information related to our genetic testing Services</li>
                            <ul>
                                <li>
                                    <strong>Swab sample storage.</strong> To use our Services, you may purchase a swab kit, register an online account, and ship your swab sample to our laboratory. Once received, your saliva sample will be identified by its unique barcode. The barcode label identifies you to us but not to our laboratory. We instruct the lab that your saliva samples and DNA are to be destroyed within 45 calendar days after the laboratory completes its work, unless the laboratory’s
                                    legal and regulatory requirements require it to maintain physical samples.
                                </li>
                                <li>
                                    <strong>Genetic Information.</strong> “Genetic Information” as used in these Terms, refers to features of your DNA that distinguish you from other people (e.g. the As, Ts, Cs, and Gs at particular locations in your genome) and is generated when we analyze and process your saliva sample. Genetic Information includes the results information reported to you.
                                </li>
                            </ul>
                            <li>Authorization. You authorize and acknowledge that iTOVi will do the following:</li>
                            <ul type="A">
                                <li>
                                    <strong>How we use and share information.</strong> iTOVi will use and share your personal information with third parties only in the ways that are described in this Privacy Policy.
                                </li>
                                <li>
                                    <strong>Using information to provide, analyze and improve our Services.</strong> We use the information described above to operate, provide, analyze and improve our Services. These activities may include, among other things, using your information in a manner consistent with other commitments in this Privacy Policy, to:
                                </li>
                                <ul>
                                    <li>open your account, enable purchases and process payments, communicate with you, and implement your requests;</li>
                                    <li>conduct analytics to improve and enhance our Services;</li>
                                    <li>offer new products or Services to you, including through emails, promotions or contests;</li>
                                    <li>conduct surveys or polls, and obtain testimonials;</li>
                                    <li>process and deliver your genetic testing results and solution recommendations;</li>
                                    <li>perform research &amp; development activities, which may include, for example, conducting data analysis and research in order to develop new or improve existing products and services, and performing quality control activities.</li>
                                </ul>
                                <li>Information we share with third parties:</li>
                                <ul>
                                    <li>
                                        <strong>General service providers.</strong> We share your Sample with a laboratory and processing center to process and analyze your saliva sample for purposes of generating your genetic information. NOTE: Our service providers act on our behalf. While we implement procedures and contractual terms to protect the confidentiality and security of your information, we cannot guarantee the confidentiality and security of your information due to the inherent
                                        risks associated with storing and transmitting data electronically.
                                    </li>
                                    <li>
                                        <strong>Employers and Insurance Providers.</strong> We do not share genetic information with employers or health insurance providers. You are protected under the Genetic Information Nondiscrimination Act (GINA) of 2008.
                                    </li>
                                    <li>
                                        <strong>De-Identified Information.</strong> We may share anonymized information with third parties, which is any information that has been stripped of your personably identifiable information (such as your name and contact information). This anonymized information may be aggregated with information of others so that you cannot reasonably be identified as an individual.{" "}
                                    </li>
                                    <li>
                                        <strong>Registration Information.</strong> We do not share your registration information with third parties.
                                    </li>
                                </ul>
                                <li>Disclosures required by law</li>
                                <p>
                                    Under certain circumstances your information may be subject to disclosure pursuant to judicial or other government subpoenas, warrants, or orders, or in coordination with regulatory authorities. iTOVi will preserve and disclose any and all information to law enforcement agencies or others if required to do so by law or in the good faith belief that such preservation or disclosure is reasonably necessary to: (a) comply with legal or regulatory process (such as
                                    a judicial proceeding, court order, or government inquiry) or obligations that iTOVi may owe pursuant to ethical and other professional rules, laws, and regulations; (b) enforce the iTOVi and other policies; (c) respond to claims that any content violates the rights of third parties; (d) protect the rights, property, or personal safety of iTOVi, its employees, its users, its clients, and the public or (e) respond to an emergency which we believe in the good
                                    faith requires us to disclose information to assist in preventing the death or serious bodily injury of any person. To the extent administratively practicable, iTOVi will reasonably attempt to provide notice of such disclosure request to you before providing your information to the requesting entity so that you may attempt to block such request or limit the amount of information required to be disclosed.
                                </p>
                            </ul>
                            <li>Your Rights. We recognize and affirm the rights you have over your Personal Information that we hold:</li>
                            <ul>
                                <li>
                                    <strong>Right to correction.</strong> You have the right to request a copy of the personal information we have stored about you and to correct any errors found in that information. If you are unable to correct the error directly through the app or the website you may contact Customer Support with your request at support@itovi.com.
                                </li>
                                <li>
                                    <strong>Right to restrict processing.</strong> You have the right to limit additional processing of your personal data by sending a request to Customer Support at support@itovi.com.
                                </li>
                                <li>
                                    <strong>Right to be forgotten.</strong> You have the right to have your information deleted from our servers at any time by sending a request to Customer Support - support@itovi.com. Your Registration Information, and other personal information uniquely identifying you will be deleted. Certain de-identified information, such as purchase history, will be retained for regulatory compliance or business continuity purposes.
                                </li>
                                <li>
                                    <strong>Right to data portability.</strong> You have the right to request and receive a copy of the personal information iTOVi has collected on you in a commonly recognized format. This can be found through the client portal or by sending a request to Customer Support - support@itovi.com.{" "}
                                </li>
                            </ul>
                            <li>California’s Shine the Light Law</li>
                            <p>
                                California Civil Code Section 1798.83, known as the “Shine the Light” law, permits users who are California residents to request and obtain from us a list of what personal information (if any) we disclosed to third parties for their direct marketing purposes in the preceding calendar year and the names and addresses of those third parties. Requests may be made only once a year and are free of charge. Under Section 1798.83, iTOVi currently does not share any
                                personal information with third parties for their own direct marketing purposes.
                            </p>
                        </ul>
                        <li>Returns</li>
                        <p>Unopened collection kits may be returned for a refund of the purchase price. Contact Customer Support support@itovi.com or (877) 483-3748 to arrange for the return and refund. Restocking fees may apply.</p>
                        <p>Opened kits may not be returned or refunded.</p>
                        <p>Reports purchased separately from the DNA collection kit will not be refunded after the code has been redeemed in the app or web portal.</p>
                        <p>To receive a refund you must contact Customer Support within 30 days of product purchase and receive a Returned Material Authorization(RMA). Kit must be received by iTOVi within 30 days of arranging the RMA.</p>
                        <li>Account Closure</li>
                        <p>If you no longer wish to participate in our Services, you may close your account by sending a request to Customer Support - support@itovi.com. When closing an account, you will lose access to the Services. Any information you have provided will be stored on our servers unless a separate request has been made pursuant to the Privacy Policy.</p>
                        <p>iTOVi may limit, suspend, or otherwise terminate your account for any breach of these Terms or act inconsistent with the spirit of these terms. In such case, you will not be entitled to a refund for any of the Services.</p>
                        <li>Changes to these Terms</li>
                        <p>
                            We may update these Terms from time to time. If you have created an account, we will notify you of material changes to these Terms by email (sent to the email address specified in your account). We will also post notice of such change on iTOVi’s website. All changes to these Terms are effective immediately when posted, and the “Last updated” date shown at the top of the updated Terms will be updated accordingly. By continuing to use the Services, you agree to be bound
                            by the updated Terms.
                        </p>
                        <li>Dispute Resolution</li>
                        <p>Should any dispute arise between you and iTOVi, you agree to binding arbitration pursuant to the rules of the American Arbitration Association (“AAA”). You agree that a AAA arbiter has the authority to resolve any and all claims between you and iTOVi. Such arbitration will take place in Salt Lake City, Utah and will be governed by the laws of the State of Utah. YOU UNDERSTAND AND ACKNOWLEGE THAT BY AGREEING TO ARBITRATION YOU HAVE WAIVED YOUR RIGHT TO A JURY TRIAL.</p>
                        <p>Each party will be responsible for its own costs and fees, including attorney’s fees incurred during any dispute.</p>
                        <li>Governing Law</li>
                        <p>The Terms shall be governed by the laws of the State of Utah. Any and all disputes are subject to the dispute resolution above. Should any dispute not be decided by arbitration, the parties agree to the jurisdiction of the courts of the State of Utah, which will be the sole venue for deciding any disputes between you and iTOIV that are not resolved by arbitration.</p>
                        <li>Services by Other Companies</li>
                        <p>Your Sample will be provided to a third-party lab for processing. That third-party may be subject to other terms and conditions. Please read those terms carefully. We have no responsibility related to any such third-party services.</p>
                        <li>Miscellaneous</li>
                        <ul type="none">
                            <li>
                                <u>Waiver:</u> These Terms may only be modified in writing. No action or inaction will be interpreted as a wavier of any right or obligation under these Terms.
                            </li>
                            <li>
                                <u>Entire Agreement:</u> These Terms state the entire agreement between you and iTOVi regarding your use of the Services and supersede any prior agreements we may have relating to the Services.
                            </li>
                            <li>
                                <u>Feedback:</u> If you submit feedback or suggestions about the Services, we may use your feedback or suggestions for any purpose without any obligation to you.
                            </li>
                            <li>
                                <u>Assignment:</u> We reserve the right to assign or transfer our rights and obligations under these Terms. These Terms are personal to you and, as a result, you may not, without the written consent of iTOVi, assign or transfer any of your rights and obligations under this Agreement. There shall be no third-party beneficiaries to these Terms.
                            </li>
                            <li>
                                <u>If iTOVi is Acquired:</u> If iTOVi or its businesses are acquired or transferred to another entity (in whole or part and including in connection with bankruptcy or similar proceedings), iTOVi has the right to share all the information associated with your use of the Services with that entity. These Terms will continue to apply to the Services until you receive notification of changes to the Terms.
                            </li>
                            <li>
                                <u>Severability:</u> Except as explicitly provided herein, the unenforceability of any section or clause in these Terms will not affect the enforceability of the remaining Terms. We may replace any unenforceable section or clause with a similar one that is enforceable.
                            </li>
                        </ul>
                        <li>Contact Us</li>
                        <p>If you have any questions about these Terms, please contact us at support@itovi.com or by mail to the following address:</p>
                        <address>
                            iTOVi, LLC
                            <br />
                            751 E 700 S
                            <br />
                            Suite 202
                            <br />
                            American Fork, UT 84003
                            <br />
                        </address>
                    </ol>
                </div>
            </div>
        </div>
    );
};

export default TermsAndConditions;
