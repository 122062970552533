import React from "react";
import { useHistory } from "react-router-dom";
import { getColor } from "../../helpers/statusColorHelper";
import ArrowRight from "../../assets/i-line-rightarrow.svg";
import BlankImage from "../../assets/blank_1542x464.png";

const TraitCard = ({
  sectionKey,
  title,
  reportKey,
  traits,
  summary,
  description,
}) => {
  const history = useHistory();

  return (
    <>
      <div className="trait-card">
        <div className="trait-title">{title.toLowerCase()}</div>
        {summary ? (
          <div className="summary-card">
            <div>
              <img
                src={summary.imageUrl ? summary.imageUrl : BlankImage}
                alt="card"
              />
              <div
                onClick={() => {
                  history.push(`/report/${reportKey}/${sectionKey}`);
                }}
              >
                <div>{summary.title}</div>
                <div>
                  <img src={ArrowRight} alt="icon" />
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {description ? (
          <div className="section-description">{description}</div>
        ) : null}
        <div className="trait-container">
          {traits && traits.length ? (
            traits.map((trait, index) => {
              let status = trait.scores.filter((obj) => obj.active === true)[0];
              return (
                <div
                  className="trait"
                  onClick={() => {
                    history.push(
                      `/report/${reportKey}/${sectionKey}/${trait.traitKey}`.toLowerCase()
                    );
                  }}
                  key={trait.traitKey}
                >
                  <div className="image" alt="card">
                    {trait.croppedImages && trait.croppedImages.circle ? (
                      <img src={trait.croppedImages.circle} alt="trait" />
                    ) : trait.imageUrl || trait.imageUrl !== "" ? (
                      <img src={trait.imageUrl} alt="trait" />
                    ) : null}
                  </div>
                  <div className="name-status">
                    <div>{trait.traitName}</div>
                    <div style={{ color: getColor(status.value) }}>
                      {status.scoreName}
                    </div>
                  </div>

                  <div className="icon" alt="icon">
                    <img src={ArrowRight} alt="arrow right" />
                  </div>
                </div>
              );
            })
          ) : (
            <div className="trait trait-disabled">
              <div className="image" alt="card"></div>
              <div className="name-status">No Traits Available</div>
              <div className="icon" alt="icon"></div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default TraitCard;
