import React from "react";
import ReportTraitsReport from "../../assets/dna_reporttraits_report.png"
import DnaCopperTendencyMock from "../../assets/dna_coppertendency_teport.png"
const ReportOneWhatsIncluded = () => {
    return (
        <div className="dna-report-one-whats-included-wrapper">
            <div className="dna-report-one-whats-included">
                <div  className="dna-section-heading">
                    Report 1: Balance—What’s Included?
                </div>
                <div>
                    <div>
                        <p>
                            The first step in improving your wellness is understanding which way it tends to lean. The iTOVi BALANCE REPORT shows YOUR genetic predisposition towards up to 19 different wellness traits that need to be kept in balance. You’ll also find product recommendations for these traits that, combined with your Scan reports, will help you make better decisions for your wellness.
                        </p>
                        <p>
                            Ready to learn about your genetic strengths and weaknesses? Ready to find wellness products matched to your genome?
                        </p>
                        <p>
                            The iTOVi DNA Balance Report studies your genome for genes relating to:
                        </p>
                        <p>
                            Carb Utilization<br />
                            Copper Tendency<br />
                            Stress Tolerance<br />
                            Systemic Inflammation<br />
                            Vitamin A<br />
                            Zinc Tendency<br />
                            Fat Utilization<br />
                            Protein Utilization<br />
                            Calcium Tendency<br />
                            Iron Tendency<br />
                            Magnesium Tendency<br />
                            Phosphorus Tendency<br />
                            Selenium Tendency<br />
                            Vitamin B12<br />
                            Vitamin B6<br />
                            Vitamin B9 (Folate)<br />
                            Vitamin C<br />
                            Vitamin D<br />
                            Vitamin E
                        </p>
                        <p>
                            Even though we will test your DNA for predispositions in ALL of the wellness categories listed above, our tests do not always yield sufficient data to determine a definite predisposition. Therefore, not all of these categories will show up in everyone’s reports.
                        </p>
                    </div>
                    <div className="image-stack">
                        <img src={ReportTraitsReport} alt="" className="image-stack-item-bottom"/>
                        <img src={DnaCopperTendencyMock} alt="" className="image-stack-item-top"/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReportOneWhatsIncluded;
