import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="lg-container content-container">
      <div className="not-found">
        <div className="not-found-header">
          <div>404</div>
          <div>We are sorry. The page you requested was not found</div>
        </div>
        <div className="not-found-nav">
          <div>
            <Link to="/" className="button button-primary">
              Home
            </Link>
          </div>
          <div>
            <a
              href="https://www.itovi.com/contact/"
              target="_blank"
              rel="noopener noreferrer"
              className="button button-primary"
            >
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
