import React from "react";
import { getTrait, getTraits } from "../helpers/reportMapHelper";
import TraitSummary from "../components/trait/TraitSummary";
import AllTraitsNav from "../components/trait/AllTraitsNav";

const Trait = (props) => {
  const traitName = props.match.params.traitKey
    ? props.match.params.traitKey
    : null;
  const trait = getTrait(traitName);
  const allTraits = getTraits();

  return (
    <div className="xl-container">
      {trait ? (
        <div className="flex-two-thirds-container single-trait-wrapper">
          <div>
            <div className="page-title">All Traits</div>
            <AllTraitsNav traitId={trait.traitId} allTraits={allTraits} />
          </div>
          <div>
            <TraitSummary data={trait} userId={props.userInfo.userId} />
          </div>
        </div>
      ) : (
        <div className="content-container p-5">
          <h4>Trait Not Found!</h4>
        </div>
      )}
    </div>
  );
};

export default Trait;
