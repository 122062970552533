import React from "react";
import PrivacyLock from "../../assets/icon_privacy@2.png";

const Privacy = () => {
  return (
    <div className="dna-privacy">
      <div>
        <div id="privacy" className="dna-section-heading">
          We Value Your Privacy
        </div>
        <div className="body">
          <div>
            <img src={PrivacyLock} alt="" />
          </div>
          <div>
            <p>
              Keeping your genetic information private is a priority to us. So,
              we ensure that your iTOVi DNA reports can only be accessed through
              your account and we also follow the guidelines of the Health
              Insurance Portability and Accountability Act (HIPAA) in that all
              DNA samples and DNA extracts sent to our lab are destroyed within
              45 days of their arrival. Also, once your DNA is analyzed, your
              raw genetic data file is de-identified and kept within an
              encrypted storage facility. This way, your genetic data is
              available if you want to purchase more DNA reports, but your
              privacy is always protected.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
