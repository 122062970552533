import React from "react";
import IconOrder from "../../assets/icon_order.png"
import IconReport from "../../assets/icon_report.png"
import IconTransit from "../../assets/icon_transit.png"

const HowItWorks = () => {
  return (
      <div className="how-it-works-wrapper">
          <div className="how-it-works">
              <div id="how-it-works" className="dna-section-heading">
                  How it Works
              </div>
              <div className="steps-container">
                  <div>
                      <div>
                          <img src={IconOrder} alt="dna" />
                      </div>
                      <div>1.</div>
                      <div>Order your DNA Kit online and we’ll ship it to you.</div>
                  </div>
                  <div>
                      <div><img src={IconTransit} alt="dna" /></div>
                      <div>2.</div>
                      <div>Collect a sample of your DNA and send it back to our lab.</div>
                  </div>
                  <div>
                      <div>
                          <img src={IconReport} alt="dna" />
                      </div>
                      <div>3.</div>
                      <div>After 4-6 weeks, your DNA report will be ready to view online!</div>
                  </div>
              </div>
          </div>
      </div>
  );
};

export default HowItWorks;
