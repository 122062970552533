import React from "react";
import { useHistory } from "react-router-dom";
import TraitNav from "../components/trait/TraitNav";
import FoodSummary from "../components/trait/FoodSummary";
import NutrientSummary from "../components/trait/NutrientSummary";
import TraitSummary from "../components/trait/TraitSummary";
import { getReport } from "../helpers/reportMapHelper";
import { getTrait } from "../helpers/reportMapHelper";

const Traits = (props) => {
  const history = useHistory();
  const reportKey = props.match.params.reportKey.toLowerCase();
  const sectionKey = props.match.params.slug.toLowerCase();
  const report = getReport(reportKey);
  if (!report) {
    history.push("/");
    return null;
  }
  const section = report.sections.find(
    (section) =>
      section.reportKey.toLowerCase() === reportKey &&
      section.sectionKey.toLowerCase() === sectionKey
  );
  if (!section) {
    history.push("/");
    return null;
  }
  let summary = undefined;
  if (section && section.reportKey === "balance") {
    if (section.sectionKey === "FOOD") {
      summary = {
        title: "Food Summary",
        imageUrl: section.imageUrl,
        component: FoodSummary,
        componentData: report.foodSummary,
      };
    } else if (section && section.sectionKey === "NUTRIENT") {
      summary = {
        title: "Nutrient Summary",
        imageUrl: section.imageUrl,
        component: NutrientSummary,
        componentData: report.nutrientSummary,
      };
    }
  }
  const traitName = props.match.params.trait
    ? props.match.params.trait.split("-").join(" ")
    : null;
  const trait = getTrait(traitName);

  const setInitialActive = () => {
    if (report && section && trait) {
      return trait.traitId;
    }
    if (report && section && !trait) {
      if (summary) {
        return "summary";
      } else if (traitName) {
        history.push(`/report/${reportKey}/${sectionKey}`);
      } else {
        return 0;
      }
    }
    if (report && !section) {
      history.push(`/report/${reportKey}`);
    }
    if (!report) {
      history.push("/");
    }
  };

  const presetComponentData = () => {
    if (trait) {
      return trait;
    }

    if (summary) {
      return summary;
    } else if (section && section.traits) {
      return section.traits[0];
    } else {
      return null;
    }
  };

  const componentData = presetComponentData();
  const setComponent = () => {
    if (!trait && summary && summary.component) {
      return React.createElement(summary.component, {
        title: summary.title,
        data: summary.componentData,
        user: {
          birth_date: props.userInfo.birth_date,
          pregnant: props.userInfo.pregnant,
          gender: props.userInfo.sex,
          weight: props.userInfo.weight,
          height: props.userInfo.height,
          user_id: props.userInfo.userId,
          client_id: props.userInfo.clientId,
        },
        kitId: props.userInfo.kitId,
        getLoginStatus: props.getLoginStatus
      });
    } else {
      return (
        <TraitSummary data={componentData} userId={props.userInfo.userId} />
      );
    }
  };

  return (
    <div className="xl-container">
      {section ? (
        <div className="section-wrapper">
          <div className="flex-two-thirds-container">
            <div>
              <div className="page-title">{section.sectionTitle}</div>
              <TraitNav
                active={setInitialActive()}
                section={section}
                reportKey={reportKey}
                summary={summary}
              />
            </div>
            <div>{setComponent()}</div>
          </div>
        </div>
      ) : (
        <div className="content-container p-5">
          <h4>No traits found!</h4>
        </div>
      )}
    </div>
  );
};

export default Traits;
