import React, { useState } from "react";
import DnaKitBarcode from "../../assets/Kit_Back_Site.jpg";
import { Form, Button, Spinner } from "react-bootstrap";
import "../../scss/components/kitRegistration/kitPreScan.scss";
import axios from "axios";

const KitPreScan = ({ navigation, formData, setForm }) => {
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const { next } = navigation;
  const { kitId, phoneNumber } = formData;

  return (
    <div className="dna-registration-form kit-pre-scan-form">
      <div className="title">
        <h2>Register Your DNA Kit</h2>
      </div>
      <Form>
        <Form.Group>
          <Form.Control
            isInvalid={
              errors && errors.phoneNumber ? errors.phoneNumber : false
            }
            type="tel"
            placeholder="Phone #"
            name="phoneNumber"
            onChange={setForm}
            defaultValue={phoneNumber}
            required
          />
          {errors && errors.phoneNumber ? (
            <div className="invalid-feedback d-block">
              <span>Please enter a valid phone number</span>
            </div>
          ) : null}
        </Form.Group>
        <Form.Group>
          <img className="img-fluid" src={DnaKitBarcode} alt="Dna barcode" />
        </Form.Group>
        <Form.Group>
          <Form.Control
            isInvalid={errors && errors.kitId ? errors.kitId : false}
            type="text"
            name="kitId"
            onChange={setForm}
            placeholder="Kit ID #"
            required
            autoCapitalize="characters"
            defaultValue={kitId}
          />
          {errors && errors.kitId ? (
            <div className="invalid-feedback d-block">
              <span>{errors.kitId}</span>
            </div>
          ) : null}
        </Form.Group>
        {loading === false ? (
          <Button
            variant="success"
            onClick={validatePreScan}
            type="submit"
            block
          >
            Next
          </Button>
        ) : (
          <Button variant="success" block disabled>
            <Spinner
              as="span"
              animation="grow"
              size="md"
              role="status"
              aria-hidden="true"
              className="mr-2"
            />
            Loading...
          </Button>
        )}
      </Form>
    </div>
  );

  /**
   * Validate form inputs for Pre Scan Questions
   * @param {event} e
   */
  function validatePreScan(e) {
    e.preventDefault();
    setLoading(true);
    let errors = {};
    setErrors({});

    if (
      !phoneNumber ||
      !/^\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/g.test(phoneNumber)
    ) {
      errors.phoneNumber = true;
    }

    if (
      !kitId ||
      !/[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}$/g.test(kitId)
    ) {
      errors.kitId = "Kit Id isn't valid!";
    }

    if (Object.keys(errors).length) {
      setErrors(errors);
      setLoading(false);
      return;
    }

    axios
        .get(process.env.REACT_APP_DNA_API + "/v1/kits/validate/" + kitId)
        .then(function (response) {
            setLoading(false);
            if (response.data.status === "not_registered" || response.data.status === "on_hold_not_registered") {
                next();
            }
            else {
                errors.kitId = "Kit Id is not valid.";
                setErrors(errors);
            }
        })
        .catch(function (error) {
            setLoading(false);
            if (error.response.status === 422) {
                if (error.response.data.status === "registered") {
                    errors.kitId = "This kit id has already been registered.";
                } else {
                    errors.kitId = "Kit Id isn't valid!";
                }
                setErrors(errors);
            } else {
                errors.kitId = "Server Error";
                setErrors(errors);
            }
        });
  }
};

export default KitPreScan;
