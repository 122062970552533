import React, { useState } from "react";
import { Toast, Button } from "react-bootstrap";
import "../scss/components/openApp.scss";

const OpenApp = (props) => {
  const isMobileBrowser =
    navigator.userAgent.indexOf("Android") !== -1 || //Android
    navigator.userAgent.indexOf("like Mac") !== -1; //iOS

  const [openAppSb, setOpenAppSb] = useState(isMobileBrowser);
  const toggleAppSb = () => setOpenAppSb(!openAppSb);

  const openMobileApp = () => {
    if (process.env.NODE_ENV === "development") {
      // staging link
      window.location = "https://itovi.page.link/xVasEUfBGuWTjsD69";
    } else {
      // production link
      window.location = "https://itovi.page.link/dxvUC2z1c5NuF12C6";
    }
  };
  return (
    <>
      {openAppSb ? (
        <Toast className="app-sb" onClose={toggleAppSb}>
          <Toast.Header>Also available in the iTOVi app.</Toast.Header>
          <Toast.Body>
            <Button
              variant="success"
              size="sm"
              className="mr-auto text-white"
              onClick={openMobileApp}
            >
              Open App
            </Button>
          </Toast.Body>
        </Toast>
      ) : null}
    </>
  );
};

export default OpenApp;
