import React from "react";

const SummaryCard = ({ reportName, reportDescription, imageUrl }) => {
  return (
    <div className="summary-card">
      {imageUrl ? <img src={imageUrl} alt="summary card" /> : null}
      <div className="summary-content">
        <div className="content-title">About {reportName}</div>
        <p>{reportDescription}</p>
      </div>
    </div>
  );
};

export default SummaryCard;
