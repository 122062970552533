import React from "react";
import AllTraitsReport from "../../assets/dna_alltraits_report.png"

const DnaWhatsInReport = () => {
    return (
        <div className="dna-whats-in-report-wrapper">
            <div className="dna-whats-in-report">
                <div  className="dna-section-heading">
                    What’s in the iTOVi DNA Reports?
                </div>
                <div>
                    <div>
                        <img src={AllTraitsReport} alt="" />
                    </div>
                    <div>
                        <p>
                            <strong>1. TRAITS</strong> - iTOVi’s Nutrition Report dives deeper into your wellness, giving you detailed, individualized data that can help you make healthier choices in your daily life.
                        </p>
                        <p>
                            Each trait in your iTOVi DNA report represents a particular factor in your physical or mental wellness. A page devoted to each trait delves into why this trait is important for your wellness and how it is likely to be affected by YOUR genotype.
                        </p>
                        <p>
                            Different iTOVi DNA reports include information on different traits. Manage the specifics of your wellness by learning about these traits!
                        </p>
                        <p>
                            <strong>2. PERSONALIZED SCORE</strong> - How does your DNA add up? Find out what your genes say about your body, your nutrition, and how you compare to others.
                        </p>
                        <p>
                            Our DNA makes sure that we all have different predispositions—different strengths and weaknesses—in our wellness. Different areas of wellness will always present a greater challenge to some individuals than to others.
                        </p>
                        <p>
                            Knowing your personalized score for each trait will help you know where your genetic strengths and weaknesses lie, so you can work WITH your body to improve your wellness.
                        </p>
                        <p>
                            Use these personalized wellness scores to identify traits to emphasize in your personal wellness plan.
                        </p>
                        <p>
                            <strong>3. SUCCESS STRATEGIES</strong> - Insightful tips help you know how to take action!
                        </p>
                        <p>
                            This section includes lifestyle, diet, and other suggestions on how you can support your wellness in any of the traits listed on your report! Incorporate these strategies into your wellness plan and enjoy the results!
                        </p>
                        <p>
                            <strong>4. 100% SCIENCE</strong> - Our traits are backed by peer-reviewed studies published in medical journals. From in vitro studies to large clinical trials, these studies show how different genotypes tend towards different wellness patterns, especially in regards to certain traits.
                        </p>
                        <p>
                            These resources will always be available to you in the iTOVi DNA app as references!
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DnaWhatsInReport;
