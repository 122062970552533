import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SelectDropdown = ({ name, setForm, options, error, defaultValue }) => {
  const [selectDropdown, setSelectDropdown] = useState(false);
  const [selectValue, setSelectValue] = useState(defaultValue || null);
  const node = useRef();

  const handleClick = e => {
    if (node.current.contains(e.target)) {
      return;
    }
    setSelectDropdown(false);
  };

  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  return (
    <div
      className={
        selectDropdown
          ? "select-dropdown active"
          : error
          ? "select-dropdown error"
          : "select-dropdown"
      }
      onClick={() => setSelectDropdown(!selectDropdown)}
      ref={node}
    >
      <div className="selected">
        <div>{selectValue || "Select..."}</div>
        <div>
          <FontAwesomeIcon
            icon={
              selectDropdown ? ("fas", "chevron-up") : ("fas", "chevron-down")
            }
          />
        </div>
      </div>
      <div className="dropdown" onClick={() => setSelectDropdown(false)}>
        {options.length
          ? options.map((option, index) => {
              return (
                <div
                  key={index}
                  className="option"
                  onClick={() => {
                    setForm({ target: { name: name, value: option.value } });
                    setSelectValue(option.name);
                  }}
                >
                  {option.name}
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
};

export default SelectDropdown;
