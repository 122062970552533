import React from "react";
import { useHistory } from "react-router-dom";

const AllTraitsCard = () => {
  const history = useHistory();

  return (
      <div className="all-traits-card">
          <div className="header">
              <img src="https://dm8j30w0c9dtk.cloudfront.net/traits/all-traits-v2.png" alt="DNA All Traits" />
              <div>
                  <div>All Traits</div>
              </div>
          </div>
          <div className="body">
              <div>View all your available traits</div>
              <div>
                  <button type="button" onClick={() => history.push("/all-traits")} className="button button-primary">
                      View Traits
                  </button>
              </div>
          </div>
      </div>
  );
};

export default AllTraitsCard;
