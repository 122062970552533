import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hooks-helper";
import { Form, Button, Spinner } from "react-bootstrap";
import { loginRedirect } from "../../helpers/loginRedirect";
import Cookies from "universal-cookie";
import axios from "axios";

const Cookie = new Cookies();

const Register = ({ getLoginStatus }) => {
  let history = useHistory();
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [formData, setForm] = useForm({
    first_name: null,
    last_name: null,
    email: null,
    password: null,
    confirm_password: null,
    termsOfService: null,
  });

  return (
    <div className="xl-container">
      <div className="content-container account-registration">
        <Form className="form">
          <div className="title">
            <h1>Create an account</h1>
            <p>Create an account to register your DNA Kit</p>
          </div>
          <Form.Group>
            <Form.Control
              isInvalid={errors.first_name}
              type="text"
              name="first_name"
              placeholder="First Name"
              onChange={setForm}
              required
              size="lg"
            />
            {errors.first_name ? (
              <div className="invalid-feedback d-block">
                <span>{errors.first_name}</span>
              </div>
            ) : null}
          </Form.Group>
          <Form.Group>
            <Form.Control
              isInvalid={errors.last_name}
              type="text"
              name="last_name"
              placeholder="Last Name"
              onChange={setForm}
              required
              size="lg"
            />
            {errors.last_name ? (
              <div className="invalid-feedback d-block">
                <span>{errors.last_name}</span>
              </div>
            ) : null}
          </Form.Group>
          <Form.Group>
            <Form.Control
              isInvalid={errors.email}
              type="text"
              name="email"
              placeholder="Email"
              autoComplete="username"
              onChange={setForm}
              required
              size="lg"
            />
            {errors.email ? (
              <div className="invalid-feedback d-block">
                <span>{errors.email}</span>
              </div>
            ) : null}
          </Form.Group>
          <Form.Group>
            <Form.Control
              isInvalid={errors.password}
              type="password"
              name="password"
              placeholder="Create a Password"
              autoComplete="new-password"
              onChange={setForm}
              required
              size="lg"
            />
            {errors.password ? (
              <div className="invalid-feedback d-block">
                <span>{errors.password}</span>
              </div>
            ) : null}
          </Form.Group>
          <Form.Group>
            <Form.Control
              isInvalid={errors.confirm_password}
              type="password"
              name="confirm_password"
              placeholder="Confirm Password"
              autoComplete="new-password"
              onChange={setForm}
              required
              size="lg"
            />
            {errors.confirm_password ? (
              <div className="invalid-feedback d-block">
                <span>{errors.confirm_password}</span>
              </div>
            ) : null}
          </Form.Group>
          <div className="pb-3">
            {loading === false ? (
              <Button
                variant="primary"
                type="submit"
                onClick={validateRegistration}
                size="lg"
                block
              >
                Create Account
              </Button>
            ) : (
              <Button variant="primary" block size="lg" disabled>
                <Spinner
                  as="span"
                  animation="grow"
                  size="md"
                  role="status"
                  aria-hidden="true"
                  className="mr-2"
                />
                Loading...
              </Button>
            )}
          </div>
          <div className="sign-in">
            <span>Already have an account? </span>
            <Link to="/login">Login</Link>
          </div>
        </Form>
        <div className="need-a-kit mt-3">
          Do you need a kit?
          <a
            href="https://itovi.com/dna"
            target="_blank"
            rel="noopener noreferrer"
          >
            &nbsp;Learn More
          </a>
        </div>
      </div>
    </div>
  );
  /**
   * Validate form inputs for Pre Scan Questions
   * @param {event} e
   */
  function validateRegistration(e) {
    e.preventDefault();
    setLoading(true);
    let errors = {};
    let nameRgx = /\d/;
    let passRgx = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm;
    let emailRgx = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;

    if (formData.first_name === null || nameRgx.test(formData.first_name)) {
      errors.first_name = "Please enter your first name";
    }

    if (!formData.last_name || nameRgx.test(formData.last_name)) {
      errors.last_name = "Please enter your last name";
    }

    if (!formData.email || !emailRgx.test(formData.email)) {
      errors.email = "Please enter a valid email address";
    }

    if (!formData.password || !passRgx.test(formData.password)) {
      errors.password =
        "Password must be at least 8 characters and must contain at least 1 uppercase letter, 1 lowercase letter, and 1 number";
    }

    if (formData.password !== formData.confirm_password) {
      errors.confirm_password = "Passwords do not match";
    }

    if (Object.keys(errors).length) {
      setLoading(false);
      setErrors(errors);
      return;
    }

    axios
      .post(process.env.REACT_APP_ITOVI_API + "/auth/register", formData)
      .then(async (response) => {
        setLoading(false);
        if(process.env.REACT_APP_FEATURE_FLAG === 'production') {
          Cookie.set("token", response.data.data.token, { path: '/', domain: '.itovi.com' });
        } else {
          Cookie.set("token", response.data.data.token);
        }
        setErrors({});
        await getLoginStatus();
        history.push(loginRedirect());
      })
      .catch(function (error) {
        console.log("error", error);
        setLoading(false);
        if (error.response) {
          setErrors(error.response.data.errors);
        } else {
          setErrors({ first_name: "Internal Server Error" });
        }
      });
  }
};

export default Register;
