import React from "react";
import SummaryCard from "../../components/report/SummaryCard";

const DefaultReport = (props) => {
    const summaryProps = {
        imageUrl: props.imageUrl,
        reportName: props.title,
        reportDescription: props.description
    };

    return (
        <>
            <div className="report-container xl-container">
                <div className="report-title">{props.title}</div>
                <div className="flex-two-thirds-container">
                    <div className="content-container">
                        <SummaryCard {...summaryProps} />
                    </div>
                    <div className="content-container">{props.keyTraits}</div>
                </div>
                <div className="flex-half-container">{props.children}</div>
            </div>
        </>
    );
};

export default DefaultReport;