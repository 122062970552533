function getColor(number) {
  switch (number) {
    case -2:
      return "#FF8189";
    case -1:
      return "#FCB24C";
    case 0:
      return "#58C1A6";
    case 1:
      return "#0A93C8";
    case 2:
      return "#A23A95";
    default:
      return null;
  }
}

export { getColor };
